import {
  Row,
  Col
} from 'react-bootstrap'
import {
  DateConvert
} from 'utilities'

const InfoSection = ({ dataInfo }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td width={165}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  )

  return (
    <Row>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="Tgl. Permintaan Job Mix" value={dataInfo.tgl_jobmix_permintaan ? DateConvert(new Date(dataInfo.tgl_jobmix_permintaan)).detail : '-'} />
            <InfoItem title="No. Permintaan Job Mix" value={dataInfo.no_jobmix_permintaan ?? "-"} />
            <InfoItem title="No. Job Mix" value={dataInfo.no_jobmix ?? "-"} />
          </tbody>
        </table>
      </Col>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="Item Bahan" value={dataInfo.nama_item_bahan} />
            <InfoItem title="Qty. Permintaan Job Mix" value={dataInfo.qty_permintaan_jobmix} />
            <InfoItem title="Pabrik" value={dataInfo.nama_pabrik ?? "-"} />
          </tbody>
        </table>
      </Col>
    </Row>
  )
}

export default InfoSection