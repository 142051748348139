import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { PenerimaanSuratJalanApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataSales, setDataSales] = useState([{label: 'Semua', value: undefined}])
    const [dataStatusPenerimaan, setDataStatusPenerimaan] = useState([
        {
            label: 'Semua', 
            value: undefined
        },
        {
            label: 'Sudah Diterima',
            value: 'terima'
        },
        {
            label: 'Batal Diterima',
            value: 'batal'
        },
        {
            label: 'Belum Diterima',
            value: 'pending'
        }
    ])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
        PenerimaanSuratJalanApi.getDropdownCustomer(),
        PenerimaanSuratJalanApi.getDropdownSales(),
        ])
        .then(Axios.spread((customer, sales) => {
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataSales = sales?.data?.data ? sales.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []

            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataSales([...dataSales, ...mapDataSales])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_surat_jalan_mulai: data?.filter?.tgl_surat_jalan_mulai,
        tgl_surat_jalan_selesai: data?.filter?.tgl_surat_jalan_selesai,
        tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
        tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
        batas_waktu_mulai: data?.filter?.batas_waktu_mulai,
        batas_waktu_selesai: data?.filter?.batas_waktu_selesai,
        customer: data?.filter?.customer,
        sales: data?.filter?.sales,
        status_penerimaan: data?.filter?.status_penerimaan,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalSuratJalanChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_surat_jalan_mulai: startDate,
            tgl_surat_jalan_selesai: endDate,
        })
    }
    const onTanggalDeliveryOrderChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_delivery_order_mulai: startDate,
            tgl_delivery_order_selesai: endDate,
        })
    }
    const onBatasWaktuChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            batas_waktu_mulai: startDate,
            batas_waktu_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_surat_jalan_mulai: undefined,
            tgl_surat_jalan_selesai: undefined,
            tgl_delivery_order_mulai: undefined,
            tgl_delivery_order_selesai: undefined,
            batas_waktu_mulai: undefined,
            batas_waktu_selesai: undefined,
            customer: undefined,
            sales: undefined,
            status_penerimaan: undefined
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Surat Jalan"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Surat Jalan"
                        startDate={values.tgl_surat_jalan_mulai ? new Date(values.tgl_surat_jalan_mulai) : ''}
                        endDate={values.tgl_surat_jalan_selesai ? new Date(values.tgl_surat_jalan_selesai) : ''}
                        onChange={dates => onTanggalSuratJalanChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Delivery Order"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Delivery Order"
                        startDate={values.tgl_delivery_order_mulai ? new Date(values.tgl_delivery_order_mulai) : ''}
                        endDate={values.tgl_delivery_order_selesai ? new Date(values.tgl_delivery_order_selesai) : ''}
                        onChange={dates => onTanggalDeliveryOrderChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Batas Waktu"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Batas Waktu"
                        startDate={values.batas_waktu_mulai ? new Date(values.batas_waktu_mulai) : ''}
                        endDate={values.batas_waktu_selesai ? new Date(values.batas_waktu_selesai) : ''}
                        onChange={dates => onBatasWaktuChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih Customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => setFieldValue('customer', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.sales}
                        label="Sales"
                        placeholder="Pilih Sales"
                        defaultValue={dataSales.find(val => val.value === values.sales)}
                        option={dataSales}
                        onChange={val => setFieldValue('sales', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.status_penerimaan}
                        label="Status Penerimaan"
                        placeholder="Pilih Status Penerimaan"
                        defaultValue={dataStatusPenerimaan.find(val => val.value === values.status_penerimaan)}
                        option={dataStatusPenerimaan}
                        onChange={val => setFieldValue('status_penerimaan', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;