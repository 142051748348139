import { useState, useEffect, useRef } from "react"
import { Row, Dropdown, Spinner } from "react-bootstrap"
import { useReactToPrint } from "react-to-print"
import Axios from "axios"
import FileSaver from "file-saver"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  FilterButton,
} from "components"
import { DateConvert } from "utilities"
import { LaporanStockUpdateApi } from "api"
import {
  PrintStokTerbaru,
  ModalFilterStokTerbaru,
  TableStokTerbaru
} from "./components"

const StokTerbaru = ({ setNavbarTitle }) => {
  const title = "Stok Terbaru"
  const refPrint = useRef()
  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const [data, setData] = useState([])
  const [loadingExport, setLoadingExport] = useState(false)
  const [dropdown, setDropdown] = useState({
    item_buaso: [],
    kelompok: [],
    jenis: [],
    gudang: [{ value: "0", kode_gudang: "0", label: "Semua Gudang" }],
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {}
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      tanggal: undefined,
      id_kelompok: undefined,
      id_jenis: undefined,
      id_item_buaso: undefined,
      id_gudang: "0",
    }
  })

  // FETCH DROPDOWN KELOMPOK DAN GUDANG
  const fetchDropdown = () => {
    Axios.all([
      LaporanStockUpdateApi.getDropdown({ tipe: "kelompok" }),
      LaporanStockUpdateApi.getDropdown({ tipe: "gudang" }),
    ])
      .then(
        Axios.spread((kelompok, gudang) => {
          // Tambah item dropdown gudang untuk semua gudang
          const mapGudang = dropdown.gudang.concat(gudang?.data?.data?.map(item => ({ ...item, value: item.id_gudang, label: item.nama_gudang })))
          const mapKelompok = kelompok?.data?.data?.map(item => ({ ...item, value: item.id_kelompok, label: item.nama_kelompok }))

          setDropdown(prev => ({ ...prev, kelompok: mapKelompok ?? [], gudang: mapGudang ?? [] }))
        })
      )
      .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" }))
  }

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true)

    LaporanStockUpdateApi.getReport({
      ...dataFilter.filter,
      tanggal: DateConvert(new Date(dataFilter.filter.tanggal)).default,
    })
      .then(({ data }) => setData(data?.data ?? []))
      .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" }))
      .finally(() => setIsFetchingReport(false))
  }

  const exportData = tipe => {
    setLoadingExport(true)

    LaporanStockUpdateApi.export({
      tipe,
      id_item_buaso: dataFilter.filter.id_item_buaso,
      id_gudang: dataFilter.filter.id_gudang,
      tanggal: DateConvert(new Date(dataFilter.filter.tanggal)).default,
    })
      .then(res => {
        const data = res.data.data_only
        const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data
        FileSaver.saveAs(urlFile, data)
      }).catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
      }).finally(() => setLoadingExport(false))
  }

  useEffect(() => {
    setNavbarTitle(title)
    fetchDropdown()

    return () => {
      setDropdown({ item_buaso: [], gudang: [] })
      setData([])
    }
  }, [setNavbarTitle])

  // fetch report on dataFilter change
  useEffect(() => {
    if (dataFilter.filter.active) getReportData()
  }, [dataFilter])

  const PageContent = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Stok Terbaru</div>
        <TableStokTerbaru data={data} />
      </>
    )
  }

  return (
    <CRUDLayout>
      <Row className="d-flex px-2 mb-3">
        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: 'filter' })}
          className="mr-2"
        />

        {/* Export Button  */}
        <Dropdown>
          <Dropdown.Toggle
            disabled={loadingExport}
            className="ml-2 mb-2 px-3 btn btn-warning text-white"
          >
            {loadingExport ? <Spinner animation="border" variant="white" size="sm" /> : "Export Data"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={!dataFilter?.filter.active}
              onClick={() => dataFilter?.filter?.active && exportData("excel")}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!dataFilter?.filter.active}
              onClick={() => dataFilter?.filter?.active && exportData("pdf")}>PDF (.pdf)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      {/* alert */}
      < Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {
        isFetchingReport
          ? <DataStatus loading={true} text="Memuat data . . ." />
          : (
            <div style={{ minHeight: "45vh" }}>
              <PageContent />
            </div>
          )
      }

      {/* modal filter */}
      {
        modalConfig.show && modalConfig.type === "filter" && (
          <ModalFilterStokTerbaru
            dropdown={dropdown}
            setDropdown={setDropdown}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
            data={dataFilter}
            setData={setDataFilter}
          />
        )
      }

      {/* PDF Laporan Stock Terbaru */}
      <div style={{ display: "none" }}>
        <PrintStokTerbaru ref={refPrint} dataFilter={dataFilter.filter} dataTable={data} />
      </div>
    </CRUDLayout >
  )
}

export default StokTerbaru
