import React, { useState, useEffect, useRef } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import Axios from "axios"
import { Button } from "react-bootstrap"
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  CreateButton,
  UpdateButton,
  ReadButton,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  FilterButton,
  ApprovalButton
} from "components"
import { PurchaseRequestApi } from "api"
import { DateConvert, TableNumber } from "utilities"
import { CetakPurchaseRequest } from "./CetakPurchaseRequest"
import { ModalDetail, ModalFilterPR } from "./Section"

const PurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  let createAlert = location?.state?.variant
  const componentRef = useRef()
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true)
  // menampung value dari search form
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  // data
  const [data, setData] = useState([])
  const [dropdownItem, setDropdownItem] = useState([{ value: undefined, label: "Semua" }])
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail', // detail | filter
    id: null
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pr?.filter?.active,
      tgl_purchase_request_mulai: location?.state?.pr?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: location?.state?.pr?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: location?.state?.pr?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: location?.state?.pr?.filter?.tgl_pemakaian_selesai,
      keperluan: location?.state?.pr?.filter?.keperluan,
      nama_item: location?.state?.pr?.filter?.nama_item,
      status_seleksi_vendor: location?.state?.pr?.filter?.status_seleksi_vendor
    },
    pagination: {
      page: location?.state?.pr?.filter?.page ?? "1",
      dataLength: location?.state?.pr?.filter?.dataLength ?? "10",
      totalPage: location?.state?.pr?.filter?.totalPage ?? "1",
      dataCount: location?.state?.pr?.filter?.dataCount ?? "0",
    }
  })

  const [printData, setPrintData] = useState(0)
  const [printVendorData, setPrintVendorData] = useState(0)
  const [isPrint, setIsPrint] = useState(false)

  const [dropdown, setDropdown] = useState({
    status: [
      { value: undefined, label: 'Semua' },
      { value: false, label: 'Belum Diseleksi' },
      { value: true, label: 'Sudah Diseleksi' },
    ],
    keperluan: [
      { value: undefined, label: 'Semua' },
      { value: 'stok', label: 'Stok' },
      { value: 'produksi', label: 'Produksi' },
    ]
  })
  // get initial data
  const getData = () => {
    setIsLoading(true)

    Axios.all([
      PurchaseRequestApi.getPage({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_purchase_request_mulai: dataFilter?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai: dataFilter?.filter?.tgl_purchase_request_selesai,
        tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
        tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
        keperluan: dataFilter?.filter?.keperluan,
        nama_item: dataFilter?.filter?.nama_item,
        status_seleksi_vendor: dataFilter?.filter?.status_seleksi_vendor
      }),
      PurchaseRequestApi.getDropdownItem()
    ])
      .then(
        Axios.spread((res, item) => {
          const mapItem = item?.data?.data ? item.data.data.map(val => ({ value: val.id_item_buaso, label: val.nama_item })) : []

          setData(res.data.data)
          setDropdownItem([ ...dropdownItem, ...mapItem ])
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          })
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        })
        setShowAlert(true)
      })
      .finally(() => setIsLoading(false))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
    setShowAlert(key ? true : false)
  }

  useEffect(() => {
    setNavbarTitle("Purchase Request (PR)")
    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text })
      setShowAlert(true)
    } else {
      return getData()
    }
    getData()
    return () => {
      history.replace((createAlert = undefined))
      setIsLoading(false)
      setDropdown({})
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.nama_item,
    dataFilter?.filter?.status_seleksi_vendor
  ])

  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  })

  const handleDataPrint = (id) => {
    setPrintData(id)
    setIsPrint(true)
  }

  const handlePrint = () => {
    reactToPrint()
  }

  const Table = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMore, setIsReadMore] = useState(false)

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data?.map((brg, index) => {
              if (isReadMore) {
                return <li key={index} index={index}>{`${brg.nama_item} (${parseFloat(brg.qty ?? 0)} ${brg.nama_satuan ?? ''})`}</li>
              } else {
                return index < 2 && <li key={index} index={index}>{`${brg.nama_item} (${parseFloat(brg.qty ?? 0)} ${brg.nama_satuan ?? ''})`}</li>
              }
            })}
          </ul>
          {data?.length > 2 &&
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setIsReadMore((prev) => !prev)} >
              {isReadMore ? "Sembunyikan" : "Selengkapnya..."}
            </div>}
        </>
      )
    }
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <ThFixed>Keperluan</ThFixed>
              <ThFixed>Tanggal Pemakaian</ThFixed>
              <Th>Item Barang</Th>
              <Th width={120}>Status Seleksi Vendor</Th>
            </Tr>
          </THead>
          <TBody>
            {data?.map((val, index) => {
              const rowSpan = val?.barang_jadi?.length ?? 1

              return val?.barang_jadi.map((item, index_item) => {
                return(
                  <Tr key={index}>
                    {index_item === 0 &&
                      <>
                        <TdFixed rowSpan={rowSpan}>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                        <TdFixed rowSpan={rowSpan}>
                          <div className="d-flex">
                            <ReadButton
                              onClick={() => {
                                setModalConfig({ show: true, type: 'detail', id: val?.id_purchase_request })
                                handleDataPrint(val?.id_purchase_request)
                              }}
                            />
                            <UpdateButton
                              onClick={() => history.push(`/inventory/transaksi/purchase-request/${val?.id_purchase_request}`, { ...location?.state, pr: dataFilter })}
                            />
                          </div>
                        </TdFixed>
                        <Td rowSpan={rowSpan}>
                          <div> {DateConvert(new Date(val?.tgl_purchase_request)).defaultDMY} </div>
                          <div> {val?.no_purchase_request ?? ""} </div>
                        </Td>
                        <Td rowSpan={rowSpan} className="text-capitalize">{val?.keperluan}</Td>
                        <Td rowSpan={rowSpan}>{DateConvert(new Date(val?.tgl_pemakaian)).defaultDMY}</Td>
                      </>
                    }
                    <Td>{`${item?.nama_item} (${parseFloat(item?.qty ?? 0)} ${item?.nama_satuan ?? ''})`}</Td>
                    <Td>
                      {item?.status_seleksi_vendor
                        ? <div className="text-center text-success">Sudah Diseleksi</div>
                        : <div className="text-center text-danger">Belum Diseleksi</div>
                      }
                    </Td>
                  </Tr>
                )
              })
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex justify-content-start align-items-center mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/inventory/transaksi/purchase-request/tambah", { ...location?.state, pr: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true
        ? <DataStatus loading={true} text="Memuat data..." />
        : data?.length > 0 ? (
          <>
            <small className="font-weight-bold">List Data Purchase Request</small>
            <Table />
          </>
        )
          : <DataStatus text="Tidak ada data" />
      }

      {/* Modal Detail */}
      {modalConfig.show && modalConfig.type === 'detail' && <ModalDetail
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        setAlertConfig={setAlertConfig}
        handlePrint={handlePrint}
      />}

      {/* Modal Filter */}
      {modalConfig.show && modalConfig.type === 'filter' && <ModalFilterPR
        dropdown={dropdown}
        show={modalConfig}
        setShow={setModalConfig}
        data={dataFilter}
        setData={setDataFilter}
        dropdownItem={dropdownItem}
      />}

    </CRUDLayout>
  )
}

export default PurchaseRequest
