import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  Input,
  InputSearch,
  ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  Alert,
  Pagination,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { BuasoApi } from "../../../api";
import { PageNumber, TableNumber } from "../../../utilities";

const Buaso = ({ setNavbarTitle }) => {
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA BUASO
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([BuasoApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_buaso: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? BuasoApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : BuasoApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };
  // FUNCTION CARI DATA DARI SERVER
  const searchData = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const key = searchKey; // SearchKey Value as key

    // SEARCH DATA WITH KEY FROM DATABASE
    BuasoApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle("BUASO"); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    kode_buaso: Yup.number().required("Masukkan kode").typeError("Kode hanya dapat berupa angka"),
    nama_buaso: Yup.string().required("Masukkan nama buaso"),
    // keterangan_buaso: Yup.string().required("Masukkan keterangan"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      kode_buaso: "",
      nama_buaso: "",
      keterangan_buaso: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      BuasoApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Input
                label="Kode Buaso"
                type="text"
                name="kode_buaso"
                placeholder="Kode Buaso"
                value={values.kode_buaso}
                onChange={handleChange}
                error={errors.kode_buaso && touched.kode_buaso && true}
                errorText={errors.kode_buaso}
              />

              <Input
                label="Nama Buaso"
                type="text"
                name="nama_buaso"
                placeholder="Nama Buaso"
                value={values.nama_buaso}
                onChange={handleChange}
                error={errors.nama_buaso && touched.nama_buaso && true}
                errorText={errors.nama_buaso}
              />

              <Input
                label="Keterangan"
                type="text"
                name="keterangan_buaso"
                placeholder="Keterangan"
                value={values.keterangan_buaso}
                onChange={handleChange}
                error={errors.keterangan_buaso && touched.keterangan_buaso && true}
                errorText={errors.keterangan_buaso}
              />

              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      kode_buaso: updateData.kode_buaso,
      nama_buaso: updateData.nama_buaso,
      keterangan_buaso: updateData.keterangan_buaso,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_buaso: updateData.id_buaso,
        ...values,
      };
      BuasoApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Input
                label="Kode Buaso"
                type="text"
                name="kode_buaso"
                placeholder="Kode Buaso"
                readOnly={true}
                value={values.kode_buaso}
                onChange={handleChange}
                error={errors.kode_buaso && touched.kode_buaso && true}
                errorText={errors.kode_buaso}
              />

              <Input
                label="Nama Buaso"
                type="text"
                name="nama_buaso"
                placeholder="Nama Buaso"
                value={values.nama_buaso}
                onChange={handleChange}
                error={errors.nama_buaso && touched.nama_buaso && true}
                errorText={errors.nama_buaso}
              />

              <Input
                label="Keterangan"
                type="text"
                name="keterangan_buaso"
                placeholder="Keterangan"
                value={values.keterangan_buaso}
                onChange={handleChange}
                error={errors.keterangan_buaso && touched.keterangan_buaso && true}
                errorText={errors.keterangan_buaso}
              />

              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_buaso: deleteData.id_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      BuasoApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      />
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {/* <ThFixed>Aksi</ThFixed> */}
              <ThFixed>
                <div style={{ whiteSpace: "nowrap" }}>Kode BUASO</div>
              </ThFixed>
              <ThFixed>
                <div style={{ width: 300 }}>Nama BUASO</div>
              </ThFixed>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_buaso}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  {/* <TdFixed >
                                        <div className="d-flex justify-content-center">
                                            <UpdateButton
                                                onClick={() => {
                                                    setUpdateData(val);
                                                    setIsUpdateForm(true);
                                                }}
                                            />

                                            <DeleteButton
                                                onClick={() => {
                                                    setDeleteData(val);
                                                    setIsDeleteData(true);
                                                }} />

                                            <Switch
                                                id={val.id_buaso}
                                                checked={(val.is_hidden) ? false : true}
                                                onChange={() => changeDataStatus(val.is_hidden, val.id_buaso)} />
                                        </div>
                                    </TdFixed> */}
                  <TdFixed>{val.kode_buaso}</TdFixed>
                  <TdFixed>
                    <div style={{ textAlign: "left" }}>{val.nama_buaso}</div>
                  </TdFixed>
                  <Td>{val.keterangan_buaso}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton onClick={() => {}} /> */}
          {/* Button Tambah */}
          {/* <CreateButton onClick={() => setIsCreateForm(true)} /> */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default Buaso;
