// React
import { useEffect, useState } from "react"

// Component
import { 
	ActionButton, CRUDLayout, Input, TBody,
	Td, TdFixed, Th, THead, ThFixed, Tr
} from "components"
import { Col, Row } from "react-bootstrap"

// API
import { PenerimaanSuratJalanApi } from "api"

const FormSection = ({ formik, dataTable }) => {
	const { values, errors, touched, isSubmitting, setFieldValue } = formik
	const [kode, setKode] = useState("")
	const [isNomorLoading, setIsNomorLoading] = useState(false)

	const getNomor = (value) => {
		setIsNomorLoading(true)

		PenerimaanSuratJalanApi.getNomor({ tanggal: value })
			.then(({ data }) => setKode(data.data))
			.catch(() => alert("Gagal mengambil nomor Penerimaan Surat Jalan"))
			.finally(() => setIsNomorLoading(false))
	}

	useEffect(() => {
		getNomor(new Date())

		// eslint-disable-next-line
	}, [])
	

	return (
		<>
			<Row>
				<Col lg="3">
					<Input
						label="Tanggal Penerimaan Surat Jalan"
						type="date"
						name="tgl_penerimaan_surat_jalan"
						value={values.tgl_penerimaan_surat_jalan}
						onChange={(e) => {
							const value = e.target.value

							setFieldValue("tgl_penerimaan_surat_jalan", value)
							getNomor(value)
						}}
						error={errors.tgl_penerimaan_surat_jalan && touched.tgl_penerimaan_surat_jalan && true}
						errorText={errors.tgl_penerimaan_surat_jalan}
					/>
				</Col>
				<Col lg="3">
					<Input
						label="No. Penerimaan Surat Jalan"
						type="text"
						value={kode.nomor}
						placeholder={
							isNomorLoading
								? "Memuat . . . "
								: values.tgl_penerimaan_surat_jalan
									? kode
									: "Pilih tanggal terlebih dahulu"
						}
						readOnly={true}
					/>
				</Col>
			</Row>

			<TableSection dataTable={dataTable} />

			<div className="d-flex justify-content-end">
				<ActionButton
					type="submit"
					variant="primary"
					text="Simpan"
					className="mt-2 px-4"
					loading={isSubmitting}
				/>
			</div>
		</>
	)
}

const TableSection = ({ dataTable }) => (
	<>
		<small>List Barang</small>
		<CRUDLayout.Table>
			<THead>
				<Tr className="text-center">
					<ThFixed>No.</ThFixed>
					<ThFixed>Kode barang</ThFixed>
					<Th>Item barang</Th>
					<ThFixed>Qty. Surat Jalan</ThFixed>
					<ThFixed>Satuan</ThFixed>
				</Tr>
			</THead>
			<TBody>
				{dataTable.map((val, index) => (
					<Tr key={index}>
						<TdFixed>{index + 1}</TdFixed>
						<TdFixed>{val.kode_barang}</TdFixed>
						<Td>{val.nama_barang}</Td>
						<Td><span className="text-right">{parseInt(val.qty_sj)}</span></Td>
						<Td>{val.nama_satuan}</Td>
					</Tr>
				))}
			</TBody>
		</CRUDLayout.Table>
	</>
)

export { FormSection, TableSection }
