import Services from "../../../services"

class PenerimaanGKGApi {
  getPage(params) {
    return Services.get("/penerimaan_gabah_kering_giling/page", { params })
  }
  getSingle(params) {
    return Services.get("/penerimaan_gabah_kering_giling/single", { params })
  }
  getDropdownGKG() {
    return Services.get("/gudang/dropdown")
  }
  getNomor(params) {
    return Services.get("/penerimaan_gabah_kering_giling/no_baru", { params })
  }
  create(value) {
    return Services.post("/penerimaan_gabah_kering_giling", value)
  }
  item() {
    return Services.get("/penerimaan_gabah_kering_giling/item")
  }
  export(params) {
    return Services.get("/penerimaan_gabah_kering_giling/export", { params })
  }
}
export default new PenerimaanGKGApi()
