import Services from "services"

class SuratJalanApi {
  get(params) {
    return Services.get("/surat_jalan/page", {params})
  }

  getSingle(params) {
    return Services.get("/surat_jalan/single", {params})
  }

  getDO(params) {
    return Services.get("/surat_jalan/list_do", {params})
  }

  getNomor(params) {
    return Services.get("/surat_jalan/no_baru", {params})
  }

  getDropdownGudang() {
    return Services.get("/gudang/dropdown")
  }

  getDropdownPetugas() {
    return Services.get("/karyawan")
  }

  getDropdownArmada() {
    return Services.get("/asetkendaraan")
  }

  getDropdownGudang() {
    return Services.get("/gudang/dropdown")
  }

  getSingleDO(params) {
    return Services.get("/surat_jalan/do_single/", {params})
  }

  save(data) {
    return Services.post("/surat_jalan", data)
  }

  update(data) {
    return Services.put("/surat_jalan", data)
  } 
}

export default new SuratJalanApi();
