import {
  useState,
  useEffect
} from 'react'
import {
  Modal
} from 'react-bootstrap'
import {
  DataStatus
} from 'components'
import {
  TransferBahanProduksiApi
} from 'api'
import {
  SectionDetail,
  TablePermintaan,
} from '../Comp'

export const ModalPermintaan = ({show, onHide, data}) => {
  const [dataPermintaan, setDataPermintaan] = useState({})
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    failed: false
  })

  const getData = () => {
    TransferBahanProduksiApi.getSingle({id_permintaan_produksi: data?.id_permintaan_produksi})
      .then(permintaan => {
        setDataPermintaan(permintaan?.data?.data ?? {})

        setFetchingStatus({
          loading: false,
          failed: false
        })
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          failed: true
        })
      })
  }
  
  useEffect(() => {
    show && getData()
  }, [show])

  return (
    <>
      <Modal 
        size="xl"
        show={show} 
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <b>Detail Permintaan Produksi</b>
        </Modal.Header>
        <Modal.Body>
        {fetchingStatus.loading || fetchingStatus.failed 
          ? <DataStatus 
              loading={fetchingStatus.loading}
              text={fetchingStatus.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
            />
          : <>
              <SectionDetail
                data={dataPermintaan}
              />
              <hr />
              <TablePermintaan 
                action="VIEW"
                data={dataPermintaan?.bahan ?? []}
              />
            </>
        }
        </Modal.Body>
      </Modal>
    </>
  )
}
