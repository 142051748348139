import React, { useState } from "react";
import { Table, Th, Td, Pagination, ThFixed, TdFixed } from "components";
import { RupiahConvert } from "utilities";

const TableSummary = ({ data }) => {
  const grandTotal = data.reduce((prev, { summary }) => parseFloat(prev) + parseFloat(summary), 0);
  const tableStyle = "align-middle text-nowrap"
  const tableBold = "text-right font-weight-bold"

  return (
    <div className="p-3 bg-white border border-top-0">
      <div className="font-weight-bold mb-2">Summary Nilai Persediaan</div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Kelompok Item</Th>
            <Th>Nilai Persediaan</Th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((val, index) => (
                <>
                  <tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td className={`${tableStyle}`}>{val?.nama_kelompok ?? "-"}</Td>
                    <Td className={`${tableStyle} ${"text-right"}`}>{RupiahConvert(parseFloat(val?.summary ?? 0).toString()).getWithComa}</Td>
                  </tr>
                </>
              ))}
              <tr>
                <Td colSpan="2" className={tableBold}>Grand Total</Td>
                <Td className={tableBold}>{RupiahConvert(parseFloat(grandTotal ?? 0).toString()).getWithComa}</Td>
              </tr>
            </>
          ) : (
            <tr><td colSpan={8} className={`${tableStyle} ${"text-center"}`}>Tidak ada data</td></tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableSummary;
