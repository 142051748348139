// React
import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

// API
import Axios from "axios";
import { DeliveryOrderApi } from "../../../../api";

// Form
import * as Yup from "yup";
import { Formik } from "formik";

// Component
import { Alert, BackButton, DataStatus } from "../../../../components";
import { Card } from "react-bootstrap";

// Page Component
import { FormSection, InfoSection } from "./Section";

const EditDeliveryOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([DeliveryOrderApi.getFormEdit(id)])
      .then(Axios.spread((res) => setData({
        ...res.data.data,
        alamat_pengiriman: location?.state?.data?.alamat_pengiriman ?? null,
        catatan: location?.state?.data?.catatan ?? null
      })))
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order");

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const TambahData = () => {
    const formInitialValues = {
      id_delivery_order: id,
      tgl_delivery_order: data.tgl_delivery_order,
      id_sales_order: data.id_sales_order,
      catatan_delivery_order: data.catatan_delivery_order,
    };

    const formValidationSchema = Yup.object().shape({
      tgl_delivery_order: Yup.string().required("Masukan tanggal delivery order "),
      // no_delivery_order: Yup.string().required("Masukan no delivery order "),
      // catatan_delivery_order: Yup.string().required("Masukan catatan delivery order"),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const detail = data?.detail?.map((val) => {
        return {
          id_sales_order: val.id_sales_order,
          id_sales_order_detail: val.id_sales_order_detail,
          qty: val.qty,
        };
      });

      const finalValues = {
        ...values,
        detail: detail,
        id_gudang: data.id_gudang
      };

      DeliveryOrderApi.update(finalValues)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Data berhasil diubah!",
            status: true,
          })
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: "Data gagal diubah!",
            status: true,
          })
        })
        .finally(() => {
          getData()
          setIsLoading(false);
        });
    };

    return (
      <>
        <InfoSection data={data} />

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormSection
                data={data}
                isLocation={true}
                location={{}}
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                type="update"
              />
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <b className="text-capitalize">Edit delivery order</b>
        <BackButton
          onClick={() => {
            history.push("/inventory/transaksi/delivery-order", { ...location?.state });
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={alertConfig.status}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({
            ...alertConfig,
            status: false
          })}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data !== undefined ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default EditDeliveryOrder;
