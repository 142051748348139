import React from 'react'

const InfoCatatanSection = ({ data }) => {
  const { catatan_surat_jalan } = data

  return (
    <div className='mb-1'>
      <b>Catatan Surat Jalan</b>
      <div style={{ fontSize: 14 }}>{catatan_surat_jalan ?? '-'}</div>
    </div>
  )
}

export default InfoCatatanSection