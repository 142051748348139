import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import { FilterModal, DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { PenerimaanBerasApi } from "api"
import Axios from "axios"

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [loading, setLoading] = useState(true)
    const [dropdownItem, setDropdownItem] = useState([{ label: 'Semua', value: undefined }])
    const [dropdownGudang, setDropdownGudang] = useState([{ label: 'Semua', value: undefined }])
// REQUSET DATA SERVER 
    const getDataDropdown = () => {
        Axios.all([
            PenerimaanBerasApi.dropdownItem(),
            PenerimaanBerasApi.dropdownGudang(),
        ])
        .then(Axios.spread((item, gudang) => {
            const mapDataPenerimaanBeras = item?.data?.data ? item.data.data.map(val => ({ label: val?.nama_item, value: val?.id_item_buaso })) : []
            const mapDataGudang = gudang?.data?.data ? gudang.data.data.map(gdg => ({ label: gdg.nama_gudang, value: gdg.id_gudang })) : []
            setDropdownItem([ ...dropdownItem, ...mapDataPenerimaanBeras ])
            setDropdownGudang([ ...dropdownGudang, ...mapDataGudang ])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_penerimaan_beras_mulai: data?.filter?.tgl_penerimaan_beras_mulai,
        tgl_penerimaan_beras_selesai: data?.filter?.tgl_penerimaan_beras_selesai,
        id_barang: data?.filter?.id_barang,
        id_gudang: data?.filter?.id_gudang,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalBerasChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_penerimaan_beras_mulai: startDate,
            tgl_penerimaan_beras_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_penerimaan_beras_mulai: undefined,
            tgl_penerimaan_beras_selesai: undefined,
            id_barang: undefined,
            id_gudang: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Penerimaan Beras"
                        placeholderText="Pilih Tanggal Penerimaan Beras"
                        dateFormat="dd/MM/yyyy"
                        startDate={values.tgl_penerimaan_beras_mulai ? new Date(values.tgl_penerimaan_beras_mulai) : ''}
                        endDate={values.tgl_penerimaan_beras_selesai ? new Date(values.tgl_penerimaan_beras_selesai) : ''}
                        onChange={dates => onTanggalBerasChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.id_barang}
                        label="Nama Beras"
                        placeholder="Pilih Nama Beras"
                        defaultValue={dropdownItem.find(val => val.value === values.id_barang)}
                        option={dropdownItem}
                        onChange={val => setFieldValue('id_barang', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.id_gudang}
                        label="Gudang Penerimaan"
                        placeholder="Pilih Gudang Penerimaan"
                        defaultValue={dropdownGudang.find(val => val.value === values.id_gudang)}
                        option={dropdownGudang}
                        onChange={val => setFieldValue('id_gudang', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;