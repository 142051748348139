import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Dropdown, DropdownButton, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  Select,
  SelectSearch,
  DeleteButton,
  InfoItemVertical,
  FilterButton,
  InfoItemHorizontal,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { KartuStockMaterialApi } from "../../../../api";
import { timestamp } from "./functions";
import { Divider, ExportButton, ModalFilter } from "./components";
import FileSaver from "file-saver";

const KartuStockMaterial = ({ setNavbarTitle }) => {
  // Variables
  const title = "Kartu Stok Barang"

  const [isLoading, setIsLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false)

  // MENYIMPAN DATA INFO SECTION
  const [head, setHead] = useState({});
  //MENYIMPAN LIST TABLE
  const [table, setTable] = useState({
    body: [],
  });

  // INITIALIZE SALDO AWAL
  let saldoAwal = table.saldo_awal ?? 0;

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: false,
    dari: undefined,
    sampai: undefined,
    id_jenis: undefined,
    id_barang: undefined,
    id_gudang: undefined,
    id_kelompok: undefined,
  })

  const toggleModal = () => {
    setModalFilter(!modalFilter)
  }

  useEffect(() => {
    setNavbarTitle(title);

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  useEffect(() => {
    saldoAwal = table.saldo_awal;
  }, [table.saldo_awal]);

  useEffect(() => {
    setIsLoading(true)

    KartuStockMaterialApi.getKartuStockMaterial({
      ...dataFilter,
      active: undefined
    }).then((res) => {
      setHead(res.data.data.head);
      setTable(res.data.data.table);
    }).catch(() => {
      setAlertConfig({
        variant: "danger",
        text: "Data gagal dimuat, tidak dapat menampilkan data",
      });
    }).finally(() => setIsLoading(false))
  }, [dataFilter])

  const exportData = tipe => {
    setLoadingExport(true)

    KartuStockMaterialApi.export({
      ...dataFilter,
      active: undefined,
      tipe
    }).then(res => {
      const data = res.data.data_only
      const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
      FileSaver.saveAs(urlFile, data);
    }).catch(() => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Export Data gagal",
      })
    }).finally(() => setLoadingExport(false))
  }

  return (
    <>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      <div className="d-flex">
        <FilterButton
          active={dataFilter?.active}
          onClick={toggleModal}
          className="mr-2"
          noMargin
        />

        <ExportButton loading={loadingExport}>
          <Dropdown.Item onClick={() => exportData('excel')}>Excel (.xlsx)</Dropdown.Item>
          <Dropdown.Item onClick={() => exportData('pdf')}>PDF (.pdf)</Dropdown.Item>
        </ExportButton>
      </div>

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <>
          <Divider>
            <Card>
              <Card.Body>
                <table>
                  <tbody>
                    <InfoItemHorizontal label="Kode Barang" text={head.kode_barang ?? "-"} />
                    <InfoItemHorizontal label="Nama Barang" text={head.nama_barang ?? "-"} />
                    <InfoItemHorizontal label="Gudang" text={head.nama_gudang ?? "-"} />
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Divider>

          <Divider>
            <b>List Data {title}</b>
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <ThFixed>Tanggal</ThFixed>
                  <ThFixed>No. Referensi</ThFixed>
                  <ThFixed>Tanggal Transaksi</ThFixed>
                  <Th>Keterangan</Th>
                  <ThFixed>Masuk</ThFixed>
                  <ThFixed>Keluar</ThFixed>
                  <ThFixed>Sisa</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {table?.body && table?.body?.length > 0 ? (
                  table.body.map((val, index) => {
                    if (parseInt(val.jumlah) > 0) {
                      saldoAwal += parseInt(val.jumlah);
                      val.sisa = parseInt(saldoAwal);
                    } else {
                      saldoAwal = saldoAwal - Math.abs(parseInt(val.jumlah));
                      val.sisa = parseInt(saldoAwal);
                    }

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>
                          <div>{DateConvert(new Date(val.created_at)).defaultDMY}</div>
                          <div>{timestamp(val.created_at)}</div>
                        </Td>
                        <Td className="text-nowrap">{val.no_ref_transaksi}</Td>
                        <Td>{val.tanggal_mutasi ? DateConvert(new Date(val.tanggal_mutasi)).defaultDMY : "-"}</Td>
                        <Td>{val.keterangan ?? "-"}</Td>
                        <Td className='text-right'>{parseInt(val.jumlah) > 0 ? RupiahConvert(String(val.jumlah)).withoutLabel : 0}</Td>
                        <Td className='text-right'>{parseInt(val.jumlah) < 0 ? RupiahConvert(String(Math.abs(val.jumlah))).withoutLabel : 0}</Td>
                        <Td className='text-right'>{RupiahConvert(String(val.sisa)).withoutLabel}</Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <td colSpan={7} className="text-center">
                      {" "}
                      Tidak ada data
                    </td>
                  </Tr>
                )}
              </TBody>
            </CRUDLayout.Table>
          </Divider>
        </>
      )}

      {modalFilter && (
        <ModalFilter
          toggleModal={toggleModal}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </>
  );
};

export default KartuStockMaterial;
