const InputField = ({
    label,
    type,
    name,
    placeholder,
    value,
    readOnly,
    error,
    errorText,
    onChange,
    onBlur,
    onFocus,
    autoComplete,
    maxLength,
    className,
    style,
  }) => {
    return (
      <div className={className} style={style}>
        <small>{label}</small>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`form-control form-control-sm ${error && "is-invalid"}`}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        <div className="invalid-feedback">{errorText}</div>
      </div>
    );
  };
export default InputField