import React, { useState, useEffect } from "react";
import {
  Modal
} from "react-bootstrap";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  SelectSearch,
  Alert,
  Pagination,
  THead,
  Tr,
  Th,
  TBody,
  ThFixed,
  TdFixed,
  Td,
  ListData,
  ReadButton,
  ReadModal,
} from "components";
import {
  PageNumber,
  TableNumber,
  HeadOfficeStatus
} from "utilities";
import { DepoApi } from 'api'
import { Button } from "react-bootstrap"
import { FormSection } from "./Section"

const Depo = ({ setNavbarTitle }) => {
// VARIABLE
  const title = "Depo"
// STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
// STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
// STATE DATA
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [dataDepo, setDataDepo] = useState([])
  const [kode, setKode] = useState([])
  const [dropdownUnitOrganisasi, setDropdownUnitOrganisasi] = useState([])
  const [dropdownPenanggungJawab, setDropdownPenanggungJawab] = useState([])
  console.log(dropdownPenanggungJawab)
// CONFIG
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false  
  })
  const [searchConfig, setSearchConfig] = useState({
      status: false,
      key: "",
  })
  const [paginationConfig, setPaginationConfig] = useState({
      currentPage: "1",
      dataLength: "10",
      dataCount: "0",
      totalPage: "1",
  })
  const [modalConfig, setModalConfig] = useState({
      show: false,
      type: 'create', 
      title: 'Depo',
      data: {}
  })
  const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "",
      text: "",
  })
// FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setFetchConfig({...fetchConfig, loading: true})
    setDropdownLoading(true)

    Axios.all([
      DepoApi.getPage({
          page: paginationConfig.currentPage,
          per_page: paginationConfig.dataLength,
          q: searchConfig.key
      }),
      DepoApi.getKode(),
      DepoApi.dropdownUnitOrganisasi(),
      DepoApi.dropdownPenanggungJawab()
    ])
    .then(Axios.spread((depo, kode, unit, karyawan) => {
      const mapUnitOrganisasi = unit?.data?.data ? unit.data.data.map(val => ({label: val?.nama_unit_organisasi, value: val?.id_unit_organisasi})) : []
      const mapKaryawan = karyawan?.data?.data ? karyawan.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []

      setDataDepo(depo?.data?.data ?? [])
      setKode(kode?.data?.data ?? [])
      setDropdownUnitOrganisasi([...mapUnitOrganisasi])
      setDropdownPenanggungJawab(karyawan?.data?.data ?? [])

      setPaginationConfig({
          ...paginationConfig,
          dataCount: depo?.data?.data_count ?? 0,
          totalPage: depo?.data?.total_page ?? 1, 
      })
      setFetchConfig({
          loading: false,
          success: true
      })
      setDropdownLoading(false)
    }))
    .catch(() => {
      setFetchConfig({
          loading: false,
          success: false
      })
      setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
      });
      setDropdownLoading(false)
    })
  }
// USE EFFECT
  useEffect(() => {
    setNavbarTitle('Depo')
    getData()
  }, [setNavbarTitle, paginationConfig.currentPage, paginationConfig.dataLength, searchConfig.key])
// CHECK DUPLICATE DATA
  const checkDuplicate = (value) => {
    // CEK DUPLIKAT KETIKA UPDATE DATA
    if (modalConfig.type === 'update') {
      if (updateData.nama_depo !== value) {
        return DepoApi.checkDuplicateNama({ nama_depo: value })
          .then(() => true)
          .catch(() => false)
      }
      return true
    } else {
      // CEK DUPLIKAT KETIKA CREATE DATA
      return DepoApi.checkDuplicateNama({ nama_depo: value })
        .then(() => true)
        .catch(() => false)
    }
  }
// FORM VALIDATIOM
  const formValidationSchema = Yup.object().shape({
    kode_depo: Yup.string().required("Masukkan Kode Depo"),
    nama_depo: Yup.string()
    .required("Masukkan Depo")
    .test("checkDuplicate", "Nama gudang sudah digunakan", async (value) => await checkDuplicate(value)),
    alamat_depo: Yup.string().required("Masukkan Alamat Depo"),
    // keterangan_depo: Yup.string().required("Masukkan Keterangan Depo"),
    id_unit_organisasi: Yup.string().required("Pilih Unit Organisasi"),
    id_pekerja: Yup.string().required("Pilih Penanggung Jawab"),
  })
  const formInitialValues = {
    kode_depo: modalConfig.type === "update" ? updateData.kode_depo : kode,
    nama_depo: modalConfig.type === "update" ? updateData.nama_depo : "",
    alamat_depo: modalConfig.type === "update" ? updateData.alamat_depo : "",
    keterangan_depo: modalConfig.type === "update" ? updateData.keterangan_depo : "",
    id_unit_organisasi: modalConfig.type === "update" ? updateData.id_unit_organisasi : "",
    id_pekerja: modalConfig.type === "update" ? updateData.id_pekerja : "",
  }
  const formSubmitHandler = (values) => {
    if (modalConfig.type === 'update') {
      const finalValues = { id_depo: updateData.id_depo, ...values }

      DepoApi.update(finalValues)
        .then(() => 
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        )
        .catch((err) => 
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          })
        )
        .finally(() => {
          setModalConfig({...modalConfig, show: false})
          getData();
        })
    } else {

      DepoApi.create(values)
        .then(() => 
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        )
        .catch((err) => 
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        )
        .finally(() => {
          setModalConfig({...modalConfig, show: false})
          getData();
        })
    }
  }
// MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // STATE DATA
    const [isUnitChange, setIsUnitChange] = useState(true);
    const [penanggungJawab, setPenanggungJawab] = useState([]);
    const [karyawanFilterd, setKaryawanFiltered] = useState([]);
    const [karyawanLoading, setKaryawanLoading] = useState(false);
    // REQUEST SERVER
    // const getPenanggungJawab = () => {
    //   setKaryawanLoading(true);

    //   DepoApi.dropdownPenanggungJawab()
    //     .then((data) => setPenanggungJawab(data.data.data))
    //     .finally(() => setKaryawanLoading(false));
    // };
    
    // const UnitOrganisasiChange = (id_unit) => {
    //   const karyawanfilter = penanggungJawab.filter((val) => val.id_unit_organisasi === id_unit);
    //   setKaryawanFiltered(karyawanfilter);
    // }
    // useEffect(() => getPenanggungJawab(), [])

    return (
      <CreateModal 
        show={modalConfig.type === 'create' ? modalConfig.show : ''} 
        onHide={() => setModalConfig({...modalConfig, show: false})}
        title={title}>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}>

          {formik => (
            <FormSection 
              formik={formik}
              onSubmit={formSubmitHandler}
              dataDepo={dataDepo}
              dropdownLoading={dropdownLoading}
              dropdownUnitOrganisasi={dropdownUnitOrganisasi}
              dropdownPenanggungJawab={dropdownPenanggungJawab}
              modalConfig={modalConfig}
              // UnitOrganisasiChange={UnitOrganisasiChange}
              // setIsUnitChange={setIsUnitChange}
              // isUnitChange={isUnitChange}
              // getPenanggungJawab={getPenanggungJawab}
              // karyawanLoading={karyawanLoading}
              // karyawanFilterd={karyawanFilterd}
            />
          )}
        </Formik>
      </CreateModal>
    )
  }
// MODAL UBAH COMPONENT
  const UbahModal = () => {
    return (
      <UpdateModal 
        show={modalConfig.type === 'update' ? modalConfig.show : ''} 
        onHide={() => setModalConfig({...modalConfig, show: false})}
        title={title}>
          
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {formik => (
            <FormSection 
            formik={formik}
            onSubmit={formSubmitHandler}
            dataDepo={dataDepo}
            dropdownLoading={dropdownLoading}
            dropdownUnitOrganisasi={dropdownUnitOrganisasi}
            dropdownPenanggungJawab={dropdownPenanggungJawab}
            modalConfig={modalConfig}
            formInitialValues={formInitialValues}
            />
          )}
        </Formik>
      </UpdateModal>
    );
  }
// MODAL DETAIL COMPONENT
  const DetailModal = () => (
    <ReadModal 
      show={modalConfig.type === 'detail' ? modalConfig.show : ''} 
      onHide={() => setModalConfig({...modalConfig, show: false})}
      title={title}>

      <Modal.Body>
        <ListData label="Kode Depo">{updateData.kode_depo ?? "-"}</ListData>
        <ListData label="Nama Depo">{updateData.nama_depo ?? "-"}</ListData>
        <ListData label="Alamat Depo">{updateData.alamat_depo ?? "-"}</ListData>
        <ListData label="Keterangan">{updateData.keterangan_depo ?? "-"}</ListData>
        <ListData label="Unit Organisasi">{updateData.nama_unit_organisasi ?? "-"}</ListData>
        <ListData label="Kepala Penanggung Jawab">{updateData.nama_karyawan ?? "-"}</ListData>
      </Modal.Body>
      <Modal.Footer>
          <Button 
            variant="outline-secondary"
            onClick={() => setModalConfig({...modalConfig, show: false})}>
            Tutup
          </Button>
      </Modal.Footer>
    </ReadModal>
  )
// MODAL HAPUS COMPONENT
  const HapusModal = () => {
  // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_depo: deleteData.id_depo };
  // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);
  // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      DepoApi.delete(deleteValue)
        .then(() =>
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        )
        .catch((err) => 
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Hapus data gagal!! (${err.response.data.message})`,
          })
        )
        .finally(() => {
          setModalConfig({...modalConfig, show: false})
          getData();
        });
    };

    return (
      <DeleteModal
        show={modalConfig.type === 'delete' ? modalConfig.show : ''} 
        onHide={() => setModalConfig({...modalConfig, show: false})}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}>

        <div>Kode Gudang : {deleteData.kode_depo}</div>
        <div>Nama Gudang : {deleteData.nama_depo}</div>
      </DeleteModal>
    );
  }
// TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <div className='mb-1 mt-1'>
            <span style={{fontSize:'14px'}}><b>List Depo</b></span>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Depo</ThFixed>
              <Th>Nama Depo</Th>
              <Th>Alamat Depo</Th>
              <Th>Unit Organisasi</Th>
              <Th>Penanggung Jawab</Th>
            </Tr>
          </THead>
          <TBody>
            {dataDepo.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed textCenter>
                    {TableNumber(paginationConfig.currentPage, paginationConfig.dataLength, index)}
                  </TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setUpdateData(val);
                          setModalConfig({show: true, type: 'detail'})
                        }}
                      />

                      {HeadOfficeStatus() && (
                        <>
                          <UpdateButton
                            onClick={() => {
                              setUpdateData(val);
                              setModalConfig({show: true, type: 'update'})
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              setDeleteData(val);
                              setModalConfig({show: true, type: 'delete'})
                            }}
                          />
                        </>
                      )}
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_depo ?? "-"}</TdFixed>
                  <Td style={{minWidth:'22 0px'}}>{val.nama_depo ?? "-"}</Td>
                  <Td>{val.alamat_depo ?? "-"}</Td>
                  <Td>{val.nama_unit_organisasi ?? "-"}</Td>
                  <Td>{val.nama_karyawan ?? "-"}</Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={paginationConfig.currentPage * paginationConfig.dataLength - paginationConfig.dataLength + 1}
          dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.currentPage * paginationConfig.dataLength}
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.currentPage}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) => setPaginationConfig({...paginationConfig, currentPage: selected + 1})}
          onDataLengthChange={(e) => {
              setPaginationConfig({...paginationConfig, 
                  currentPage: 1,
                  dataLength: e.target.value
              })
          }}
        />
      </>
    );
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex">
            <InputSearch  
                onChange={(e) => setSearchConfig({...searchConfig, key: e.target.value })}
                onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setModalConfig({show: true, type: 'create'})} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({...alertConfig, show: false})}
      />
      {
        fetchConfig.loading === true
        ? <DataStatus loading={fetchConfig.loading} text='Memuat data. . .' />
        : <Table />
      }
      {/* <Table /> */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
      <DetailModal />
    </CRUDLayout>
  );
};

export default Depo;
