import React, {
  useState,
  memo
} from 'react'
import {
  IoAddOutline,
  IoCheckmarkOutline
} from 'react-icons/io5'
import {
  Table,
  THead,
  TBody,
  Tr,
  Td,
  Th,
  DataStatus,
  ActionButton,
} from 'components'
import {
  ModalTransfer
} from '../Comp'

const ItemRow = memo(({ setModalConfig, index, val, action }) => {
  const tableStyling = { width: 100, padding: 0, fontSize: "14px" }

  // check jika qty telah transfer lebih besar samadengan qty permintaan produksi, 
  // maka tidak boleh menambah data transfer
  const isPreventCreate = Boolean(parseInt(val?.qty_telah_ditransfer ?? 0) >= parseInt(val?.qty_permintaan_produksi ?? 0))

  return (
    <tr>
      <Td textCenter width={20}> {index + 1} </Td>
      <Td>{val.kode_item}</Td>
      <Td>{val.nama_item}</Td>
      <Td>{val.satuan_pakai}</Td>
      <Td style={tableStyling} className="text-right">{parseFloat(val.qty_permintaan_produksi ?? 0)}</Td>
      <Td style={tableStyling} className="text-right">{parseFloat(val.qty_telah_ditransfer ?? 0)}</Td>
      <Td style={{ display: action === 'VIEW' ? 'none' : '' }}>
        {isPreventCreate
          ? (
            <ActionButton disable size="sm" variant="success" >
              <IoCheckmarkOutline />
            </ActionButton>
          )
          : (
            <ActionButton size="sm" onClick={() => setModalConfig({ show: true, data: val })} >
              <IoAddOutline />
            </ActionButton>
          )
        }
      </Td>
    </tr>
  )
})

export const TablePermintaan = ({ action, data, dataHistory, refresh, alertConfig, setAlertConfig }) => {
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })

  return (
    <>
      <div className="pb-2">
        <b>List Data Permintaan Produksi</b>
      </div>
      <Table>
        <THead className="bg-light">
          <Tr>
            <Th noPadding style={{ width: 40 }}>No</Th>
            <Th noPadding style={{ width: 150 }}>Kode Item</Th>
            <Th noPadding style={{ minWidth: 150 }}>Item Bahan</Th>
            <Th noPadding style={{ width: 100 }}>Satuan</Th>
            <Th noPadding style={{ width: 150 }}>Qty. Permintaan Produksi</Th>
            <Th noPadding style={{ width: 150 }}>Total Qty. Telah Diransfer</Th>
            <Th noPadding style={{ width: 40, display: action === 'VIEW' ? 'none' : '' }}>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0
            ? data.map((val, index) => (
              // <Tr key={index}>
              //   <Td className="text-center">
              //     {index + 1}
              //   </Td>
              //   <Td>{val?.kode_item ?? '-'}</Td>
              //   <Td>{val?.nama_item ?? '-'}</Td>
              //   <Td>{val?.satuan_pakai ?? '-'}</Td>
              //   <Td className="text-right">{parseFloat(val?.qty_permintaan_produksi ?? '0')}</Td>
              //   <Td className="text-right">{parseFloat(val?.qty_telah_ditransfer ?? '0')}</Td>
              //   <Td style={{ display: action === 'VIEW' ? 'none' : '' }}>
              //     <ActionButton
              //       size="sm"
              //       text={<IoAddOutline />}
              //       onClick={() => setModal({ ...modal, show: true, data: val })}
              //     />
              //   </Td>
              // </Tr>'
              <ItemRow
                key={index}
                index={index}
                val={val}
                action={action}
                modalConfig={modal}
                setModalConfig={setModal}
              />
            ))
            : <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          }
        </TBody>
      </Table>
      <ModalTransfer
        action="CREATE"
        data={modal?.data ?? {}}
        dataHistory={dataHistory}
        show={modal?.show}
        onHide={() => setModal({ ...modal, show: false })}
        refresh={refresh}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
      />
    </>
  )
}
