import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { PenerimaanSuratJalanApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataGudang, setDataGudang] = useState([{label: 'Semua', value: undefined}])
    const [dataPetugas, setDataPetugas] = useState([{label: 'Semua', value: undefined}])
    const [dataArmada, setDataArmada] = useState([{label: 'Semua', value: undefined}])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
        PenerimaanSuratJalanApi.getDropdownGudang(),
        PenerimaanSuratJalanApi.getDropdownPetugas(),
        PenerimaanSuratJalanApi.getDropdownArmada(),
        ])
        .then(Axios.spread((gudang, petugas, armada) => {
            const mapDataGudang = gudang?.data?.data ? gudang.data.data.map(val => ({label: val?.nama_gudang, value: val?.id_gudang})) : []
            const mapDataPetugas = petugas?.data?.data ? petugas.data.data.map(val => ({label: val?.nama_supir, value: val?.id_supir})) : []
            const mapDataArmada = armada?.data?.data ? armada.data.data.map(val => ({label: val?.nama_kendaraan, value: val?.id_kendaraan})) : []

            setDataGudang([...dataGudang, ...mapDataGudang])
            setDataPetugas([...dataPetugas, ...mapDataPetugas])
            setDataArmada([...dataArmada, ...mapDataArmada])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_surat_jalan_mulai: data?.filter?.tgl_surat_jalan_mulai,
        tgl_surat_jalan_selesai: data?.filter?.tgl_surat_jalan_selesai,
        tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
        tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
        batas_waktu_mulai: data?.filter?.batas_waktu_mulai,
        batas_waktu_selesai: data?.filter?.batas_waktu_selesai,
        gudang: data?.filter?.gudang,
        pengirim: data?.filter?.pengirim,
        armada: data?.filter?.armada,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalSuratJalanChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_surat_jalan_mulai: startDate,
            tgl_surat_jalan_selesai: endDate,
        })
    }
    const onTanggalDeliveryOrderChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_delivery_order_mulai: startDate,
            tgl_delivery_order_selesai: endDate,
        })
    }
    const onBatasWaktuChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            batas_waktu_mulai: startDate,
            batas_waktu_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_surat_jalan_mulai: undefined,
            tgl_surat_jalan_selesai: undefined,
            tgl_delivery_order_mulai: undefined,
            tgl_delivery_order_selesai: undefined,
            batas_waktu_mulai: undefined,
            batas_waktu_selesai: undefined,
            gudang: undefined,
            pengirim: undefined,
            armada: undefined
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Surat Jalan"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Surat Jalan"
                        startDate={values.tgl_surat_jalan_mulai ? new Date(values.tgl_surat_jalan_mulai) : ''}
                        endDate={values.tgl_surat_jalan_selesai ? new Date(values.tgl_surat_jalan_selesai) : ''}
                        onChange={dates => onTanggalSuratJalanChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Delivery Order"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Delivery Order"
                        startDate={values.tgl_delivery_order_mulai ? new Date(values.tgl_delivery_order_mulai) : ''}
                        endDate={values.tgl_delivery_order_selesai ? new Date(values.tgl_delivery_order_selesai) : ''}
                        onChange={dates => onTanggalDeliveryOrderChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Batas Waktu Pengiriman"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Batas Waktu Pengiriman"
                        startDate={values.batas_waktu_mulai ? new Date(values.batas_waktu_mulai) : ''}
                        endDate={values.batas_waktu_selesai ? new Date(values.batas_waktu_selesai) : ''}
                        onChange={dates => onBatasWaktuChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.gudang}
                        label="Gudang"
                        placeholder="Pilih Gudang"
                        defaultValue={dataGudang.find(val => val.value === values.gudang)}
                        option={dataGudang}
                        onChange={val => setFieldValue('gudang', val.value)}
                        loading={loading}
                    />
                    {/* <SelectSearch 
                        key={values.pengirim}
                        label="Petugas Pengiriman"
                        placeholder="Pilih Petugas Pengiriman"
                        defaultValue={dataPetugas.find(val => val.value === values.pengirim)}
                        option={dataPetugas}
                        onChange={val => setFieldValue('pengirim', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.armada}
                        label="Armada Pengiriman"
                        placeholder="Pilih Armada Pengiriman"
                        defaultValue={dataArmada.find(val => val.value === values.armada)}
                        option={dataArmada}
                        onChange={val => setFieldValue('armada', val.value)}
                        loading={loading}
                    /> */}
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;