// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

// Page Component
import { ListData } from "./index"

const InfoSJSection = ({ data }) => (
	<>
		<Row>
			<Col lg={3}>
				<ListData 
					title="Tgl. Surat Jalan"
					text={data.tgl_surat_jalan ? DateConvert(new Date(data.tgl_surat_jalan)).detail : "-"}
				/>
				<ListData 
					title="No. Surat Jalan"
					text={data.no_surat_jalan}
				/>
			</Col>
			<Col lg={3}>
				<ListData 
					title="Petugas Pengiriman"
					text={data.nama_sopir}
				/>
				<ListData 
					title="Armada Pengiriman"
					text={data.nama_item_aset}
				/>
			</Col>
		</Row>
	</>
)

export default InfoSJSection