// React
import { useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"

// Component
import { Card, Row, Col } from "react-bootstrap"
import { ActionButton, Alert, BackButton, DataStatus } from "components"

// API
import Axios from 'axios'
import { SuratJalanApi } from 'api'

// Page Component
import { InfoSection, InfoCatatanSection, TableSection } from "./Section"

// React Print
import { useReactToPrint } from "react-to-print"
import PrintSuratJalan from "../PrintSuratJalan"
// import { PrintSuratJalan } from "./PrintSuratJalan"
// import PrintSuratJalan from "./PrintSuratJalan"

const DetailSuratJalan = ({ setNavbarTitle }) => {

  const history = useHistory()
  const { id } = useParams()
  const refPrint = useRef()

  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const handlePrint = () => reactToPrint()

  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataStakeholder, setDataStakeholder] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const mappingDataTable = data => data.map(val => {
    const qtyDo = val.qty_do ? parseFloat(val.qty_do) : 0
    const qtyTerkirim = val.qty_sj ? parseFloat(val.qty_sj) : 0
    const qtySisaDo = parseFloat(qtyDo - qtyTerkirim)
    const qtySJ = val.qty ? parseFloat(val.qty) : "-"

    return {
      id_delivery_order_detail: val.id_delivery_order_detail ?? null,
      id_delivery_order: val.id_delivery_order ?? null,
      kode_item: val.kode_barang ?? "-",
      nama_item: val.nama_barang ?? "-",
      satuan: val.nama_satuan ?? "",
      qty_do: qtyDo,
      qty_terkirim: qtyTerkirim,
      qty_sisa_do: qtySisaDo,
      qty_surat_jalan: qtySJ,
    }
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      SuratJalanApi.getSingle({ id_surat_jalan: id }),
    ])
      .then(Axios.spread((dataDO) => {
        const getData = dataDO.data.data
        const getDataStakeholder = dataDO.data.data.stakeholder
        const mapDataTable = mappingDataTable(getData.detail ?? [])

        setDataInfo(getData)
        setDataTable(mapDataTable)
        setDataStakeholder(getDataStakeholder ?? [])
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Surat Jalan")

    // eslint-disable-next-line
  }, [])

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          {dataStakeholder.length > 1
            ? <Row>
              {dataStakeholder.map((val, index) => index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
              )}
            </Row>
            : <div className="d-flex justify-content-center">
              <DataStatus text="Tidak ada catatan" />
            </div>
          }
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Jalan</b>

          <div>
            <ActionButton className='mx-1 mb-1' text="Cetak" onClick={handlePrint} />
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() => setAlertConfig({
              ...alertConfig,
              show: false
            })}
          />
          {fetchingStatus.loading
            ? <DataStatus loading text="Memuat . . ." />
            : fetchingStatus.success
              ?
              <>
                <InfoSection data={dataInfo} />
                <TableSection dataTable={dataTable} />
                <InfoCatatanSection data={dataInfo} />

                <div className="d-flex justify-content-end">
                  <div style={{ display: "none" }}>
                    <PrintSuratJalan
                      ref={refPrint}
                      idRef={id}
                      data={dataInfo}
                      dataTable={dataTable} />
                  </div>
                </div>
              </>
              : <DataStatus text="Data gagal dimuat" />
          }
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && <CatatanApprovalCard />}
    </>
  )
}

export default DetailSuratJalan