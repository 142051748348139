// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

// Page Component
import { ListData } from "./index"

const InfoDOSection = ({ data }) => (
	<>
		<Row>
			<Col lg={3}>
				<ListData title="Tgl. Delivery Order" text={data.tgl_delivery_order ? DateConvert(new Date(data.tgl_delivery_order)).detail : '-'} />
			</Col>
			<Col lg={3}>
				<ListData title="No. Delivery Order" text={data.no_delivery_order ?? '-'} />
			</Col>
			<Col lg>
				<ListData title="Catatan Delivery Order" text={data.catatan_delivery_order ?? '-'} />
			</Col>
		</Row>
		<hr className="my-1" />
	</>
)

export default InfoDOSection