import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  Select,
  SelectSearch,
  DeleteButton,
} from "../../../../components";
import {
  IoTrashOutline,
  IoPencilOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5"
import { InputField } from "./Comps";
import { PurchaseRequestApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, ButtonGroup } from "react-bootstrap";
import { Formik, useFormik } from "formik";
import { TableItemPR } from "./Section";


const FormField = ({ formik, getDataJenis, getDataItem, dataIsLoading, getList, dataDetail, checkStatus }) => {
  const [dataNo, setDataNo] = useState("");
  const getNomor = (id) => {
    const date = Date.parse(id);

    if (isNaN(date) == false) {
      var dataDate = new Date(date).toISOString().split("T")[0].split("-").join("/");
      PurchaseRequestApi.getNoSurat(dataDate)
        .then((res) => {
          setDataNo(res.data.data);
        })
        .catch((err) => alert(err));
    }
  };
  const { values, errors, touched, setFieldValue, handleChange } = formik
  
  return (
    <div className="mb-4">
      <Row>
        <Col lg="6">
          <Input
            label="Tanggal Purchase Request"
            type="date"
            name="tgl_purchase_request"
            value={values.tgl_purchase_request}
            onChange={(e) => {
              const value = e.target.value;

              setFieldValue("tgl_purchase_request", value);
              setFieldValue("no_purchase_request", getNomor(value));
            }}
            onBlur={() => {
              setFieldValue("no_purchase_request", dataNo);
            }}
          />
        </Col>

        <Col lg="6">
          <Input
            label="No. Purchase Request"
            name="no_purchase_request"
            value={values.no_purchase_request}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Input
            label="Tanggal Pemakaian"
            type="date"
            name="tgl_pemakaian"
            value={values.tgl_pemakaian}
            onChange={handleChange}
            error={errors.tgl_pemakaian && touched.tgl_pemakaian && true}
            errorText={errors.tgl_pemakaian}
          />
        </Col>

        <Col lg="6">
          <Select
            label="Keperluan"
            name="keperluan"
            onChange={(e) => {
              const value = e.target.value;

              setFieldValue("keperluan", value);
            }}
            defaultValue={values.keperluan}
            error={errors.keperluan && touched.keperluan && true}
            errorText={errors.keperluan}
          >
            <option value="" hidden>
              Pilih Keperluan
            </option>
            <option value="produksi">Produksi</option>
            <option value="stok">Stok</option>
          </Select>
        </Col>
      </Row>

      <TextArea
        label="Catatan"
        type="text"
        name="catatan_purchase_request"
        placeholder="Masukkan Catatan Purchase Request"
        rows={2}
        value={values.catatan_purchase_request}
        onChange={handleChange}
      />

      <Row>
        <Col lg="2">
          <SelectSearch
            label="Kelompok"
            name="id_kelompok"
            placeholder="Pilih Kelompok"
            onChange={(val) => {
              setFieldValue("id_kelompok", val.value);
              setFieldValue("id_jenis", "");
              setFieldValue("id_item_buaso", "");
              getDataJenis(val.value);
            }}
            option={dataDetail.kelompok.map((val) => {
              return {
                value: val.id_kelompok,
                label: val.nama_kelompok,
              };
            })}
            isDisabled={checkStatus}
          />
        </Col>

        <Col lg="2">
          <SelectSearch
            label="Jenis"
            name="id_jenis"
            placeholder="Pilih Jenis"
            onChange={(val) => {
              setFieldValue("id_jenis", val.value);
              setFieldValue("id_item_buaso", "");
              getDataItem(val.value);
            }}
            option={dataDetail.jenis.map((val) => {
              return {
                value: val.id_jenis,
                label: val.nama_jenis,
              };
            })}
            loading={dataIsLoading.jenis}
            isDisabled={values.id_kelompok ? false : true ?? checkStatus}
          />
        </Col>

        <Col lg="3">
          <SelectSearch
            label="Item"
            name="id_item"
            placeholder="Pilih Item"
            onChange={(val) => {
              setFieldValue("id_item", val.value);
              setFieldValue("nama_satuan", val.satuan_beli);
              setFieldValue("kode_item", val.kode_item);
              setFieldValue("nama_item", val.label);
            }}
            option={dataDetail.item.map((val) => {
              return {
                value: val.id_item_buaso,
                label: val.nama_item,
                satuan_beli: val.satuan_beli,
                kode_item: val.kode_item,
              };
            })}
            loading={dataIsLoading.item}
            isDisabled={values.id_jenis ? false : true ?? checkStatus}
          />
        </Col>

        <Col lg="2">
          <Input
            label="Satuan"
            name="nama_satuan"
            placeholder="Pilih Satuan"
            value={values.nama_satuan}
            readOnly={true}
          />
        </Col>

        <Col lg="1">
          <Input 
            label="Qty" 
            type="number" 
            name="qty" 
            onChange={handleChange} 
            readOnly={checkStatus}
          />
        </Col>

        <Col lg="1">
          <label></label>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();

              getList(
                values.id_item,
                values.kode_item,
                values.nama_item,
                values.nama_satuan,
                values.qty
              );
            }}
            variant="primary"
            text="Tambah"
            disable={checkStatus}
          />
        </Col>
      </Row>
    </div>
  )
}
const ButtonSection = ({ formik }) => {
  const { handleSubmit, isSubmitting } = formik

  return(
    <div className="d-flex justify-content-end">
      <ActionButton
        variant="primary"
        text="Simpan"
        className="mt-2 px-4 mt-5"
        onClick={handleSubmit}
        loading={isSubmitting}
      />
    </div>  
  )
}

const UbahPurchaseRequest = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState({
    jenis: false,
    item: false,
  });
  // test tampilan saat ubah data list pr
  const [isEdit, setIsEdit] = useState(false)
  const [processedData, setProcessedData] = useState({})

  const history = useHistory();
  const location = useLocation();

  // data
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState({
    kelompok: [],
    jenis: [],
    item: [],
    itemWithOutId: [],
  });
  const [dataList, setDataList] = useState([]);
  // menampung data yang akan dihapus

  // Cek jika data status sama
  const isBelowThreshold = (currentValue) => currentValue?.nama_satuan === "Rim";
  const checkStatus = dataList?.every(isBelowThreshold)

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([
      PurchaseRequestApi.single(id), 
      PurchaseRequestApi.getKelompok(),
      PurchaseRequestApi.getItemWithOutId()
    ])
      .then(
        Axios.spread((res, kelompok, item) => {
          const mapDataItem = item?.data?.data 
            ? item.data.data.map(val => ({
                label: val?.nama_item,
                value: val?.id_item_buaso,
                satuanLabel: val?.satuan_beli,
                satuanValue: val?.id_satuan_pakai,
                kodeItem: val.kode_item,
            })) 
            : []
          setData(res.data.data);
          setDataList(res.data.data.detail);
          setDataDetail({
            ...dataDetail,
            kelompok: kelompok.data.data,
            jenis: [],
            item: [],
            itemWithOutId: mapDataItem,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Ubah Purchase Request");

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const formInitialValues = {
    id_purchase_request: data.id_purchase_request,
    tgl_purchase_request: data.tgl_purchase_request,
    no_purchase_request: data.no_purchase_request,
    tgl_pemakaian: data.tgl_pemakaian,
    keperluan: data.keperluan,
    catatan_purchase_request: data.catatan_purchase_request,
    id_kelompok: "",
    id_jenis: "",
    id_item_buaso: "",
  };
  const formValidationSchema = Yup.object().shape({
    tgl_purchase_request: Yup.date().required("Masukkan Tanggal Purchase Request"),
    tgl_pemakaian: Yup.date().required("Masukan Tanggal Pemakaian"),
    keperluan: Yup.string().required("Pilih Keperluan"),
  });
  // KIRIM DATA BARU KE SERVER
  const formSubmitHandler = (values) => {
    const detail = dataList;
    const finalValues = {
      ...values,
      detail,
    };

    PurchaseRequestApi.update(finalValues)
      .then(() => {
        history.push("/inventory/transaksi/purchase-request", {
          variant: "primary",
          text: "Purchase Request berhasil diubah",
          status: true,
        });
      })
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: `Ubah Purchase Request gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
})

  const getDataJenis = (id) => {
    setDataIsLoading({
      jenis: true,
      item: true,
    });

    PurchaseRequestApi.getJenis(id)
      .then((res) =>
        setDataDetail({
          ...dataDetail,
          jenis: res.data.data,
          item: [],
        })
      )
      .catch((err) => alert("Data Jenis gagal dimuat | " + err))
      .finally(() => {
        setDataIsLoading({
          jenis: false,
          item: false,
        });
      });
  };
  const getDataItem = (id) => {
    setDataIsLoading({
      item: true,
    });

    PurchaseRequestApi.getItem(id)
      .then((res) =>
        setDataDetail({
          ...dataDetail,
          item: res.data.data,
        })
      )
      .catch((err) => alert("Data Item gagal dimuat | " + err))
      .finally(() => {
        setDataIsLoading({
          item: false,
        });
      });
  };

  const getList = (id, kode, nama, satuan, qty) => {
    if (id != null && qty != null) {
      setDataList([
        ...dataList,
        {
          id_item_buaso: id,
          kode_item: kode,
          nama_item: nama,
          nama_satuan: satuan,
          qty: qty,
        },
      ]);
    } else {
      alert("Item dan Qty tidak boleh kosong");
    }
  };

  return (
  <CRUDLayout>
    <CRUDLayout.Head>
      <CRUDLayout.HeadButtonSection>
        <BackButton onClick={() => {history.push("/inventory/transaksi/purchase-request", {...location?.state})}} />
      </CRUDLayout.HeadButtonSection>
    </CRUDLayout.Head>
    <span>Ubah Purchase Request</span>
    <Card>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true 
          ? (<DataStatus loading={true} text="Memuat data..." />) 
          : ( <>
                <FormField 
                  formik={formik} 
                  getDataJenis={getDataJenis}
                  getDataItem={getDataItem}
                  dataIsLoading={dataIsLoading}
                  getList={getList}
                  dataDetail={dataDetail}
                  checkStatus={checkStatus}
                />
                <Alert
                  show={alertConfig.status}
                  showCloseButton={true}
                  variant={alertConfig.variant}
                  text={alertConfig.text}
                  onClose={() => setAlertConfig({ ...alertConfig, status: false })}
                />
                <TableItemPR
                  dataDetail={dataDetail}
                  setDataDetail={setDataDetail}
                  dataList={dataList}
                  setDataList={setDataList}
                  isType="UPDATE"
                />
                <ButtonSection formik={formik} />
              </>
        )}
      </Card.Body>
    </Card>
  </CRUDLayout>
  );
};

export default UbahPurchaseRequest;


// const FormCard = () => {
//   const InfoItem = ({ title1, value1, title2, value2 }) => (
//     <div className="mb-2">
//       <small>{title1}</small>
//       <br />
//       <b>{value1}</b>
//       <div className="mt-1" />
//       <small>{title2}</small>
//       <br />
//       <b>{value2}</b>
//     </div>
//   );

//   return (
//     <Card className="mt-4 mb-5">
//       <Card.Header className="d-flex justify-content-between align-items-center">
//         <b>Catatan Approval Purchase Request</b>
//       </Card.Header>
//       <Card.Body>
//         <Row>
//           {data.stakeholder.map(
//             (val, index) =>
//               index !== 0 &&
//               val.status_approval !== "PEN" && (
//                 <Col sm>
//                   <InfoItem
//                     title1={
//                       val.status_approval === "VER" || val.status_approval === "REV"
//                         ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
//                         : val.status_approval === "APP"
//                           ? "Pengesah"
//                           : "Di Tolak Oleh"
//                     }
//                     value1={val.nama_karyawan ?? "-"}
//                     title2="Catatan"
//                     value2={val.catatan ?? "-"}
//                   />
//                 </Col>
//               )
//           )}
//         </Row>
//       </Card.Body>
//     </Card>
//   );
// };
// <FormCard />