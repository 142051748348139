// React
import React, { forwardRef, useEffect } from "react"

// Component
import { Col, Row } from "react-bootstrap"

// Page Component
import { KopSuratSection } from "./Section"
import { InfoSection, InfoCatatanSection, TableSection } from "./DetailSuratJalan/Section"

const PrintSuratJalan = forwardRef((props, ref) => {
  let id = props.id
  let data = props.data
  let dataTable = props.dataTable

  useEffect(() => { }, [id])

  const TTDSection = () => {
    const TTDItems = ({ title, value }) => (
      <Col className="text-center">
        <div>{title}</div>
        <div style={{ marginTop: 100 }}>
          <hr style={{ maxWidth: 250, borderColor: "black" }} />
          {value}
        </div>
      </Col>
    )

    return (
      <Row className="mt-5 pt-3 d-flex justify-content-around">
        <TTDItems
          title="Penerima,"
          value=""
        />
        <TTDItems
          title="Hormat Kami,"
          value="PT. PUSPA BERAS BALI"
        />
      </Row>
    )
  }

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />
      <InfoSection data={data} />
      <TableSection dataTable={dataTable} />
      <InfoCatatanSection data={data} />
      <TTDSection />
    </div >
  )
})

export default PrintSuratJalan