import Services from "../../../services";

class JenisApi {
  getPage(page, dataLength, key) {
    return Services.get(`/jenisaset/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getDataKelompok() {
    return Services.get("/jenisaset/kelompok?id_buaso=3&flag=ASM");
  }
  create(data) {
    return Services.post("/jenisaset", data);
  }
  update(data) {
    return Services.put("/jenisaset", data);
  }
  delete(id_jenis) {
    return Services.post("/jenisaset/delete", id_jenis);
  }
  search(key) {
    return Services.get(`/jenisaset/page/?q=${key}`);
  }
  show(id_jenis) {
    return Services.post("/jenisaset/show", id_jenis);
  }
  hide(id_jenis) {
    return Services.post("/jenisaset/hide", id_jenis);
  }
}

export default new JenisApi();
