import Services from 'services'
import UploadServices from 'services/UploadServices'

class RegistrasiBarangJadiApi {
    page(value) {
        return Services.get(`/registrasi_barang_jadi/page`, { params: { ...value } });
    }
    single(value) {
        return Services.get(`/registrasi_barang_jadi/single`, { params: { ...value } });
    }
    dropdown(value) {
        return Services.get(`/registrasi_barang_jadi/dropdown`, { params: { ...value } });
    }
    no_baru() {
        return Services.get(`/registrasi_barang_jadi/no_baru`);
    }
    create(value) {
        return Services.post(`/registrasi_barang_jadi`, value);
    }
    update(value) {
        return Services.post(`/registrasi_barang_jadi/update`, value);
    }
    delete(value) {
        return Services.post(`/registrasi_barang_jadi/delete`, value);
    }
    show(value) {
        return Services.post(`/registrasi_barang_jadi/show`, value);
    }
    hide(value) {
        return Services.post(`/registrasi_barang_jadi/hide`, value);
    }
    uploadFileMultiple(data) {
        return UploadServices.post("/upload/registrasi_barang_jadi_multiple", data);
    }
    uploadFile(data) {
        return Services.post("/registrasi_barang_jadi/gambar", data);
    }
    deleteFile(id) {
        return Services.post("/registrasi_barang_jadi/gambar_delete", id);
    }
    cek_nama(params) {
        return Services.get('/registrasi_barang_jadi/cek_nama', { params });
    }
}

export default new RegistrasiBarangJadiApi();