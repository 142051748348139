import React, { useState, memo } from 'react'
import {
    SelectSearch,
    ActionButton,
    Td,
    Tr,
    TdFixed,
    Alert
} from 'components'
import {
  IoTrashOutline,
  IoPencilOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5"
import { Modal, Spinner, ButtonGroup } from "react-bootstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { RupiahConvert, DecimalConvert } from "utilities"
import { InputField, ReactNumberFormatField } from "../Comps"

const TableItemPR = ({ 
  isType, 
  dataDetail,
  setDataDetail,
  dataList,
  setDataList,
}) => {
    console.log("dataDetail", dataDetail)
  const [isEdit, setIsEdit] = useState(false)
  const [processedData, setProcessedData] = useState({})
  const [testButton, setTestButton] = useState("")

  const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "",
      title: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  })

  const FormSection = memo(({ type }) => {
    const formInitialValues = {
      id_item_buaso: type === "update" ? processedData?.id_item_buaso : "",
      qty: type === "update" ? processedData?.qty : "",
      nama_satuan: type === "update" ? processedData?.nama_satuan : "",
      kode_item: type === "update" ? processedData?.kode_item : ""
    }
    const formValidationSchema = Yup.object().shape({
      id_item_buaso: Yup.string().required(),
      qty: Yup.string().required(),
    })
    const formSubmitHandler = (values, { setSubmitting }) => {
      let newData = []

      if (type === "update") {
          const update = dataList?.map((item, index) =>
          Boolean(index === processedData.index) ? values : item
        )
        newData = update
      } 
      else {
        newData = [...dataList];
      }

      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil ditambah!",
        });
        setDataList(newData)

        setIsEdit(false)
        setSubmitting(false)
        setProcessedData({})
      }, 300)
    }
    
    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    })
  
    const { values, errors, touched, setValues, setFieldValue, handleSubmit, handleChange, isSubmitting } = formik
  
    return (
        <tr>
            <TdFixed>{processedData?.index + 1}</TdFixed>
            <Td width={100} className="text-center">{values?.kode_item}</Td>
            {/* Item Barang */}
            <Td>
                <SelectSearch
                    noMargin
                    name="id_item_buaso"
                    placeholder="Pilih item"
                    defaultValue={values.id_item_buaso ? dataDetail?.itemWithOutId.find((item) => item?.value === values?.id_item_buaso) : ""}
                    option={dataDetail.itemWithOutId}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={(val) => {
                      setFieldValue("id_item_buaso", val.value);
                      setFieldValue("nama_satuan", val.satuanLabel);
                      setFieldValue("kode_item", val.kodeItem);
                      setFieldValue("nama_item", val.label);
                    }}
                    error={Boolean(errors.id_item_buaso && touched.id_item_buaso)}
                />
            </Td>
            {/* Satuan */}
            <Td width={150}>
                <InputField name="nama_satuan" value={values.nama_satuan} readOnly={true} />
            </Td>
            {/* Qty */}
            <Td width={150}>
                <InputField type="number" name="qty" value={values?.qty ? parseInt(values?.qty) : "0"} onChange={handleChange} />
            </Td>
            <Td>
            {type === "update" && (
                // Button outline centang dan silang saat update list table
                <ButtonGroup style={{ width: "100%" }}>
                  <ActionButton
                      size="sm"
                      variant="outline-success"
                      onClick={handleSubmit}
                      disable={isSubmitting}
                  >
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : <IoCheckmark />}
                  </ActionButton>
                  <ActionButton
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        setIsEdit(false)
                        setProcessedData({})
                      }}
                      disable={isSubmitting}
                  >
                      <IoCloseOutline />
                  </ActionButton>
                </ButtonGroup>
            )}
            </Td>
        </tr>
    )
  })
  const deleteList = (index) => {
    const array = [...dataList];
    array.splice(index, 1);
    setDataList(array);
  };

  const classStyling = "align-middle p-2"
  
  const TabelData = ({ val, index }) => {

    return (isEdit && index === processedData.index) 
      ?   <FormSection type="update" />
      :   <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td width={100} className="text-center">{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td width={150}>{val.nama_satuan}</Td>
                <Td width={150}><div className="text-right">{parseInt(val.qty)}</div></Td>
                <TdFixed>
                  <div className='text-right'>
                    {val.status_seleksi_vendor
                    ? <>
                        <ActionButton
                          size="sm"
                          variant="success"
                          text={<IoCheckmark />}
                          disable={true}
                        />
                      </>
                    : <ButtonGroup style={{ width: "100%" }}>
                        <ActionButton
                            size="sm"
                            variant="success"
                            text={<IoPencilOutline />}
                            onClick={() => {
                                setTestButton("updateAction")
                                setIsEdit(true)
                                setProcessedData({ ...val, index: index })
                            }}
                        />
                        <ActionButton
                            size="sm"
                            variant="danger"
                            text={<IoTrashOutline />}
                            onClick={() => { deleteList(index)}}
                        />
                        </ButtonGroup>
                    }
                  </div>
                </TdFixed>
            </Tr>
  }
  const TableSection = () => {
    const tableStyling = {
      padding: "0.5px",
      fontSize: "14px",
      verticalAlign: "middle",
    };
      return(
          <>
            <div className="font-weight-bold mt-4" style={{ fontSize: "14px" }}>List Item Purchase Request</div>
            <div className="table-responsive">
              <table className="table table-bordered bg-white table-sm">
                <thead className="text-center">
                    <tr>
                        <th className={classStyling} style={{ ...tableStyling, width: 10 }}>No</th>
                        <th className={classStyling} style={{ ...tableStyling, width: 30 }}>Kode Item</th>
                        <th className={classStyling} style={tableStyling}>Nama Item</th>
                        <th className={classStyling} style={{ ...tableStyling, width: 20 }}>Satuan</th>
                        <th className={classStyling} style={{ ...tableStyling, width: 20 }}>Qty</th>
                        <th className={classStyling} style={{ ...tableStyling, width: 10 }}>Aksi</th>
                    </tr>
                </thead>
                <tbody>
                  {dataList?.length > 0
                    ? dataList?.map((val, index) => {
                      return isEdit && index === processedData.index
                        ? <FormSection key={index} type="update" />
                        : <TabelData key={index} val={val} index={index} />})
                    : null
                  }
                </tbody>
              </table>
            </div>
          </>
      )
  }
    
  return (
    <>
      <Alert
        show={alertConfig.status}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, status: false })}
      />
      <TableSection />
    </>
  )
}

export default TableItemPR