// React
import React, { useState, useEffect } from 'react'

// Form
import { Formik } from 'formik'
import * as Yup from 'yup'

// Components
import { 
	DatePicker, FilterModal, SelectSearch 
} from 'components'
import { DateConvert } from 'utilities'

// API
import Axios from "axios"
import { KartuStockMaterialApi } from 'api'

// View Functions
import { 
	mappingData, mappingDataItem 
} from '../functions'

const ModalFilter = ({ toggleModal, data, setData }) => {
	// States
	const [dropdownFilter, setDropdownFilter] = useState({
		kelompok: [],
		jenis: [],
		barang: [],
		gudang: []
	})
	const [loading, setLoading] = useState(true)
	const [loadingJenis, setLoadingJenis] = useState(false)
	const [loadingBarang, setLoadingBarang] = useState(false)

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		Axios.all([
			KartuStockMaterialApi.getDropdownKelompok(),
			KartuStockMaterialApi.getDropdownGudang(),
			data.id_kelompok && KartuStockMaterialApi.getDropdownJenis(data.id_kelompok),
			data.id_jenis && KartuStockMaterialApi.getDropdownMaterial(data.id_jenis)
		]).then(Axios.spread((kelompok, gudang, jenis, barang) => {
			setDropdownFilter({
				kelompok: mappingData(kelompok, 'kelompok'),
				gudang: mappingData(gudang, 'gudang'),
				jenis: data.id_kelompok ? mappingData(jenis, 'jenis') : [],
				barang: data.id_jenis ? mappingDataItem(barang, 'item_buaso') : []
			})
		})).finally(() => setLoading(false))
	}

	const getDataJenis = (value, values, setValues) => {
		setValues({
			...values,
			id_kelompok: value,
			id_jenis: null,
			id_barang: null
		})

		setLoadingJenis(true)
		KartuStockMaterialApi.getDropdownJenis(value).then(jenis => {
			setDropdownFilter({
				...dropdownFilter,
				jenis: mappingData(jenis, 'jenis'),
				barang: []
			})
		}).catch(() => {
			setDropdownFilter({
				...dropdownFilter,
				jenis: [],
				barang: []
			})
		}).finally(() => setLoadingJenis(false))
	}

	const getDataBarang = (value, values, setValues) => {
		setValues({
			...values,
			id_jenis: value,
			id_barang: null
		})

		setLoadingBarang(true)
		KartuStockMaterialApi.getDropdownMaterial(value).then(barang => {
			setDropdownFilter({
				...dropdownFilter,
				barang: mappingDataItem(barang, 'item_buaso')
			})
		}).catch(() => {
			setDropdownFilter({
				...dropdownFilter,
				barang: []
			})
		}).finally(() => setLoadingBarang(false))
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()
		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		dari: data.dari,
		sampai: data.sampai,
		id_jenis: data.id_jenis,
		id_barang: data.id_barang,
		id_gudang: data.id_gudang,
		id_kelompok: data.id_kelompok
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive
		})

		toggleModal()
	}

	// HANDLE CHANGE
	const onResetButtonClick = setValues => {
		setLoading(true)

		const myPromise = new Promise(resolve => {
			setValues({
				dari: null,
				sampai: null,
				id_kelompok: null,
				id_jenis: null,
				id_barang: null,
				id_gudang: null,
			})

			setTimeout(() => resolve(), 300)
		})

		myPromise.then(() => setLoading(false))
	}

	const onTanggalChange = (dates, values, setValues, value_awal, value_akhir) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null
	
		setValues({
		  ...values,
		  [value_awal]: startDate,
		  [value_akhir]: endDate
		})
	}

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
			validationSchema={Yup.object().shape({
				dari: Yup.date().typeError("Masukkan Tanggal").required("Masukkan Tanggal"),
				sampai: Yup.date().required("Masukan Tanggal"),
				id_kelompok: Yup.string().required("Pilih Kelompok").nullable(),
				id_jenis: Yup.string().required("Pilih Jenis").nullable(),
				id_barang: Yup.string().required("Pilih Barang").nullable(),
				id_gudang: Yup.string().required("Pilih Gudang").nullable(),
			})}
		>	
			{({values, errors, touched, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					isToggle
					toggleModal={toggleModal}
					onResetButtonClick={() => onResetButtonClick(setValues)}
					onFilterButtonClick={handleSubmit}
				>
					<DatePicker 
						selectsRange
						label="Tanggal"
						placeholderText="Pilih Tanggal"
						startDate={values.dari ? new Date(values.dari) : ''}
						endDate={values.sampai ? new Date(values.sampai) : ''}
						onChange={dates => onTanggalChange(dates, values, setValues, 'dari', 'sampai')}
						monthsShown={2}
						error={Boolean(errors.dari || errors.sampai) && Boolean(touched.dari || touched.sampai) && true}
						errorText={errors.dari}
					/>

					<SelectSearch 
						key="1"
						label="Kelompok"
						placeholder="Pilih Kelompok"
						defaultValue={dropdownFilter.kelompok.find(val => val.value === values.id_kelompok)}
						option={dropdownFilter.kelompok}
						onChange={val => getDataJenis(val.value, values, setValues)}
						loading={loading}
						error={errors.id_kelompok && touched.id_kelompok && true}
						errorText={errors.id_kelompok}
					/>

					<SelectSearch 
						key="2"
						label="Jenis"
						placeholder="Pilih Jenis"
						defaultValue={dropdownFilter.jenis.find(val => val.value === values.id_jenis)}
						option={dropdownFilter.jenis}
						onChange={val => getDataBarang(val.value, values, setValues)}
						loading={loading || loadingJenis}
						isDisabled={!Boolean(values.id_kelompok)}
						error={errors.id_jenis && touched.id_jenis && true}
						errorText={errors.id_jenis}
					/>

					<SelectSearch 
						key="3"
						label="Nama Barang"
						placeholder="Pilih Barang"
						defaultValue={dropdownFilter.barang.find(val => val.value === values.id_barang)}
						option={dropdownFilter.barang}
						onChange={val => setFieldValue('id_barang', val.value)}
						loading={loading || loadingBarang}
						isDisabled={!Boolean(values.id_jenis)}
						error={errors.id_barang && touched.id_barang && true}
						errorText={errors.id_barang}
					/>

					<SelectSearch 
						key="4"
						label="Gudang"
						placeholder="Pilih Gudang"
						defaultValue={dropdownFilter.gudang.find(val => val.value === values.id_gudang)}
						option={dropdownFilter.gudang}
						onChange={val => setFieldValue('id_gudang', val.value)}
						loading={loading}
						error={errors.id_gudang && touched.id_gudang && true}
						errorText={errors.id_gudang}
					/>
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter