// React
import React, { forwardRef, useEffect } from "react"

// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

// Page Component
import { KopSuratSection, ListData, TableSection } from "./Components"

const Print = forwardRef((props, ref) => {
  let id = props.id
  let data = props.data
  let dataTable = props.dataTable

  useEffect(() => { }, [id])

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />

      <Row className="mt-5">
        <Col>
          <ListData title="Tgl. Sales Order" text={data.tgl_sales_order ? DateConvert(new Date(data.tgl_sales_order)).detail : '-'} />
          <ListData title="No. Sales Order" text={data.no_sales_order ?? '-'} />
          <ListData title="Batas Waktu Pengiriman" text={data.batas_waktu ? DateConvert(new Date(data.batas_waktu)).detail : '-'} />
        </Col>
        <Col>
          <ListData title="Sales" text={data.nama_sales ?? '-'} />
          <ListData title="Customer" text={data.nama_customer ?? '-'} />
          <ListData title="Gudang" text={data.nama_gudang ?? '-'} />
        </Col>
        <Col>
          <ListData title="Alamat Pengiriman" text={data.alamat_pengiriman ?? '-'} />
          <ListData title="Catatan Sales Order" text={data.catatan ?? '-'} />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <ListData title="Tanggal Delivery Order" text={DateConvert(new Date(data.tgl_delivery_order)).detail} />
        </Col>
        <Col>
          <ListData title="No. Delivery Order" text={data.no_delivery_order} />
        </Col>
        <Col>
          <ListData title="Catatan Delivery Order" text={data.catatan_delivery_order} />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <ListData 
            title="Tgl. Surat Jalan"
            text={data.tgl_surat_jalan ? DateConvert(new Date(data.tgl_surat_jalan)).detail : "-"}
          />
          <ListData 
            title="No. Surat Jalan"
            text={data.no_surat_jalan}
          />
        </Col>
        <Col>
          <ListData 
            title="Petugas Pengiriman"
            text={data.nama_sopir}
          />
          <ListData 
            title="Armada Pengiriman"
            text={data.nama_item_aset}
          />
        </Col>
        <Col />
      </Row>
      <hr />

      <Row>
        <Col lg={3}>
          <ListData 
            title="Tgl. Penerimaan Surat Jalan"
            text={data.tgl_penerimaan_surat_jalan ? DateConvert(new Date(data.tgl_penerimaan_surat_jalan)).detail : "-"}
          />
        </Col>
        <Col lg={3}>
          <ListData 
            title="No. Penerimaan Surat Jalan"
            text={data.no_penerimaan_surat_jalan}
          />
        </Col>
      </Row>

      <TableSection dataTable={dataTable} />
    </div >
  )
})

export default Print