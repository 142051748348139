// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

// Page Component
import { ListData } from "./index"

const InfoPSJSection = ({ data }) => (
	<>
		<Row>
			<Col lg={3}>
				<ListData 
					title="Tgl. Penerimaan Surat Jalan"
					text={data.tgl_penerimaan_surat_jalan ? DateConvert(new Date(data.tgl_penerimaan_surat_jalan)).detail : "-"}
				/>
			</Col>
			<Col lg={3}>
				<ListData 
					title="No. Penerimaan Surat Jalan"
					text={data.no_penerimaan_surat_jalan}
				/>
			</Col>
		</Row>
	</>
)

export default InfoPSJSection