import { useState, useEffect } from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import {
  CRUDLayout,
  ActionButton,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  ReadModal,
  InfoItemHorizontal
} from "components";
import { DateConvert } from "utilities";
import { PurchaseRequestApi } from "api";

const ModalDetail = ({ modalConfig, setModalConfig, setAlertConfig, handlePrint }) => {
  const { id } = modalConfig
  const [readData, setReadData] = useState({ detail: [] });
  const [isLoadingRead, setIsLoadingRead] = useState(false);

  useEffect(() => {
    setIsLoadingRead(true);
    getReadData(id);

  }, []);

  const getReadData = (id) => {
    setReadData({ detail: [] });

    PurchaseRequestApi.single(id)
      .then((res) => {
        setReadData(res.data.data);
        setIsLoadingRead(false);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Purchase Request gagal dimuat",
        });
      });
  };

  // const hurufPertama = tulisan.charAt(0).toUpperCase() + tulisan.substr(1)
  // const hurufDiKapital = hurufPertama.toUpperCase() 
  // const hasil = hurufDiKapital + tulisan.substr(1) 
  // console.log(hurufDiKapital)

  return (
    <>
      <ReadModal
        size="xl"
        title="Purchase Request"
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false, type: 'detail', id: null })}>

        <Modal.Body>
          {isLoadingRead == true 
            ? (<DataStatus loading={true} text="Memuat data..." />) 
            : (<div className="mb-2">
                  {/* Info Section */}
                  <Card className="pl-3 pt-4 pb-4 pr-3 mb-4">
                    <Row>
                      <Col lg="6">
                        <InfoItemHorizontal
                          label="Tgl. Purchase Request"
                          text={readData.tgl_purchase_request ? DateConvert(new Date(readData.tgl_purchase_request)).detail : "-"}
                        />
                        <InfoItemHorizontal
                          label="Tgl. Pemakaian"
                          text={readData.tgl_pemakaian ? DateConvert(new Date(readData.tgl_pemakaian)).detail : "-"}
                        />
                        <InfoItemHorizontal 
                          label="Catatan" 
                          text={readData.catatan_purchase_request ?? "-"} 
                        />
                      </Col>
                      <Col lg="6">
                        <InfoItemHorizontal 
                          label="No. Purchase Request" 
                          text={readData.no_purchase_request ?? "-"} 
                        />
                        <InfoItemHorizontal 
                          label="Keperluan" 
                          text={readData.keperluan ? readData.keperluan.charAt(0).toUpperCase() + readData.keperluan.substr(1) : "-"} 
                        />
                      </Col>
                    </Row>
                  </Card>

                  {/* Info Item */}
                  <div className="font-weight-bold" style={{ fontSize: "14px" }}>List Item Purchase Request</div>
                  <CRUDLayout.Table>
                    <THead>
                      <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <Th width={100}>Kode Item</Th>
                        <Th>Item Barang</Th>
                        <Th width={120}>Status Seleksi Vendor</Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {readData.detail.map((val, index) => {
                        const rowSpan = readData.detail.length ?? 1
                        return (
                          <Tr key={readData.index}>
                            {index === 0 && <TdFixed rowSpan={rowSpan}>1</TdFixed>}
                            <TdFixed>{val.kode_item}</TdFixed>
                            <Td>{`${val.nama_item} (${parseFloat(val.qty)} ${val.nama_satuan})`}</Td>
                            <Td className="text-center">
                              {val.status_seleksi_vendor
                                ? <div className="text-success">Sudah Diseleksi</div>
                                : <div className="text-danger">Belum Diseleksi</div>
                              }
                            </Td>
                          </Tr>
                        )
                      })}
                    </TBody>
                  </CRUDLayout.Table>
                </div>
              )
          }
        </Modal.Body>
        <Modal.Footer>
          <ActionButton 
            text="Tutup"
            variant="light"
            onClick={() => setModalConfig({ show: false, type: 'detail', id: null })}
          />
        </Modal.Footer>
      </ReadModal>
    </>
  );
};

export default ModalDetail


// {/* <div>
//   <strong>Catatan : </strong>
//   <br></br>
//   {readData.catatan_purchase_request}
// </div> */}

// {readData.status_approval == "app" ? (
//   <Modal.Footer>
//     <ActionButton
//       type="button"
//       variant="primary"
//       text="Cetak Purchase Request"
//       onClick={handlePrint}
//     />
//   </Modal.Footer>
// ) : (
//   <></>
// )}

// {/* <FormCard /> */}

// const FormCard = () => {
//   const InfoItem = ({ title1, value1, title2, value2 }) => (
//     <div className="mb-2">
//       <small>{title1}</small>
//       <br />
//       <b>{value1}</b>
//       <div className="mt-1" />
//       <small>{title2}</small>
//       <br />
//       <b>{value2}</b>
//     </div>
//   );

//   return (
//     <Card className="mt-4 mb-5">
//       <Card.Header className="d-flex justify-content-between align-items-center">
//         <b>Catatan Approval Purchase Request</b>
//       </Card.Header>
//       <Card.Body>
//         <Row>
//           {readData.stakeholder === undefined
//             ? "Memuat Data"
//             : readData?.stakeholder?.map(
//               (val, index) =>
//                 index !== 0 &&
//                 val.status_approval !== "PEN" && (
//                   <Col sm>
//                     <InfoItem
//                       title1={
//                         val.status_approval === "VER" || val.status_approval === "REV"
//                           ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""
//                           }`
//                           : val.status_approval === "APP"
//                             ? "Pengesah"
//                             : "Di Tolak Oleh"
//                       }
//                       value1={val.nama_karyawan ?? "-"}
//                       title2="Catatan"
//                       value2={val.catatan ?? "-"}
//                     />
//                   </Col>
//                 )
//             )}
//         </Row>
//       </Card.Body>
//     </Card>
//   );
// };

// const ShowData = ({ title, text, line }) => (
//   <div>
//     <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
//       {title}
//     </small>
//     <div className="text-dark">
//       <small>{text ? text : "-"}</small>
//     </div>
//     {line && <hr className="my-2" />}
//   </div>
// );