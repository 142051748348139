// React
import { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"

// Component
import { Card, Modal } from "react-bootstrap"
import {
  Alert, BackButton, DataStatus, ActionButton
} from "components"
import { DateConvert } from "utilities"

// Icon
import { IoAlertCircleOutline } from 'react-icons/io5'

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import Axios from 'axios'
import { SuratJalanApi } from 'api'

// Page Component
import { InfoDOSection, InfoSection, FormSection } from "../Section"

const TambahSuratJalan = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default
  const history = useHistory()
  const { id } = useParams()
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataArmada, setDataArmada] = useState([])
  const [dataPetugas, setDataPetugas] = useState([])
  const [formRendered, setFormRendered] = useState(0)
  const [showModalQty, setShowModalQty] = useState(false)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const mappingDataTable = data => data.map(val => {
    const qtyDo = val.qty ? parseFloat(val.qty) : 0
    const qtyTerkirim = val.qty_jalan ? parseFloat(val.qty_jalan) : 0
    const qtySisaDo = parseFloat(qtyDo - qtyTerkirim)

    return {
      id_delivery_order_detail: val.id_delivery_order_detail ?? null,
      id_delivery_order: val.id_delivery_order ?? null,
      kode_item: val.kode_barang ?? "-",
      nama_item: val.nama_barang ?? "-",
      satuan: val.nama_satuan ?? "",
      qty_do: qtyDo,
      qty_terkirim: qtyTerkirim,
      qty_sisa_do: qtySisaDo,
      qty_surat_jalan: 0,
    }
  })

  const mappingDataPetugas = data => data.map(val => {
    return {
      label: val.nama_karyawan,
      value: val.id_karyawan
    }
  })

  const mappingDataArmada = data => data.map(val => {
    return {
      label: `${val.plat_nomor} - ${val.nama_item_aset}`,
      value: val.id_item_aset
    }
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      SuratJalanApi.getSingleDO({ id_delivery_order: id }),
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada()
    ])
      .then(Axios.spread((dataDO, petugas, armada) => {
        const getData = dataDO.data.data
        const getPetugas = petugas.data.data
        const getArmada = armada.data.data
        const mapDataTable = mappingDataTable(getData.detail ?? [])
        const mapDataPetugas = mappingDataPetugas(getPetugas ?? [])
        const mapDataArmada = mappingDataArmada(getArmada ?? [])

        setDataInfo(getData)
        setDataTable(mapDataTable)
        setDataPetugas(mapDataPetugas)
        setDataArmada(mapDataArmada)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const formInitialValues = {
    tanggal: today,
    nomor: "",
    nama_petugas: "",
    nama_armada: "",
    catatan_surat_jalan: ""
    // petugas_pengiriman: "",
    // armada_pengiriman: "",
  }

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
    nama_petugas: Yup.string().required("Nama petugas pengiriman diperlukan!"),
    nama_armada: Yup.string().required("Nama armada pengiriman diperlukan!"),
    // petugas_pengiriman: Yup.string().required("Pilih petugas pengiriman"),
    // armada_pengiriman: Yup.string().required("Pilih armada pengiriman"),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    const checkQtyNull = Boolean(dataTable.find(val => val.qty_surat_jalan < 1))
    const checkQty = Boolean(dataTable.find(val => val.qty_surat_jalan > val.qty_sisa_do))
    const tableValues = dataTable.map(val => {
      return {
        id_delivery_order_detail: val.id_delivery_order_detail,
        id_delivery_order: val.id_delivery_order,
        qty: val.qty_surat_jalan
      }
    })
    const finalValues = {
      id_delivery_order: id,
      nama_petugas: values.nama_petugas,
      nama_armada: values.nama_armada,
      tgl_surat_jalan: values.tanggal,
      no_surat_jalan: values.nomor,
      catatan_surat_jalan: values.catatan_surat_jalan,
      detail: tableValues,
      // id_supir: values.petugas_pengiriman,
      // id_petugas_gudang: values.petugas_pengiriman,
      // id_kendaraan: values.armada_pengiriman,
    }

    if (checkQtyNull || checkQty) {
      setSubmitting(false)
      setShowModalQty(true)
    } else {
      SuratJalanApi.save(finalValues)
        .then(() => {
          history.push("/inventory/transaksi/surat-jalan", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil disimpan!"
            }
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal disimpan!"
          })
        })
        .finally(() => setSubmitting(false))
    }
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Surat Jalan")

    // eslint-disable-next-line
  }, [])

  const ModalQty = () => (
    <Modal
      show={showModalQty}
      onHide={() => setShowModalQty(false)}
    >
      <Modal.Header closeButton>
        <b className="text-danger">Peringatan</b>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="mb-3">
          <IoAlertCircleOutline size={75} className="mb-2 text-danger" /><br />
          <b>Qty. Surat Jalan Tidak Valid!</b><br />
          <small><b>QTY. SURAT JALAN</b> tidak boleh lebih dari <b>QTY. SISA DO</b> dan tidak boleh bernilai 0!</small>
        </p>
      </Modal.Body>
    </Modal>
  )

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {formik => (
        <Card style={{ minHeight: "80vh" }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Tambah Data Surat Jalan</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body className="pb-5">
            <Alert
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              showCloseButton={true}
              onClose={() => setAlertConfig({
                ...alertConfig,
                show: false
              })}
            />
            {fetchingStatus.loading
              ? <DataStatus loading text="Memuat . . ." />
              : fetchingStatus.success
                ? <form onSubmit={formik.handleSubmit}>
                  <InfoSection data={dataInfo} />
                  <InfoDOSection data={dataInfo} />
                  <FormSection
                    formik={formik}
                    formRendered={formRendered}
                    fetchingStatus={fetchingStatus}
                    setFormRendered={setFormRendered}
                    dataArmada={dataArmada}
                    dataPetugas={dataPetugas}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                    type='create'
                  />

                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
                : <DataStatus text="Data gagal dimuat" />
            }
          </Card.Body>
          <ModalQty />
        </Card>
      )}
    </Formik>
  )
}

export default TambahSuratJalan
