import React, {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router-dom'
import Axios from 'axios'
import {
  Alert,
  BackButton,
  DataStatus
} from 'components'
import {
  TransferBahanProduksiApi
} from 'api'
import {
  SectionDetail,
  TableHistory,
  TablePermintaan
} from './Comp'

const TransferBahanProduksiTambah = ({setNavbarTitle}) => {
  const { id } = useParams()
  console.log("id", id)
  const history = useHistory()
  const location = useLocation()
  const [dataPermintaan, setDataPermintaan] = useState({})
  const [dataHistory, setDataHistory] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    failed: false
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [dataFilter, setDataFilter] = useState({
    pagination: {
      page: location?.state?.bahan?.filter?.page ?? "1",
      dataLength: location?.state?.bahan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.bahan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.bahan?.filter?.dataCount ?? "0",
    }
  })

  const getData = () => {
    setFetchingStatus({
      loading: true,
      failed: true
    })

    Axios.all([
      TransferBahanProduksiApi.getSingle({id_permintaan_produksi: id}),
      TransferBahanProduksiApi.getTransfer({
        id_permintaan_produksi: id,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
      })
    ])
      .then(Axios.spread((permintaan, history) => {
        setDataPermintaan(permintaan?.data?.data ?? {})
        setDataHistory(history?.data?.data ?? [])

        setFetchingStatus({
          loading: false,
          failed: false
        })
      }))
      .catch(() => {
        setFetchingStatus({
          loading: false,
          failed: true
        })
      })
  }
  
  useEffect(() => {
    setNavbarTitle('Transfer Bahan Produksi')
    getData()

    return () => {}
  }, [])
  

  if (fetchingStatus.loading || fetchingStatus.failed) {
    return (
      <>
        {/* Alert */}
        <Alert 
          showCloseButton
          show={alertConfig.show}
          text={alertConfig.text}
          variant={alertConfig.variant}
          onClose={() => setAlertConfig({...alertConfig, show: false})}
        />
        
        <div className="py-2">
          <DataStatus 
            loading={fetchingStatus.loading}
            text={fetchingStatus.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
            />
        </div>
      </>
    )
  }

  return (
    <>
      {/* Title Section */}
      <div className="d-flex justify-content-between align-items-end mb-2">
        <div className="px-2">
          <b>Tambah Data Transfer Bahan Produksi</b>
        </div>
        <BackButton size="sm" onClick={() => history.push("/inventory/transaksi/transfer-bahan-produksi/permintaan-produksi", {...location?.state})} />
      </div>

      {/* Detail Section */}
      <div className="p-3 mb-3 bg-white rounded border">
        <SectionDetail 
          data={dataPermintaan}
        />
      </div>

      {/* Alert */}
      <Alert 
        showCloseButton
        show={alertConfig.show}
        text={alertConfig.text}
        variant={alertConfig.variant}
        onClose={() => setAlertConfig({...alertConfig, show: false})}
      />
      
      {/* Table Section */}
      <div className="p-3 bg-white rounded border">
        <TablePermintaan 
          data={dataPermintaan?.bahan ?? []}
          dataHistory={dataHistory}
          refresh={getData}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
        <TableHistory 
          data={dataHistory}
        />
      </div>
    </>
  )
}

export default TransferBahanProduksiTambah