import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  DataStatus,
  Tr,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  CreateButton,
  FilterButton
} from "components"
import { PageNumber, DateConvert, TableNumber } from "utilities"
import { TransferBahanProduksiApi } from "api"
import { IoEyeOutline } from "react-icons/io5"
import { ModalTransfer, ModalFilterTransfer } from "./Comp"

const TransferBahanProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [data, setData] = useState([])
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.bahan?.filter?.active,
      tgl_transfer_produksi_bahan_mulai: location?.state?.bahan?.filter?.tgl_transfer_produksi_bahan_mulai,
      tgl_transfer_produksi_bahan_selesai: location?.state?.bahan?.filter?.tgl_transfer_produksi_bahan_selesai,
      tgl_permintaan_produksi_mulai: location?.state?.bahan?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: location?.state?.bahan?.filter?.tgl_permintaan_produksi_selesai,
      id_item_buaso: location?.state?.bahan?.filter?.id_item_buaso,
      id_gudang_asal: location?.state?.bahan?.filter?.id_gudang_asal,
      id_gudang_tujuan: location?.state?.bahan?.filter?.id_gudang_tujuan
    },
    pagination: {
      page: location?.state?.bahan?.filter?.page ?? "1",
      dataLength: location?.state?.bahan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.bahan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.bahan?.filter?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: 'detail', data: {} })
  const [dropdown, setDropdown] = useState({
    bahan: [{ value: undefined, label: 'Semua' },],
    gudang: [{ value: undefined, label: 'Semua' },]
  })

  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    TransferBahanProduksiApi.getTransfer({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_transfer_produksi_bahan_mulai: dataFilter?.filter?.tgl_transfer_produksi_bahan_mulai,
      tgl_transfer_produksi_bahan_selesai: dataFilter?.filter?.tgl_transfer_produksi_bahan_selesai,
      tgl_permintaan_produksi_mulai: dataFilter?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: dataFilter?.filter?.tgl_permintaan_produksi_selesai,
      id_item_buaso: dataFilter?.filter?.id_item_buaso,
      id_gudang_asal: dataFilter?.filter?.id_gudang_asal,
      id_gudang_tujuan: dataFilter?.filter?.id_gudang_tujuan
    })
      .then(res => {
        setData(res?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          }
        });
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsLoading(false))
  }
  // Fetch dropdown filter data
  const fetchDropdown = () => {
    axios.all([
      TransferBahanProduksiApi.getDropdown({ tipe: 'bahan' }),
      TransferBahanProduksiApi.getDropdown({ tipe: 'gudang' }),
    ])
      .then(axios.spread((resBahan, resGudang) => {
        const mapItem = resBahan.data.data.map((item) => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapGudang = resGudang?.data?.data?.map(item => ({ value: item.id_gudang, label: item.nama_gudang })) ?? []

        setDropdown((prev) => ({
          bahan: prev.bahan.concat(mapItem),
          gudang: prev.gudang.concat(mapGudang),
        }))
      }))
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    fetchDropdown()

    return () => setDropdown({ bahan: [], gudang: [], })
  }, [])
  useEffect(() => {
    setNavbarTitle("Transfer Bahan Produksi")
    getData()

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_transfer_produksi_bahan_mulai,
    dataFilter?.filter?.tgl_transfer_produksi_bahan_selesai,
    dataFilter?.filter?.tgl_permintaan_produksi_mulai,
    dataFilter?.filter?.tgl_permintaan_produksi_selesai,
    dataFilter?.filter?.id_item_buaso,
    dataFilter?.filter?.id_gudang_asal,
    dataFilter?.filter?.id_gudang_tujuan,
  ])

  const Table = () => {
    const [modal, setModal] = useState({
      show: false,
      data: {}
    })

    return (
      <>
        <div className="pb-2 px-2">
          <b>List Data Transfer Bahan Produksi</b>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <Th noPadding style={{ width: 40 }}>No</Th>
              <Th noPadding style={{ width: 40 }}>Aksi</Th>
              <Th noPadding style={{ width: 150 }}>Informasi Transfer Bahan Produksi</Th>
              <Th noPadding style={{ width: 150 }}>Informasi Permintaan Produksi</Th>
              <Th noPadding style={{ minWidth: 150 }}>Item Bahan</Th>
              <Th noPadding style={{ width: 100 }}>Qty. Transfer</Th>
              <Th noPadding style={{ width: 150 }}>Gudang Asal</Th>
              <Th noPadding style={{ width: 150 }}>Gudang Tujuan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <Td>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text={<IoEyeOutline />}
                      size="sm"
                      onClick={() => setModal({ show: true, data: val })}
                    />
                  </div>
                </Td>
                <Td>
                  <span className="d-block">{val?.tgl_transfer_produksi_bahan ? DateConvert(new Date(val.tgl_transfer_produksi_bahan)).defaultDMY : "-"}</span>
                  <span>{val?.no_transfer_produksi_bahan ?? ''}</span>
                </Td>
                <Td>
                  <span className="d-block">{val?.tgl_permintaan_produksi ? DateConvert(new Date(val.tgl_permintaan_produksi)).defaultDMY : "-"}</span>
                  <span>{val?.no_permintaan_produksi ?? ''}</span>
                </Td>
                <Td>{val?.nama_item_buaso ?? "-"}</Td>
                <Td>{`${val?.qty_transfer ? parseFloat(val.qty_transfer) : '-'} ${val?.nama_satuan ?? ''}`}</Td>
                <Td>{val?.nama_gudang_asal ?? "-"}</Td>
                <Td>{val?.nama_gudang_tujuan ?? "-"}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
        <ModalTransfer
          action="DETAIL"
          data={modal?.data ?? {}}
          show={modal?.show}
          onHide={() => setModal({ ...modal, show: false })}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="sm"
            onClick={() => history.push('transfer-bahan-produksi/permintaan-produksi', {...location?.state, bahan: dataFilter})}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }}
      />

      {/* Table Section */}
      {isLoading
        ? <DataStatus loading={true} text="Memuat data..." />
        : data?.length > 0 ? <Table /> : <DataStatus text="Tidak ada data" />}

      {/* ModalFilter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterTransfer
          dropdown={dropdown}
          show={modalConfig}
          setShow={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default TransferBahanProduksiList
