import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  DataStatus,
  Tr,
  Alert,
  Pagination,
  Th,
  ThFixed,
  Td,
  THead,
  TBody,
  CreateButton,
  BackButton,
  FilterButton
} from "components"
import { PageNumber, DateConvert, TableNumber } from "utilities"
import { TransferBahanProduksiApi } from "api"
import { IoAddOutline, IoEyeOutline } from "react-icons/io5"
import {
  ModalPermintaan,
  ModalFilterPermintaanProduksi
} from './Comp'

const ItemCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false)
  const Item = ({ val, index }) => (<li key={index}>{val.nama_item} {val.qty_produksi ? `${parseFloat(val?.qty_produksi)} ${val?.kode_satuan}` : ''}</li>)
  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: "13px" }}>
        {data.map((val, index) => {
          if (isReadMoreClick) {
            return <Item val={val} key={index} />
          } else {
            return index < 2 && <Item val={val} key={index} />
          }
        })}
      </ul>

      {data.length > 2 &&
        <div
          className="text-primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setisReadMoreClick((prev) => !prev)}
        >
          {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
        </div>}
    </>
  )
}

const TransferBahanPermintaanProduksiList = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory()
  const location = useLocation()

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false)

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false)

  // data jenis
  const [data, setData] = useState([{}])
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: "filter", data: {} })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.transfer?.filter?.active,
      tgl_permintaan_produksi_awal: location?.state?.transfer?.filter?.tgl_permintaan_produksi_awal,
      tgl_permintaan_produksi_akhir: location?.state?.transfer?.filter?.tgl_permintaan_produksi_akhir,
      tgl_job_order_awal: location?.state?.transfer?.filter?.tgl_job_order_awal,
      tgl_job_order_akhir: location?.state?.transfer?.filter?.tgl_job_order_akhir,
      id_item_buaso: location?.state?.transfer?.filter?.id_item_buaso,
      id_karyawan: location?.state?.transfer?.filter?.id_karyawan,
      id_depo: location?.state?.transfer?.filter?.id_depo,
      keperluan_produksi: location?.state?.transfer?.filter?.keperluan_produksi
    },
    pagination: {
      page: location?.state?.transfer?.filter?.page ?? "1",
      dataLength: location?.state?.transfer?.filter?.dataLength ?? "10",
      totalPage: location?.state?.transfer?.filter?.totalPage ?? "1",
      dataCount: location?.state?.transfer?.filter?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    item_produksi: [{ value: undefined, label: 'Semua' },],
    petugas: [{ value: undefined, label: 'Semua' },],
    depo: [{ value: undefined, label: 'Semua' },],
    keperluan: [
      { value: undefined, label: 'Semua' },
      { value: "penjualan", label: 'Penjualan' },
      { value: "stok", label: 'Stok' },
    ],
  })
  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    TransferBahanProduksiApi.getPermintaan({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_permintaan_produksi_awal: dataFilter?.filter?.tgl_permintaan_produksi_awal,
      tgl_permintaan_produksi_akhir: dataFilter?.filter?.tgl_permintaan_produksi_akhir,
      tgl_job_order_awal: dataFilter?.filter?.tgl_job_order_awal,
      tgl_job_order_akhir: dataFilter?.filter?.tgl_job_order_akhir,
      id_item_buaso: dataFilter?.filter?.id_item_buaso,
      id_karyawan: dataFilter?.filter?.id_karyawan,
      id_depo: dataFilter?.filter?.id_depo,
      keperluan_produksi: dataFilter?.filter?.keperluan_produksi
    })
      .then((res) => {
        setData(res?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          }
        })
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsLoading(false))
  }

  // fetch dropdown filter
  const fetchDropdown = () => {
    axios.all([
      TransferBahanProduksiApi.getDropdown({ tipe: 'item_produksi' }),
      TransferBahanProduksiApi.getDropdown({ tipe: 'karyawan' }),
      TransferBahanProduksiApi.getDropdown({ tipe: 'depo' })
    ])
      .then(axios.spread((resItem, resKaryawan, resDepo) => {
        const mapItem = resItem.data.data.map((item) => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapPetugas = resKaryawan.data.data.map(item => ({ value: item.id_karyawan, label: item.nama_karyawan }))
        const mapDepo = resDepo?.data?.data?.map(item => ({ value: item.id_depo, label: item.nama_depo })) ?? []

        setDropdown((prev) => ({
          ...prev,
          item_produksi: prev.item_produksi.concat(mapItem),
          petugas: prev.petugas.concat(mapPetugas),
          depo: prev.depo.concat(mapDepo)
        }))
      }))
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    fetchDropdown()

    return () => setDropdown({ item_produksi: [], petugas: [], depo: [], keperluan: [] })
  }, [])

  useEffect(() => {
    setNavbarTitle("Transfer Bahan Produksi")
    getData()

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_permintaan_produksi_awal,
    dataFilter?.filter?.tgl_permintaan_produksi_akhir,
    dataFilter?.filter?.tgl_job_order_awal,
    dataFilter?.filter?.tgl_job_order_akhir,
    dataFilter?.filter?.id_item_buaso,
    dataFilter?.filter?.id_karyawan,
    dataFilter?.filter?.id_depo,
    dataFilter?.filter?.keperluan_produksi
  ])

  // Tabel
  const Table = () => {
    const [modal, setModal] = useState({
      show: false,
      data: {}
    })

    return (
      <>
        <div className="pb-2 px-2">
          <b>List Permintaan Produksi Siap Dibuatkan Transfer Bahan Produksi</b>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <Th noPadding style={{ width: 40 }}>No</Th>
              <Th noPadding style={{ width: 150 }}>Informasi Permintaan Produksi</Th>
              <Th noPadding style={{ width: 150 }}>Informasi Job Order</Th>
              <Th noPadding style={{ minWidth: 150 }}>Item Produksi</Th>
              <Th noPadding >Item Bahan Yang Diminta</Th>
              <ThFixed>Qty. Produksi</ThFixed>
              <ThFixed>Keperluan Produksi</ThFixed>
              <Th noPadding style={{ width: 150 }}>Petugas Produksi</Th>
              <Th noPadding style={{ width: 150 }}>Depo Produksi</Th>
              <Th noPadding style={{ width: 40 }}>Aksi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <Td>
                  <span className="d-block">{val?.tgl_permintaan_produksi ? DateConvert(new Date(val.tgl_permintaan_produksi)).defaultDMY : "-"}</span>
                  <span>{val?.no_permintaan_produksi ?? ''}</span>
                </Td>
                <Td>
                  <span className="d-block">{val?.tgl_job_order ? DateConvert(new Date(val.tgl_job_order)).defaultDMY : "-"}</span>
                  <span>{val?.no_job_order ?? ''}</span>
                </Td>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td> <ItemCollapse data={val?.bahan ?? []} /> </Td>
                <Td>{`${val?.qty_produksi ? parseFloat(val.qty_produksi) : '-'} ${val?.nama_satuan ?? ''}`}</Td>
                <Td className="text-capitalize">{val?.keperluan_produksi ?? "-"}</Td>
                <Td>{val?.nama_karyawan ?? "-"}</Td>
                <Td>{val?.nama_depo ?? "-"}</Td>
                <Td>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text={<IoEyeOutline />}
                      size="sm"
                      variant="info"
                      onClick={() => setModal({ show: true, data: val })}
                    />
                    <ActionButton
                      text={<IoAddOutline />}
                      size="sm"
                      className="ml-1"
                      onClick={() => history.push(`/inventory/transaksi/transfer-bahan-produksi/tambah/${val.id_permintaan_produksi}`, {...location?.state, transfer: dataFilter})}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
        <ModalPermintaan
          show={modal.show}
          onHide={() => setModal({ show: false, data: {} })}
          data={modal.data}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
                active={dataFilter?.filter?.active}
                onClick={() => setModalConfig({ show: true, type: 'filter' })}
              />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push("/inventory/transaksi/transfer-bahan-produksi", {...location?.state, transfer: dataFilter})}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }}
      />

      {/* Table Section */}
      {isLoading
        ? <DataStatus loading={true} text="Memuat data..." />
        : data.length ? <Table /> : <DataStatus text="Tidak ada data" />}

      {/* ModalFilter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPermintaanProduksi
          dropdown={dropdown}
          show={modalConfig}
          setShow={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default TransferBahanPermintaanProduksiList
