import React, { useState, useEffect } from 'react'
import {
    SelectSearch,
    Input,
    TextArea,
    ActionButton,
    DatePicker,
    NumberFormat
} from 'components'
import { DateConvert, RupiahConvert, DecimalConvert } from "utilities"
import { Row, Col, Modal } from 'react-bootstrap'
import { PenerimaanGKGApi } from "api"

const FormSection = ({ formik, isLoading, formRendered, setFormRendered, dataItem, setModalConfig }) => {
    const today = DateConvert(new Date()).default
    const [type, setType] = useState("")
    const [loading, setLoading] = useState(false)
    const [isNomorLoading, setIsNomorLoading] = useState(false)
    const [dropdownGKG, setDropdownGKG] = useState([])

    const {values, errors, touched, setValues, setFieldValue, handleChange, handleSubmit, isSubmitting} = formik

// REQUSET DATA SERVER 
    const getDataDropdown = () => {
        setLoading(true)

        PenerimaanGKGApi.getDropdownGKG()
        .then(gkg => {
            const mapDataGKG = gkg?.data?.data ? gkg.data.data.map(val => ({label: val?.nama_gudang, value: val?.id_gudang})) : []
            setDropdownGKG([...dropdownGKG, ...mapDataGKG])
        })
        .finally(() => setLoading(false))
    }
// GET NOMOR PENERIMA GKG
    const getNomorHandler = date => {
        setIsNomorLoading(true)
        
        PenerimaanGKGApi.getNomor({ tanggal: date })
        .then(no => {
            const nomor = no?.data?.data
            setValues({ ...values, nomor: nomor, tanggal: date })
        })
        .catch(() => {
            setValues({ ...values, nomor: "", tanggal: "" })
            window.alert("Nomor gagal dimuat")
        })
        .finally(() => setIsNomorLoading(false))
    }   
    useEffect(() => {
        getDataDropdown()
        getNomorHandler(today)
        // !isLoading && formRendered.toString() === '0' && getNomorHandler(today)
        // return () => {
        //     setFormRendered(formRendered + 1)
        //     setIsNomorLoading(false)
        // }
    }, [])
// AKUMULASI DATA
    const beratAwal = parseFloat(Boolean(values.berat_truk_masuk && values.berat_truk_masuk !== "") 
        ? values.berat_truk_masuk : 0)
    const beratAkhir = parseFloat(Boolean(values.berat_truk_keluar && values.berat_truk_keluar !== "") 
        ? values.berat_truk_keluar : 0)
    const hargaSatuan = parseInt(Boolean(values.harga_satuan && values.harga_satuan !== "")
        ? values.harga_satuan : 0)
    const akumulasiBerat = type === "beratAkhir" ?  parseFloat(beratAwal - beratAkhir).toFixed(2) : ""
    const akumulasilHarga = hargaSatuan * akumulasiBerat

    const handleAkumulasiBerat = ({ berat_truk_masuk, berat_truk_keluar, setFieldValue }) => {
        setFieldValue("berat_truk_masuk", berat_truk_masuk)
        setFieldValue("berat_truk_keluar", berat_truk_keluar)
        setFieldValue("berat_gabah_kering_giling", berat_truk_masuk - berat_truk_keluar)
    }

  return (
      <div>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <DatePicker 
                            label="Tgl. Penerimaan GKG"
                            placeholderText="Pilih tanggal"
                            dateFormat="dd/MM/yyyy"
                            selected={values.tgl_penerimaan_gkg ? new Date(values.tgl_penerimaan_gkg) : ""}
                            onChange={date => {
                                const newDate = DateConvert(date).default
                                getNomorHandler(newDate)
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <Input 
                            label='No Penerimaan GKG'
                            readOnly={true}
                            type="text"
                            name="nomor"
                            placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
                            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <SelectSearch 
                            key={values.id_item_buaso}
                            label='Nama Item'
                            name="id_item_buaso"
                            placeholder="Pilih nama item"
                            defaultValue={dataItem.map(val => ({ label: `${val?.kode_item} | ${val?.nama_item}` }))}
                            option={dataItem.map(val => ({label: `${val?.kode_item} | ${val?.nama_item}`, value: val?.id_item_buaso}))}
                            onChange={val => setFieldValue('id_item_buaso', val.value)}
                            isDisabled={true}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <NumberFormat
                            label='Berat Truk Masuk'
                            placeholder='Masukkan berat awal'
                            value={values.berat_truk_masuk}
                            defaultValue={values.berat_truk_masuk}
                            onChange={(val) => {
                                handleAkumulasiBerat({ 
                                    berat_truk_masuk: val,
                                    berat_truk_keluar: values.berat_truk_keluar,
                                    setFieldValue
                                })
                            }}
                            error={errors.berat_truk_masuk && touched.berat_truk_masuk && true}
                            errorText={errors.berat_truk_masuk && touched.berat_truk_masuk && errors.berat_truk_masuk}
                            />
                    </Col>
                    <Col md={6}>
                        <Input
                            label='Satuan Pakai'
                            type="text"
                            name='id_satuan'
                            readOnly={true} 
                            // value={values.id_satuan}
                            value={dataItem[0]?.kode_satuan.charAt(0).toUpperCase() + dataItem[0]?.kode_satuan.slice(1)}
                            onChange={handleChange}
                            loading={loading}
                        />
                        {/* <SelectSearch 
                            key={values.id_satuan}
                            label='Satuan Pakai'
                            name="id_satuan"
                            placeholder="Pilih satuan pakai"
                            defaultValue={dataItem.map(val => ({ label: val?.nama_satuan }))}
                            option={dataItem.map(val => ({label: val?.nama_satuan, value: val?.id_satuan}))}
                            onChange={val => setFieldValue('id_satuan', val.value)}
                            isDisabled={true}
                            loading={loading}
                        /> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <NumberFormat
                            label='Berat Truk Keluar'
                            placeholder='Masukkan berat akhir'
                            value={values.berat_truk_keluar}
                            defaultValue={values.berat_truk_keluar}
                            onChange={(val) => {
                                setFieldValue("berat_truk_keluar", val)
                                setType("beratAkhir")
                                // setFieldValue("berat_gabah_kering_giling", akumulasiBerat)
                                setFieldValue("berat_gabah_kering_giling", values.berat_truk_masuk - val)
                                handleAkumulasiBerat({ 
                                    berat_truk_masuk: values.berat_truk_masuk,
                                    berat_truk_keluar: val,
                                    setFieldValue
                                })
                            }}
                            error={errors.berat_truk_keluar && touched.berat_truk_keluar && true}
                            errorText={errors.berat_truk_keluar && touched.berat_truk_keluar && errors.berat_truk_keluar}
                        />
                    </Col>
                    <Col md={6}>
                        <NumberFormat
                            values={values.berat_gabah_kering_giling}
                            label='Berat Gabah Kering Giling (GKG)'
                            placeholder="Masukkan berat akhir untuk menentukan berat gkg"
                            // name='berat_gabah_kering_giling'
                            disabled={true} 
                            value={values.berat_gabah_kering_giling}
                            // onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <NumberFormat
                            label='Harga Satuan'
                            placeholder='Masukkan harga satuan'
                            prefix="Rp."
                            value={values.harga_satuan}
                            defaultValue={values.harga_satuan}
                            onChange={(value) => {
                                setFieldValue("harga_satuan", value);
                                setFieldValue("total_harga", value * values.berat_gabah_kering_giling)
                            }}
                            error={errors.harga_satuan && touched.harga_satuan && true}
                            errorText={errors.harga_satuan && touched.harga_satuan && errors.harga_satuan}
                        />
                    </Col>
                    <Col md={6}>
                        <NumberFormat
                            key={values.harga_satuan}
                            label='Total Harga'
                            prefix="Rp."
                            placeholder='Masukkan harga satuan untuk menentukan total harga'
                            disabled={true}
                            value={values.total_harga ? RupiahConvert(String(parseFloat(values.total_harga))).getWithComa : "Rp.0"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Input
                            label='Supir'
                            type='text'
                            name='supir'
                            placeholder='Masukkan nama supir'
                            value={values.supir}
                            onChange={handleChange}
                            error={errors.supir && touched.supir && true}
                            errorText={errors.supir && touched.supir && errors.supir}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            label='Plat Kendaraan'
                            type="text"
                            placeholder="Masukkan plat kendaraan"
                            name='plat_kendaraan'
                            value={values.plat_kendaraan}
                            onChange={handleChange}
                            error={errors.plat_kendaraan && touched.plat_kendaraan && true}
                            errorText={errors.plat_kendaraan && touched.plat_kendaraan && errors.plat_kendaraan}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <SelectSearch 
                            key={values.id_gudang}
                            name="id_gudang"
                            label="Gudang Penerimaan"
                            placeholder="Pilih gudang penerimaan"
                            defaultValue={dropdownGKG.find(val => val.value === values.id_gudang)}
                            option={dropdownGKG}
                            onChange={val => setFieldValue('id_gudang', val.value)}
                            loading={loading}
                            error={errors.id_gudang && touched.id_gudang && true}
                            errorText={errors.id_gudang && touched.id_gudang && errors.id_gudang}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <TextArea
                            label="Keterangan" 
                            type="text"
                            name="keterangan"
                            rows={4}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ActionButton
                    variant="light"
                    text="TUTUP"
                    onClick={() => setModalConfig({ show: false })}
                />
                <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    loading={isSubmitting}
                />
              </Modal.Footer>
          </form>
      </div>
  )
}

export default FormSection