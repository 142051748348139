import React from "react";
import { Table, Th, Td, Pagination } from "components";
import { DecimalConvert, RupiahConvert } from "utilities";
import { Dropdown, Spinner } from "react-bootstrap";
import { InputSearch, FilterButton } from "components";
import FilterButtonRekapitulasi from "./FilterButtonRekapitulasi";

const TableRekapitulasiNilaiPersediaan = ({ 
  data, 
  paginationConfig, 
  setPaginationConfig, 
  setModalConfig, 
  searchConfig, 
  setSearchConfig, 
  setAlertConfig, 
  dataFilter, 
  exportData,
  loadingExport,
  isActive
}) => {
  return (
    <div className="p-3 bg-white border border-top-0">
      <div className="d-flex">
        <InputSearch
          onChange={(e) => {
            setSearchConfig({ ...searchConfig, key: e.target.value });
            setAlertConfig({
              show: e.target.value === "" ? false : true,
              text: e.target.value,
              variant: "primary",
            });
          }}
          onSubmit={(e) => e.preventDefault()}
        />
        <FilterButtonRekapitulasi
          active={dataFilter?.active}
          isActive={isActive}
          onClick={() => setModalConfig({ show: true, type: "filter" })}
        />

        <Dropdown>
          <Dropdown.Toggle
            // disabled={loadingExport}
            className="ml-2 btn btn-warning text-white">
            {loadingExport 
              ? ( <Spinner animation="border" variant="white" size="sm" /> ) 
              : ( "Export Data" )
            }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={!dataFilter?.active}
              onClick={() => exportData("excel")}>
                Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!dataFilter?.active}
              onClick={() => exportData("pdf")}>
                PDF (.pdf)
              </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="font-weight-bold mb-2 mt-4">List Data Nilai Persediaan</div>
      <Table>
        <thead>
          <tr>
            <Th rowSpan="2">No</Th>
            <Th rowSpan="2">Kode Item</Th>
            <Th rowSpan="2">Kelompok Item</Th>
            <Th rowSpan="2">Jenis Item</Th>
            <Th rowSpan="2">Nama Item</Th>
            <Th rowSpan="2">Gudang</Th>
            <Th colSpan="4">Volume Total</Th>
            <Th rowSpan="2">Harga Satuan</Th>
            <Th rowSpan="2">Nilai Persediaan</Th>
          </tr>
          <tr>
            <Th>Saldo Awal</Th>
            <Th>Masuk</Th>
            <Th>Keluar</Th>
            <Th>Saldo Akhir</Th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              const saldoAkhir = parseFloat(val.saldo_awal) + parseFloat(val.masuk) - parseFloat(val.keluar);
              const tableStyle = "align-middle text-nowrap"
              const tableUppercase = "align-middle text-uppercase text-right text-nowrap"
              return (
                <tr key={index}>
                  <Td className={`${tableStyle} ${"text-center"}`}>{index + 1}</Td>
                  <Td className={tableStyle}>{val?.kode_item ?? "-"}</Td>
                  <Td className={tableStyle}>{val?.nama_kelompok ?? "-"}</Td>
                  <Td className={`${tableStyle} ${"text-capitalize"}`}>{val?.nama_jenis ?? "-"}</Td>
                  <Td className={tableStyle}>{val?.nama_item ?? "-"}</Td>
                  <Td className="align-middle">{val?.nama_gudang ?? "-"}</Td>
                  <Td className={tableUppercase}>{DecimalConvert(parseFloat(val?.saldo_awal ?? 0)).getWithComa}</Td>
                  <Td className={tableUppercase}>{DecimalConvert(parseFloat(val?.masuk ?? 0)).getWithComa}</Td>
                  <Td className={tableUppercase}>{DecimalConvert(parseFloat(val?.keluar ?? 0)).getWithComa}</Td>
                  {/* <Td className={tableUppercase}>{DecimalConvert(parseFloat(saldoAkhir ?? 0)).getWithComa}</Td> */}
                  <Td className={tableUppercase}>{DecimalConvert(parseFloat(val?.saldo_akhir ?? 0)).getWithComa}</Td>
                  <Td className={`${tableStyle} ${"text-right"}`}>{RupiahConvert(parseFloat(val?.harga_satuan ?? 0).toString()).getWithComa}</Td>
                  <Td className={`${tableStyle} ${"text-right"}`}>{RupiahConvert(parseFloat(val?.nilai_persediaan ?? 0).toString()).getWithComa}</Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12} className="align-middle text-nowrap text-center">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="mt-2">
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.currentPage * paginationConfig.dataLength -
            paginationConfig.dataLength + 1
          }
          dataPage={paginationConfig.dataCount < paginationConfig.dataLength
            ? paginationConfig.dataCount
            : paginationConfig.currentPage * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.currentPage}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              currentPage: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              currentPage: 1,
              dataLength: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export default TableRekapitulasiNilaiPersediaan;


  // {/* style={{ minWidth: "90px" }} */}
  // {/* style={{ minWidth: "200px" }} */}
  // {/* style={{ minWidth: "150px" }} */}
  // {/* style={{ minWidth: "300px" }} */}
  // {/* style={{ minWidth: "150px" }} */}
  // {/* style={{ minWidth: "150px" }} */}
  // {/* style={{ minWidth: "150px" }} */}

  // {/* style={{ minWidth: "90px" }}
  // style={{ minWidth: "90px" }}
  // style={{ minWidth: "90px" }}
  // style={{ minWidth: "90px" }} */}
