import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'

export const ModalFilterTransfer = ({ dropdown, show, setShow, data, setData }) => {
  const formInitialValues = {
    tgl_transfer_produksi_bahan_mulai: data?.filter?.tgl_transfer_produksi_bahan_mulai,
    tgl_transfer_produksi_bahan_selesai: data?.filter?.tgl_transfer_produksi_bahan_selesai,
    tgl_permintaan_produksi_mulai: data?.filter?.tgl_permintaan_produksi_mulai,
    tgl_permintaan_produksi_selesai: data?.filter?.tgl_permintaan_produksi_selesai,
    id_item_buaso: data?.filter?.id_item_buaso,
    id_gudang_asal: data?.filter?.id_gudang_asal,
    id_gudang_tujuan: data?.filter?.id_gudang_tujuan
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangeTransferProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_transfer_produksi_bahan_mulai: startDate,
        tgl_transfer_produksi_bahan_selesai: endDate,
    })
  }
  const onChangePermintaanProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_permintaan_produksi_mulai: startDate,
        tgl_permintaan_produksi_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_transfer_produksi_bahan_mulai: undefined,
        tgl_transfer_produksi_bahan_selesai: undefined,
        tgl_permintaan_produksi_mulai: undefined,
        tgl_permintaan_produksi_selesai: undefined,
        id_item_buaso: undefined,
        id_gudang_asal: undefined,
        id_gudang_tujuan: undefined
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

        <DatePicker
          selectsRange
          label="Tgl. Transfer Produksi"
          placeholderText="Pilih tanggal transfer produksi"
          startDate={values.tgl_transfer_produksi_bahan_mulai ? new Date(values.tgl_transfer_produksi_bahan_mulai) : ''}
          endDate={values.tgl_transfer_produksi_bahan_selesai ? new Date(values.tgl_transfer_produksi_bahan_selesai) : ''}
          onChange={(dates) => onChangeTransferProduksi(dates, values, setValues)}
          monthsShown={2}
        />
        <DatePicker
          selectsRange
          label="Tgl. Permintaan Produksi"
          placeholderText="Pilih tanggal permintaan produksi"
          startDate={values.tgl_permintaan_produksi_mulai ? new Date(values.tgl_permintaan_produksi_mulai) : ''}
          endDate={values.tgl_permintaan_produksi_selesai ? new Date(values.tgl_permintaan_produksi_selesai) : ''}
          onChange={(dates) => onChangePermintaanProduksi(dates, values, setValues)}
          monthsShown={2}
        />
        <SelectSearch
          key={values.id_item_buaso}
          label="Item Bahan"
          placeholder="Pilih item bahan"
          defaultValue={dropdown?.bahan?.find(item => item.value === values.id_item_buaso)}
          option={dropdown.bahan}
          onChange={val => setFieldValue('id_item_buaso', val.value)}
        />
        <SelectSearch
          key={values.id_gudang_asal}
          label="Gudang Asal"
          placeholder="Pilih gudang asal"
          defaultValue={dropdown?.gudang?.find(item => item.value === values.id_gudang_asal)}
          option={dropdown.gudang}
          onChange={val => setFieldValue('id_gudang_asal', val.value)}
        />
        <SelectSearch
          key={values.id_gudang_tujuan}
          label="Gudang Tujuan"
          placeholder="Pilih gudang tujuan"
          defaultValue={dropdown?.gudang?.find(item => item.value === values.id_gudang_tujuan)}
          option={dropdown.gudang}
          onChange={val => setFieldValue('id_gudang_tujuan', val.value)}
        />
      </FilterModal>
    )}
  </Formik>
  )
}
