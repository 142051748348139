import { Modal, Row, Col } from "react-bootstrap"
import { ReadModal } from "components"
import { DateConvert } from 'utilities'

const ModalDetailPurchaseOrder = ({ modalConfig, setModalConfig }) => {
  const { data } = modalConfig ?? {}

  const ShowData = ({ title, text, line }) => (
    <div>
      <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
        {title}
      </small>
      <div className="text-dark">
        <small>{text ? text : "-"}</small>
      </div>
      {line && <hr className="my-2" />}
    </div>
  )

  return (
    <ReadModal
      size="lg"
      show={Boolean(modalConfig.show && modalConfig.type === 'detail')}
      onHide={() => setModalConfig({ show: false, type: 'detail', data: {} })}
      title="Purchase Order">
      <Modal.Body>
        <>
          <Row>
            <Col lg="6">
              <ShowData
                title="Tgl. Purchase Order"
                line={true}
                text={
                  data?.tgl_purchase_order
                    ? DateConvert(new Date(data?.tgl_purchase_order)).detail
                    : "-"
                }
              />
            </Col>
            <Col lg="6">
              <ShowData
                title="No. Purchase Order"
                line={true}
                text={data?.no_purchase_order ? data?.no_purchase_order : "-"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowData
                title="Vendor"
                line={true}
                text={data?.nama_vendor ? data?.nama_vendor : "-"}
              />
            </Col>
          </Row>
          <Row>
            <Col >
              <ShowData title="Item Barang" line={true} text={data?.nama_item} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowData
                title="Qty. Purchase Order"
                line={true}
                text={data.qty_order ? `${parseFloat(data.qty_order)} ${data.nama_satuan_beli ?? ""}` : "-"}
              />
            </Col><Col>
              <ShowData
                title="Qty. Diterima"
                line={true}
                text={data.qty_diterima ? `${parseFloat(data.qty_diterima)} ${data.nama_satuan_pakai ?? ""}` : "-"}
              />
            </Col>
            <Col>
              <ShowData
                title="Qty. Sisa"
                line={true}
                text={data.qty_sisa ? `${parseFloat(data.qty_sisa)} ${data.nama_satuan_pakai ?? ""}` : "-"}
              />
            </Col>
          </Row>
        </>
      </Modal.Body>
    </ReadModal>
  )// 
}

export default ModalDetailPurchaseOrder