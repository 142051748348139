// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// API
import Axios from "axios";
import { DeliveryOrderApi } from "../../../../../api";

// Component
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
  DataStatus,
  FilterButton
} from "../../../../../components";
import { DateConvert, PageNumber, TableNumber } from "../../../../../utilities";
import { ModalFilter } from "./Section";

// Icon
import { IoAddOutline } from "react-icons/io5";

const ListSo = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.so?.filter?.active,
      tgl_sales_order_mulai: location?.state?.so?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: location?.state?.so?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: location?.state?.so?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: location?.state?.so?.filter?.tgl_batas_waktu_selesai,
      customer: location?.state?.so?.filter?.customer,
      gudang: location?.state?.so?.filter?.gudang,
    },
    pagination: {
      page: location?.state?.so?.filter?.page ?? "1",
      dataLength: location?.state?.so?.filter?.dataLength ?? "10",
      totalPage: location?.state?.so?.filter?.totalPage ?? "1",
      dataCount: location?.state?.so?.filter?.dataCount ?? "0",
    }
  })
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([DeliveryOrderApi.getSales({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
      customer: dataFilter?.filter?.customer,
      gudang: dataFilter?.filter?.gudang,
    })])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle("Delivery Order");

    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.gudang,
  ]);

  const Table = () => {
    return (
      <>
        <span style={{fontSize:"14px"}}><b>List Sales Order Yang Siap Dijadikan Delivery Order</b></span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th>Customer</Th>
              <Th>Gudang</Th>
              <Th>Item Barang</Th>
              <ThFixed>Batas Waktu Pengiriman</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_sales_order ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>
                  <ul className="pl-3">
                    {val.barang_jadi
                      ? val.barang_jadi.map((item, index) => (
                          <li key={index}>{item.nama_barang}</li>
                        ))
                      : ""}
                  </ul>
                </Td>
                <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text={<IoAddOutline />}
                      size="sm"
                      variant="success"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/delivery-order/tambah-delivery-order/${val.id_sales_order}/${val.id_gudang}`,
                          {
                            data: {
                              nama_gudang: val.nama_gudang,
                              alamat_pengiriman: val.alamat_pengiriman,
                            },
                            ...location?.state, so: dataFilter
                          }
                        )
                      }
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setDataFilter({
                    ...dataFilter,
                    pagination: {
                      ...dataFilter.pagination,
                      page: "1",
                      dataLength: "10",
                      totalPage: "1",
                      dataCount: "0",
                    }
                  });
                }, 1000);
                // if (searchKey !== "") {
                //   setShowAlert(true)
                // }
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.push("/inventory/transaksi/delivery-order", { ...location?.state, so: dataFilter });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      { showModalFilter && <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      }
    </CRUDLayout>
  );
};

export default ListSo;
