import Services from "../../../services";

class PenerimaanSuratJalanApi {
    getPage(params) {
        return Services.get('penerimaan_surat_jalan/page', { params })
    }

    // UNTUK GET SINGLE PENERIMAAN SJ, MENGGUNAKAN API GET DETAIL SJ
    getSingle(params) {
        return Services.get('surat_jalan/single', { params }) 
    }

    // UNTUK GET NOMOR PENERIMAAN / PEMBATALAN SURAT JALAN
    getNomor(type, params) {
        const endpoint = type === 'pembatalan' ? 'no_baru_pembatalan' : 'no_baru_penerimaan'
        return Services.get(`penerimaan_surat_jalan/${endpoint}`, { params })
    }

    // UNTUK POST PENERIMAAN / PEMBATALAN SURAT JALAN
    save(type, data) {
        const endpoint = type === 'pembatalan' ? 'pembatalan' : 'penerimaan'
        return Services.post(`penerimaan_surat_jalan/${endpoint}`, data)
    }

    saveData(data) {
        return Services.post(`penerimaan_surat_jalan/penerimaan`, data)
    }

    list_sj(params) {
        return Services.get('penerimaan_surat_jalan/list_sj', { params })
    }

    getDropdownCustomer() {
        return Services.get("/dropdown/customer")
    }

    getDropdownSales() {
        return Services.get("/dropdown/sales")
    }

    getDropdownGudang() {
        return Services.get("/dropdown/gudang")
    }

    getDropdownPetugas() {
        return Services.get("/dropdown/supir")
    }

    getDropdownArmada() {
        return Services.get("/dropdown/armada")
    }
}

export default new PenerimaanSuratJalanApi();
