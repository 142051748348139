import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  UpdateButton,
  ApprovalButton,
  FilterButton,
  ActionButton,
} from "components"
import { TableNumber, DateConvert } from "utilities"
import { PenerimaanBarangApi } from "api"
import {
  ModalDetailPenerimaanBarang,
  ModalFilterPenerimaanBarang
} from "./Section"
import { ButtonGroup } from "react-bootstrap"
import { IoEyeOutline } from "react-icons/io5";
import { AiOutlineEdit } from 'react-icons/ai'

const PenerimaanBarang = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const TITLE = "Penerimaan Barang"
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`
  }
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER)) : {}
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION)) : {}

  let registerAlertConfig = location?.state?.registerAlertConfig
  const [isLoading, setIsLoading] = useState(false)
  const [searchConfig, setSearchConfig] = useState({ status: false, key: "" })
  const [data, setData] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {}
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_penerimaan_barang_mulai: filter?.tgl_penerimaan_barang_mulai,
      tgl_penerimaan_barang_selesai: filter?.tgl_penerimaan_barang_selesai,
      tgl_purchase_order_mulai: filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: filter?.tgl_purchase_order_selesai,
      vendor: filter?.vendor,
      item_barang: filter?.item_barang,
      status_approval: filter?.status_approval,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    }

  })
  const [dropdown, setDropdown] = useState({
    item_barang: [{ value: undefined, label: 'Semua' },],
    vendor: [{ value: undefined, label: 'Semua' },],
    approval: [
      { value: undefined, label: 'Semua' },
      { value: "PEN", label: 'PENDING' },
      { value: "VER", label: 'VERIFIED' },
      { value: "APP", label: 'APPROVED' },
      { value: "REV", label: 'REVISI' },
      { value: "REJ", label: 'REJECT' },
    ],
  })

  const getData = () => {
    setIsLoading(true)

    PenerimaanBarangApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter
    })
      .then((res) => {
        setData(res.data.data)
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data.data_count,
            totalPage: res?.data?.total_page
          }
        })
      })
      .catch(() => {
        setAlertConfig({ variant: "danger", text: "Data gagal dimuat!" })
        setShowAlert(true)
      })
      .finally(() => setIsLoading(false))
  }

  // fetch dropdown filter
  const fetchDropdown = () => {
    axios.all([
      PenerimaanBarangApi.dropdownItemBarang(),
      PenerimaanBarangApi.dropdownVendor(),
    ])
      .then(axios.spread((resItem, resVendor) => {
        const mapItem = resItem.data.data.map((item) => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapVendor = resVendor.data.data.map(item => ({ value: item.id_vendor, label: item.nama_vendor }))

        setDropdown((prev) => ({
          ...prev,
          item_barang: prev.item_barang.concat(mapItem),
          vendor: prev.vendor.concat(mapVendor),
        }))
      }))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
    setShowAlert(key ? true : false)
  }

  useEffect(() => {
    fetchDropdown()

    return () => setDropdown({ item_barang: [], vendor: [] })
  }, [])

  useEffect(() => {
    setNavbarTitle(TITLE)
    getData()
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig)
      setShowAlert(true)
    }

    return () => { setIsLoading(false) }
  }, [setNavbarTitle, searchConfig.key, dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, dataFilter?.filter])

  const Table = () => {
    // status approval yang menampilkan tombol edit
    const APPROVAL_EDITABLE = ['REV', 'PEN']
    return (
      <>
        <div className="font-weight-bold" style={{ fontSize: "14px" }}>List Data Penerimaan Barang</div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penerimaan Barang</ThFixed>
              <ThFixed>Informasi Purchase Order</ThFixed>
              <Th>Vendor</Th>
              <Th>Item Barang</Th>
              <Th width={100}>Qty</Th>
              {/* <ThFixed>Status Approval</ThFixed> */}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={val.id_penerimaan_barang}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      text={<IoEyeOutline />}
                      tooltip={true}
                      tooltipText="Lihat Detail Data"
                      onClick={() => {
                        setModalConfig({
                          show: true,
                          type: 'detail',
                          data: {
                            id_purchase: val.id_purchase_order_detail,
                            id_penerimaan: val.id_penerimaan_barang,
                          }
                        })
                      }}
                    />
                    {/* {!!(APPROVAL_EDITABLE.includes(val.status_approval)) && ( */}
                      <ActionButton
                        variant="success"
                        size="sm"
                        text={<AiOutlineEdit />}
                        tooltip={true}
                        tooltipText="Update Data"
                        onClick={() => {
                          //  STORE STATE TO SESSION
                          sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                          sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

                          history.push(`/inventory/transaksi/penerimaan-barang/ubah/${val.id_penerimaan_barang}`)
                        }}
                      />
                    {/* )} */}
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div>{DateConvert(new Date(val.tgl_penerimaan_barang)).defaultDMY}</div>
                  <div>{val.no_penerimaan_barang}</div>
                </Td>
                <Td>
                  <div>{DateConvert(new Date(val.tgl_purchase_order)).defaultDMY}</div>
                  <div>{val.no_purchase_order}</div>
                </Td>
                <Td>{val.nama_vendor}</Td>
                <Td> {val.nama_item} </Td>
                <Td>
                  <div className="text-right">{val.qty_beli ? `${parseFloat(val.qty_beli)} ${val.nama_satuan_beli}` : "-"}</div>
                </Td>
                {/* <Td><ApprovalButton status={val.status_approval ?? "PEN"} /></Td> */}
              </Tr>
            ))} </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => {
            //  STORE STATE TO SESSION
            sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
            sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

            history.push(`/inventory/transaksi/penerimaan-barang/list`)
          }} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false)
          // CLEAR HISTORY LOCATION
          history.replace("/inventory/transaksi/penerimaan-barang", {
            registerAlertConfig: undefined,
          })
        }}
      />

      {/* Table Section */}
      {isLoading === true
        ? (<DataStatus loading={true} text="Memuat data..." />)
        : data?.length > 0
          ? <Table />
          : (<DataStatus text="Tidak ada data" />)
      }

      {/* Modal Filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPenerimaanBarang
          SESSION_KEY={SESSION_KEY}
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Modal Detail */}
      {modalConfig.show && modalConfig.type === 'detail' && (
        <ModalDetailPenerimaanBarang
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  )
}

export default PenerimaanBarang
