import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  TdFixed,
  DataStatus,
  Tr,
  BackButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
} from "components";
import { PageNumber, DateConvert } from "utilities";
import { TransferBahanJobMixApi } from "api";

const ListPermintaanBahanJobMix = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);

  // data jenis
  const [data, setData] = useState([{}]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    TransferBahanJobMixApi.getPageListPermintaan({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res.data.data_count,
          totalPage: res.data.total_page,
        });
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("List Permintaan Job Mix");
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Permintaan Job Mix</ThFixed>
              <ThFixed>No. Permintaan Job Mix</ThFixed>
              <ThFixed>No. Job Mix</ThFixed>
              <Th>Item Permintaan</Th>
              <ThFixed>Qty. Permintaan Job Mix</ThFixed>
              <Th>Pabrik</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(paginationConfig.page, paginationConfig.dataLength, index)}
                </TdFixed>
                <Td>{val.tgl_jobmix_permintaan ? DateConvert(new Date(val.tgl_jobmix_permintaan)).defaultDMY : "-"}</Td>
                <TdFixed>{val?.no_jobmix_permintaan ?? "-"}</TdFixed>
                <TdFixed>{val?.no_jobmix ?? "-"}</TdFixed>
                <Td>{`${val?.kode_item ? `(${val.kode_item}) ` : ''}${val.nama_item ?? '-'}`}</Td>
                <Td>{`${val?.qty_permintaan?.replace(".", ",") ?? 0} ${val?.nama_satuan ?? ""}`}</Td>
                <Td>{val?.nama_pabrik ?? "-"}</Td>
                <TdFixed>
                  <div className="d-flex flex-row">
                    <ActionButton
                      text="Transfer"
                      size="sm"
                      onClick={() => history.push(`/inventory/transaksi/transfer-bahan-job-mix/tambah/${val.id_jobmix_permintaan}`, {
                        id_item_buaso: val.id_item_permintaan
                      })}
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="sm"
            onClick={() => history.goBack()}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {/* Table Section */}
      {isLoading
        ? <DataStatus loading={true} text="Memuat data..." />
        : data.length ? <Table /> : <DataStatus text="Tidak ada data" />}
    </CRUDLayout>
  );
};

export default ListPermintaanBahanJobMix;
