// React
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ButtonGroup } from "react-bootstrap"

// Component
import { Row, Col } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, InputSearch, 
  Pagination, Table, Th, Td, ThFixed, TdFixed,
  ReadButton, CreateButton, FilterButton, Tr, 
  TBody, THead, ActionButton, DatePicker, Input
} from "components"
import { DateConvert, TableNumber } from "utilities"
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Modal } from "react-bootstrap"
import { ModalFilter } from './Section'

// API
import { PenerimaanSuratJalanApi } from "api"

const PenerimaanSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([])
  const [processedData, setProcessedData] = useState({})
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.sj?.filter?.active,
      tgl_surat_jalan_mulai: location?.state?.sj?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: location?.state?.sj?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: location?.state?.sj?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: location?.state?.sj?.filter?.tgl_delivery_order_selesai,
      batas_waktu_mulai: location?.state?.sj?.filter?.batas_waktu_mulai,
      batas_waktu_selesai: location?.state?.sj?.filter?.batas_waktu_selesai,
      customer: location?.state?.sj?.filter?.customer,
      sales: location?.state?.sj?.filter?.sales,
      status_penerimaan: location?.state?.sj?.filter?.status_penerimaan,
    },
    pagination: {
      page: location?.state?.sj?.filter?.page ?? "1",
      dataLength: location?.state?.sj?.filter?.dataLength ?? "10",
      totalPage: location?.state?.sj?.filter?.totalPage ?? "1",
      dataCount: location?.state?.sj?.filter?.dataCount ?? "0",
    }
  })
  const [modalConfig, setModalConfig] = useState({
    type: '',
    show: false
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PenerimaanSuratJalanApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_surat_jalan_mulai: dataFilter?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: dataFilter?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: dataFilter?.filter?.tgl_delivery_order_selesai,
      batas_waktu_mulai: dataFilter?.filter?.batas_waktu_mulai,
      batas_waktu_selesai: dataFilter?.filter?.batas_waktu_selesai,
      customer: dataFilter?.filter?.customer,
      sales: dataFilter?.filter?.sales,
      status_penerimaan: dataFilter?.filter?.status_penerimaan,
    })
      .then(data => {
        setDataPenerimaanSuratJalan(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }
  useEffect(() => {
    setNavbarTitle("Penerimaan Surat Jalan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_surat_jalan_mulai,
    dataFilter?.filter?.tgl_surat_jalan_selesai,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.batas_waktu_mulai,
    dataFilter?.filter?.batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.sales,
    dataFilter?.filter?.status_penerimaan,
  ])

  const PageContent = () => {
    const getStatusPenerimaan = (val) => {
      switch (val.status_surat_jalan) {
        case 'terima':
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: '#00cc00' }}> SUDAH DITERIMA</span >
              {val?.no_penerimaan_surat_jalan ?? '-'}
            </div>
          );

        case 'batal':
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: '#ff0000' }}> BATAL DITERIMA</span >
              {val?.no_penerimaan_surat_jalan ?? '-'}
            </div>
          );

        default:
          return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span style={{ color: '#0408ff' }}> BELUM DITERIMA</span >
            </div>
          );
      }
    }
    const DataTable = () => {
      return (
        <>
          <span style={{fontSize:"14px"}}><b>List Data Penerimaan Surat Jalan</b></span>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Informasi Surat Jalan</ThFixed>
                <ThFixed>Informasi Delivery Order</ThFixed>
                <Th>Customer</Th>
                <Th>Sales</Th>
                <Th width={100}>Tgl. Batas Waktu</Th>
                <ThFixed>Status Penerimaan</ThFixed>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {dataPenerimaanSuratJalan.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_penerimaan_surat_jalan ? DateConvert(new Date(val.tgl_penerimaan_surat_jalan)).defaultDMY: "-"}</div>
                    <div className="text-left">{val.no_penerimaan_surat_jalan ?? "-"}</div>
                  </TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY: "-"}</div>
                    <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                  </TdFixed>
                  <Td>{val.nama_customer ?? "-"}</Td>
                  <Td>{val.nama_sales ?? "-"}</Td>
                  <Td>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</Td>
                  <TdFixed>{getStatusPenerimaan(val) ?? "-"}</TdFixed>
                  <TdFixed className="align-middle">
                    { val.status_surat_jalan === 'pending' 
                      ? <ButtonGroup size="sm" className="btn-block">
                          <ActionButton
                            variant="success"
                            text="Terima"
                            onClick={() => {
                              setProcessedData(val)
                              setModalConfig({
                                show: true,
                                type: "penerimaan"
                              })
                            }}
                          />
                          <ActionButton
                            variant="danger"
                            text="Batal"
                            onClick={() => {
                              setProcessedData(val)
                              setModalConfig({
                                show: true,
                                type: "pembatalan"
                              })
                            }}
                          />
                        </ButtonGroup>
                      : <ActionButton
                          size="sm"
                          text="Detail"
                          className="col"
                          onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/detail/'+ val.id_surat_jalan, { ...location?.state, sj: dataFilter})}
                        />
                    }
                  </TdFixed>
                </tr>
              ))}
            </TBody>
          </Table>
        </>
      )
    }

    if (!dataPenerimaanSuratJalan || dataPenerimaanSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }
  const PageModal = () => {
    const today = DateConvert(new Date()).default
    const [dataInfoModal, setDataInfoModal] = useState({})
    const [dataTableModal, setDataTableModal] = useState([])
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: false,
      success: true
    })

    const mappingDataInfo = data => {
      return {
        tgl_surat_jalan: data.tgl_surat_jalan ? DateConvert(new Date(data.tgl_surat_jalan)).detail : '-',
        no_surat_jalan: data.no_surat_jalan ?? '-',
        no_delivery_order: data.no_delivery_order ?? '-',
        no_sales_order: data.no_sales_order ?? '-',
        customer: data.nama_customer ?? '-',
        sales: data.nama_sales ?? '-',
        petugas_pengiriman: data.nama_sopir ?? '-',
        armada_pengiriman: `${data?.plat_nomor ?? ''} - ${data?.nama_item_aset ?? ''}`,
        tgl_penerimaan_surat_jalan: data.tgl_penerimaan_surat_jalan ? DateConvert(new Date(data.tgl_penerimaan_surat_jalan)).detail : '-',
        no_penerimaan_surat_jalan: data.no_penerimaan_surat_jalan ?? '-',
        batas_waktu: data.batas_waktu ?? '-',
        gudang: data.nama_gudang ?? '-'
      }
    }
    const mappingDataTable = data => {
      return data.map(val => {
        return {
          kode_barang: val.kode_barang ?? '-',
          nama_barang: val.nama_barang ?? '-',
          gudang: val.nama_gudang ?? '-',
          qty: val.qty_sj ? parseInt(val.qty_sj).toFixed(2) : 0,
          satuan: val.nama_satuan ?? '-'
        }
      })
    }

    const getInitialModalData = () => {
      setModalFetchingStatus({
        loading: true,
        success: false
      })

      PenerimaanSuratJalanApi.getSingle({ id_surat_jalan: processedData.id_surat_jalan })
        .then(({ data }) => {
          const mapDataInfo = mappingDataInfo(data.data ?? {})
          const mapDataTable = mappingDataTable(data.data.detail ?? [])

          setDataInfoModal(mapDataInfo)
          setDataTableModal(mapDataTable)

          setModalFetchingStatus({
            loading: false,
            success: true
          })
        })
        .catch(() => {
          setModalFetchingStatus({
            loading: false,
            success: false
          })
        })
    }
    useEffect(() => {
      getInitialModalData()

      return () => {
        // CLEANUP STATE
        setDataInfoModal({})
        setDataTableModal([])
      }
    }, [])

    const ModalContent = () => {
      const formInitialValues = {
        tgl: today,
        no: ''
      }
      const formValidationSchema = Yup.object().shape({
        tgl: Yup.string()
          .required('Pilih tanggal penerimaan surat jalan'),
        no: Yup.string()
          .required('Pilih tanggal untuk menentukan nomor')
      })
      const formSubmitHandler = values => {
        const finalValues = {
          id_surat_jalan: processedData.id_surat_jalan,
          tgl_penerimaan_surat_jalan: values.tgl
        }
        // SEND KE SERVER DATA PENERIMAAN / PEMBATALAN SURAT JALAN
        PenerimaanSuratJalanApi.save(modalConfig.type, finalValues)
          .then(() => {
            setAlertConfig({
              show: true,
              variant: "primary",
              text: "Data berhasil disimpan!"
            })
          })
          .catch(() => {
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: "Data gagal disimpan!"
            })
          })
          .finally(() => {
            setProcessedData({})
            setModalConfig({
              show: false,
              type: ""
            })
            getInitialData()
          })

      }

      const InfoSection = () => {
        const InfoItem = ({ title, value }) => (
          <tr>
            <td className="align-top" width={150}>{title}</td>
            <td className="align-top pr-2">:</td>
            <td className="align-top">{value}</td>
          </tr>
        )
        return (
          <Row>
            <Col lg>
              <table style={{ fontSize: 14 }}>
                <tbody>
                  <InfoItem title="Tgl. Surat Jalan" value={dataInfoModal.tgl_surat_jalan} />
                  <InfoItem title="No. Surat Jalan" value={dataInfoModal.no_surat_jalan} />
                  <InfoItem title="No. Delivery Order" value={dataInfoModal.no_delivery_order} />
                  <InfoItem title="No. Sales Order" value={dataInfoModal.no_sales_order} />
                  <InfoItem title="Tgl.Batas Waktu" value={DateConvert(new Date(dataInfoModal.batas_waktu)).detail} />
                </tbody>
              </table>
            </Col>
            <Col lg>
              <table style={{ fontSize: 14 }}>
                <tbody>
                  <InfoItem title="Customer" value={dataInfoModal.customer} />
                  <InfoItem title="Sales" value={dataInfoModal.sales} />
                  <InfoItem title="Gudang" value={dataInfoModal.gudang} />
                  <InfoItem title="Petugas Pengiriman" value={dataInfoModal.petugas_pengiriman} />
                  <InfoItem title="Armada Pengiriman" value={dataInfoModal.armada_pengiriman} />
                </tbody>
              </table>
            </Col>
          </Row>
        )
      }
      const InfoSectionDetail = () => {
        const InfoItem = ({ label, value }) => (
          <div>
            <small style={{ fontSize: 12 }}>{label}</small>
            <div style={{ fontSize: 14 }}>{value}</div>
          </div>
        )
        return (
          <Row>
            <Col lg>
              <InfoItem label="Tgl. Penerimaan Surat Jalan" value={dataInfoModal.tgl_penerimaan_surat_jalan} />
            </Col>
            <Col lg>
              <InfoItem label="No. Penerimaan Surat Jalan" value={dataInfoModal.no_penerimaan_surat_jalan} />
            </Col>
          </Row>
        )
      }

      const FormSection = ({ formik }) => {
        const [isNomorLoading, setIsNomorLoading] = useState(true)
        const { values, errors, touched, setValues } = formik

        const getNomorHandler = tanggal => {
          setIsNomorLoading(true)
          // GET NOMOR PENERIMAAN / PEMBATALAN
          PenerimaanSuratJalanApi.getNomor(modalConfig.type, { tanggal })
            .then(val => {
              setValues({
                tgl: tanggal,
                no: val?.data?.data ?? '-'
              })
            })
            .catch(() => {
              window.alert('Gagal memuat nomor')
              setValues({
                tgl: "",
                no: ""
              })
            })
            .finally(() => setIsNomorLoading(false))
        }

        useEffect(() => {
          getNomorHandler(today)
        }, [])

        return (
          <Row>
            <Col md>
              <DatePicker
                label={`Tgl. ${modalConfig.type.toLowerCase() === 'penerimaan' ? 'Penerimaan' : 'Pembatalan'} Surat Jalan`}
                selected={values.tgl ? new Date(values.tgl) : ""}
                placeholderText="Pilih tanggal"
                onChange={date => getNomorHandler(DateConvert(new Date(date)).default)}
                error={Boolean(errors.tgl) && touched.tgl}
                errorText={Boolean(errors.tgl) && touched.tgl && errors.tgl}
              />
            </Col>
            <Col md>
              <Input
                label={`No. ${modalConfig.type.toLowerCase() === 'penerimaan' ? 'Penerimaan' : 'Pembatalan'} Surat Jalan`}
                placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
                value={isNomorLoading ? 'Memuat nomor . . .' : values.no}
                error={Boolean(errors.no) && touched.no}
                errorText={Boolean(errors.no) && touched.no && errors.no}
                readOnly
              />
            </Col>
          </Row>
        )
      }
      const TableSection = () => (
        <Table>
          <thead className="bg-light">
            <tr>
              <Th width={10}>No.</Th>
              <Th>Kode Barang</Th>
              <Th width={225}>Nama Barang</Th>
              <Th>Gudang</Th>
              <Th>Qty. Surat Jalan</Th>
              <Th>Satuan</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableModal.length > 0
              ? dataTableModal.map((val, index) => (
                <tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{val.kode_barang}</Td>
                  <Td>{val.nama_barang}</Td>
                  <Td>{val.gudang}</Td>
                  <Td className="text-right">{val.qty}</Td>
                  <Td>{val.satuan}</Td>
                </tr>
              ))
              : <tr>
                <td colSpan={6}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            }
          </tbody>
        </Table>
      )
      // Cek apakah modal bertipe detail
      if (modalConfig.type.toLowerCase() === 'detail') {
        return (
          <Modal.Body>
            <InfoSection />
            <hr className="mb-1" />
            <InfoSectionDetail />
            <hr className="mt-1" />
            <TableSection />
          </Modal.Body>
        )
      }
      // Modal bertipe penerimaan/pembatalan
      return (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <InfoSection />
                <hr className="mb-1" />
                <FormSection formik={formik} />
                <hr className="mt-1" />
                <TableSection />
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type.toLowerCase() === 'penerimaan'
                  ? <ActionButton
                    type="submit"
                    variant="success"
                    text="Terima Surat Jalan"
                    loading={formik.isSubmitting}
                  />
                  : <ActionButton
                    type="submit"
                    variant="danger"
                    text="Batalkan Surat Jalan"
                    loading={formik.isSubmitting}
                  />
                }
              </Modal.Footer>
            </form>
          )}
        </Formik>
      )
    }

    return (
      <Modal
        size="lg"
        show={modalConfig.show}
        onHide={() => {
          setProcessedData({})
          setModalConfig({
            show: false,
            type: ""
          })
        }}
      >
        <Modal.Header closeButton>
          <b className="text-capitalize">{modalConfig.type} surat jalan</b>
        </Modal.Header>
        {
          modalFetchingStatus.loading
            ? <DataStatus loading text="Memuat data . . ." />
            : modalFetchingStatus.success
              ? <ModalContent />
              : <DataStatus text="Data gagal dimuat" />
        }
      </Modal>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/list-surat-jalan', { ...location?.state , sj: dataFilter})} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
      {/* Modal */}
      {modalConfig.show && <PageModal />}
      {/* Modal Filter */}
      { showModalFilter && <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      }
    </CRUDLayout>
  )
}

export default PenerimaanSuratJalan


// {/* <TdFixed className="align-middle">
//   <ReadButton onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/detail/'+ val.id_surat_jalan)} />
// </TdFixed> */}