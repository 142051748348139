// Component
import {
  Table, Th, Td, DataStatus, TdFixed,
  ThFixed
} from 'components'

const TableSection = ({ dataTable, setDataTable }) => {
  const decimalConvert = value => {
    const newValue = value.toString().replace(/[^0-9\.]/g,"")
    const convert = newValue.substring(0, 5)
    
    return convert
  }

  const onQtySJChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? {...val, qty_surat_jalan: value} : {...val}
    })

    setDataTable(newData)
  }

  return (
    <>
      <div className="p-1">
        <b>List Barang</b>
      </div>
      <Table>
        <thead className="bg-light">
          <ThFixed>No</ThFixed>
          <ThFixed>Kode Barang</ThFixed>
          <Th>Item Barang</Th>
          <ThFixed>Satuan</ThFixed>
          <ThFixed>Qty.</ThFixed>
          <ThFixed>Terkirim</ThFixed>
          <ThFixed>Sisa</ThFixed>
          <Th>Qty. Surat Jalan</Th>
        </thead>
        <tbody>
          {dataTable.length > 0
            ? dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan}</Td>
                <Td>{val.qty_do}</Td>
                <Td>{val.qty_terkirim}</Td>
                <Td>{val.qty_sisa_do}</Td>
                <Td style={{width: 125}}>
                  <input 
                    className="form-control form-control-sm"
                    placeholder="Masukan qty."
                    defaultValue={val.qty_surat_jalan}
                    onChange={e => {
                      e.target.value = decimalConvert(e.target.value)
                    }}
                    onBlur={e => {
                      const value = decimalConvert(e.target.value)
                      onQtySJChange(value, index)
                    }}
                  />
                </Td>
              </tr>
            ))
            : <tr>
                <td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default TableSection