import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import { Formik } from 'formik'
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  FilterButton,
  CreateModal,
} from "components"
import { ExportButton, ModalFilter, ModalDetail, FormSection } from "./__Comps__"
import { TableNumber, DateConvert, RupiahConvert, DecimalConvert } from "utilities"
import { PenerimaanGKGApi } from "api"
import * as Yup from "yup"
import Axios from "axios"
import FileSaver from "file-saver"

const PenerimaanGKG = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default;
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [formRendered, setFormRendered] = useState(0);
  const [dataPenerimaanGKG, setDataPenerimaanGKG] = useState([])
  const [dataItem, setDataItem] = useState([])
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.gkg?.filter?.active,
      tgl_penerimaan_gkg_mulai: location?.state?.gkg?.filter?.tgl_penerimaan_gkg_mulai,
      tgl_penerimaan_gkg_selesai:location?.state?.gkg?.filter?.tgl_penerimaan_gkg_selesai,
      gudang: location?.state?.gkg?.filter?.gudang,
    },
    pagination: {
      page: location?.state?.gkg?.filter?.page ?? "1",
      dataLength: location?.state?.gkg?.filter?.dataLength ?? "10",
      totalPage: location?.state?.gkg?.filter?.totalPage ?? "1",
      dataCount: location?.state?.gkg?.filter?.dataCount ?? "0",
    },
  });

  // CONFIG SECTION
  const [searchConfig, setSearchConfig] = useState({ 
    status: false, 
    key: "" 
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    data: {}
  })

  // REQUEST DATA SERVER
  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([
      PenerimaanGKGApi.getPage({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_penerimaan_gkg_mulai: dataFilter?.filter?.tgl_penerimaan_gkg_mulai,
        tgl_penerimaan_gkg_selesai: dataFilter?.filter?.tgl_penerimaan_gkg_selesai,
        gudang: dataFilter?.filter?.gudang,
      }),
      PenerimaanGKGApi.item()
    ])
    .then(Axios.spread((data, item) => {
      setDataPenerimaanGKG(data?.data?.data ?? []);

      const mapDataItem = item?.data?.data ? item.data.data.map(val => ({label: `${val?.kode_item} | ${val?.nama_item}`, value: val?.id_item_buaso})) : []
      setDataItem(item?.data?.data)

      setDataFilter({
        ...dataFilter,
        pagination: {
          ...dataFilter.pagination,
          dataCount: data?.data?.data_count,
          totalPage: data?.data?.total_page,
        },
      });
      setIsLoading(false)
    }))
    .catch((err) => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      });
    })
    .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    setNavbarTitle("Penerimaan Gabah Kering Giling (GKG)")
    getInitialData();
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penerimaan_gkg_mulai,
    dataFilter?.filter?.tgl_penerimaan_gkg_selesai,
    dataFilter?.filter?.gudang,
  ])

  // HANDLE EXPORT
  const handleExport = (file) => {
    setLoadingExport(true)

    PenerimaanGKGApi.export({ 
      tipe: file,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penerimaan_gkg_mulai: dataFilter?.filter?.tgl_penerimaan_gkg_mulai,
      tgl_penerimaan_gkg_selesai: dataFilter?.filter?.tgl_penerimaan_gkg_selesai,
      gudang: dataFilter?.filter?.gudang,
    })
    .then((res) => {
        console.log("export", res)
        // const link = res?.data?.data
        // const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
        // saveAs(parse, "Penerimaan Gabah Kering Giling")
        const data = res.data.data_only
        const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(urlFile, data);

        setLoadingExport(false)
    })
    .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Export Data gagal" }))
    .finally(() => setLoadingExport(false))
  }

  // FORM VALUES
  const formInitialValues = {
    id_item_buaso: dataItem[0]?.id_item_buaso,
    id_satuan: dataItem[0]?.id_satuan,
    id_gudang: "",
    tgl_penerimaan_gkg: today,
    berat_truk_masuk: "",
    berat_truk_keluar: "",
    berat_gabah_kering_giling: "",
    harga_satuan: "",
    total_harga: "",
    supir: "",
    plat_kendaraan: "",
    keterangan: "",
    nomor: "",
  }
  const formValidationSchema = Yup.object().shape({
    berat_truk_masuk: Yup.string().required("Masukkan berat awal"),
    berat_truk_keluar: Yup.string().required("Masukkan berat akhir"),
    harga_satuan: Yup.string().required("Masukan harga satuan"),
    supir: Yup.string().required("Masukkan nama supir"),
    plat_kendaraan: Yup.string().required("Masukan nomor plat kendaraan"),
    id_gudang: Yup.string().required("Pilih gudang penerimaan"),
  })
  const formSubmit = (values) => {
    PenerimaanGKGApi.create(values)
    .then(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Tambah data berhasil!",
        })
    })
    .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Tambah data gagal!",
        })
    })
    .finally(() => {
        setModalConfig({
          show: false,
          type: 'create'
        })
        getInitialData()
    })
  }

  // SECTION
  const TableSection = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Penerimaan Gabah Kering Giling (GKG)</div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penerimaan GKG</ThFixed>
              <Th width={150}>Berat Gabah Kering Giling (GKG)</Th>
              <Th width={250}>Supir</Th>
              <Th width={150}>Plat Kendaraan</Th>
              <Th width={250}>Gudang Penerimaan</Th>
              <Th width={150}>Total Harga</Th>
              <Th width={350}>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {dataPenerimaanGKG.map((val, index) => {
              const total = parseInt(val?.harga_satuan ?? 0) * parseInt(val?.berat_gkg ?? 0);
              return (
                <Tr>
                  <TdFixed>
                    <div className="pt-2">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</div>
                  </TdFixed>
                  <TdFixed>
                    <ReadButton 
                      onClick={() => setModalConfig({ 
                        show: true, 
                        type: "detail",
                        data: { id_gkg: val.id_penerimaan_gkg }
                      })} 
                    />
                  </TdFixed>
                  <TdFixed>
                    <div className="text-left">{val?.tgl_penerimaan_gkg ? DateConvert(new Date(val?.tgl_penerimaan_gkg)).defaultDMY : "-"}</div>
                    <div className="text-left">{val?.no_penerimaan_gkg ? val?.no_penerimaan_gkg : "-"}</div>
                  </TdFixed>
                  <Td><div className="text-right">{val?.berat_gabah_kering_giling ? `${DecimalConvert(val?.berat_gabah_kering_giling ?? 0).getWithComa} ${val?.satuan.charAt(0).toUpperCase() + val?.satuan.slice(1) ?? ""}` : "-"}</div></Td>
                  <Td>{val?.supir ? val?.supir : "-"}</Td>
                  <Td>{val?.plat_kendaraan ? val?.plat_kendaraan : "-"}</Td> 
                  <Td>{val?.nama_gudang ? val?.nama_gudang : "-"}</Td>
                  <Td><div className="text-right">{val?.total_harga ? RupiahConvert(String(parseFloat(val?.total_harga))).getWithComa : "Rp.0"}</div></Td>
                  <Td>{val?.keterangan ? val?.keterangan : "-"}</Td>
                </Tr>
            )})}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
            dataPage={dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
      </>
    )
  }
  const ModalCreateSection = () => {
    return (
      <CreateModal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ show: false })}
        size="md" 
        title="Penerimaan Gabah Kering Giling (GKG)">

        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmit}>
          
          {(formik) => (
              <FormSection 
                formik={formik}
                onSubmit={formSubmit}
                formRendered={formRendered}
                setFormRendered={setFormRendered}
                dataItem={dataItem}
                isLoading={isLoading}
                setModalConfig={setModalConfig}
              />
          )}
        </Formik>
      </CreateModal>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchConfig({ ...searchConfig, key: e.target.value })
                  setDataFilter({
                    ...dataFilter,
                    pagination: { ...dataFilter.pagination, page: "1" }
                  })
                  setAlertConfig({
                    show: e.target.value ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + e.target.value,
                  });
                }, 1000)
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton 
              active={dataFilter?.filter?.active} 
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
            <ExportButton loading={loadingExport} className="ml-2" text="EXPORT ">
                <Dropdown.Item onClick={() => handleExport('excel')}>Excel (.xlsx)</Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport('pdf')}>PDF (.pdf)</Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setModalConfig({ show: true, type: "create" })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig?.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ show: false })}
      />

      {/* Table Section */}
      { isLoading === true 
          ? <DataStatus loading={true} text="Memuat data..." /> 
          : dataPenerimaanGKG?.length > 0
          ? <TableSection />
          : <DataStatus text="Tidak ada data" />
      }

      {/* Modal Section */}
      {modalConfig?.type === "filter" && modalConfig?.show === true && (
        <ModalFilter
          show={modalConfig?.show}
          setShow={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {modalConfig?.type === "create" && modalConfig?.show === true && (
        <ModalCreateSection />
      )}
      {modalConfig?.type === "detail" && modalConfig?.show === true && (
        <ModalDetail 
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  )
}

export default PenerimaanGKG
