import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Dropdown, Table as BSTable } from "react-bootstrap"
import { Formik } from 'formik'
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  FilterButton,
  CreateModal,
  UpdateButton,
} from "components"
import { ExportButton, ModalFilter, FormSection } from "./__Comps__"
import { TableNumber, DateConvert, RupiahConvert, DecimalConvert } from "utilities"
import { PenerimaanBerasApi } from "api"
import * as Yup from "yup"
import Axios from "axios"
import FileSaver from "file-saver"

const PenerimaanBerasList = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  const [formRendered, setFormRendered] = useState(0)
  const [dataPenerimaanBeras, setDataPenerimaanBeras] = useState([])
  const [dataItem, setDataItem] = useState([])
  
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pbrs?.filter?.active,
      tgl_penerimaan_beras_mulai: location?.state?.pbrs?.filter?.tgl_penerimaan_beras_mulai,
      tgl_penerimaan_beras_selesai: location?.state?.pbrs?.filter?.tgl_penerimaan_beras_selesai,
      id_barang: location?.state?.pbrs?.filter?.id_barang,
      id_gudang: location?.state?.pbrs?.filter?.id_gudang,
    },
    pagination: {
      page: location?.state?.pbrs?.filter?.page ?? "1",
      dataLength: location?.state?.pbrs?.filter?.dataLength ?? "10",
      totalPage: location?.state?.pbrs?.filter?.totalPage ?? "1",
      dataCount: location?.state?.pbrs?.filter?.dataCount ?? "0",
    },
  })

// CONFIG SECTION
  const [searchConfig, setSearchConfig] = useState({ 
    status: false, 
    key: "" 
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    data: {}
  })

// REQUEST DATA SERVER
  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([
      PenerimaanBerasApi.getPage({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_penerimaan_beras_mulai: dataFilter?.filter?.tgl_penerimaan_beras_mulai,
        tgl_penerimaan_beras_selesai: dataFilter?.filter?.tgl_penerimaan_beras_selesai,
        id_barang: dataFilter?.filter?.id_barang,
        id_gudang: dataFilter?.filter?.id_gudang,
      }),
      // PenerimaanGKGApi.item()
    ])
    .then(Axios.spread((data) => {
      setDataPenerimaanBeras(data?.data?.data ?? [])

      setDataFilter({
        ...dataFilter,
        pagination: {
          ...dataFilter.pagination,
          dataCount: data?.data?.data_count,
          totalPage: data?.data?.total_page,
        },
      });
      setIsLoading(false)
    }))
    .catch((err) => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      });
    })
    .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    setNavbarTitle("Penerimaan Beras")
    getInitialData();
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penerimaan_beras_mulai,
    dataFilter?.filter?.tgl_penerimaan_beras_selesai,
    dataFilter?.filter?.id_barang,
    dataFilter?.filter?.id_gudang,
  ])
// HANDLE EXPORT
  const handleExport = (file) => {
    setLoadingExport(true)

    PenerimaanBerasApi.export({ 
      tipe: file,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penerimaan_beras_mulai: dataFilter?.filter?.tgl_penerimaan_beras_mulai,
      tgl_penerimaan_beras_selesai: dataFilter?.filter?.tgl_penerimaan_beras_selesai,
      id_barang: dataFilter?.filter?.id_barang,
      id_gudang: dataFilter?.filter?.id_gudang,
    })
    .then((res) => {
      const data = res.data.data_only
      const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
      FileSaver.saveAs(urlFile, data);
      setLoadingExport(false)
    })
    .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Export Data gagal" }))
    .finally(() => setLoadingExport(false))
  }
// SECTION
  const TableSection = () => {
    const TablelPB = ({ children }) => {
      return (
        <BSTable bordered responsive className="bg-white overflow-hidden border-1 shadow-sm">
          {children}
        </BSTable>
      );
    }
    const TabelTd = ({children, colSpan, rowSpan, textRight, className, ...props}) => {
      return (
        <td 
          {...props}
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={`p-1 px-2 ${textRight && 'text-right'} ${className}`}
          style={{
            ...props.style,
            fontSize: '14px'
          }}
        >
          {children}
        </td>
      )
    }
    const TabelTdFixed = ({children, colSpan, rowSpan, className, textRight, ...props}) => {
      return (
        <td 
          {...props}
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={`p-1 px-2 text-center ${textRight && 'text-right'} ${className}`}
          style={{
            ...props.style,
            fontSize: '14px'
          }}
        >
          {children}
        </td>
      )
    }
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Penerimaan Beras</div>
        <TablelPB>
          <THead>
            <tr>
              <Th rowSpan="2">No</Th>
              <Th rowSpan="2">Aksi</Th>
              <Th rowSpan="2">Informasi Penerimaan Beras</Th>
              <Th width={150} rowSpan="2">Total Muatan Truk</Th>
              <Th colSpan="4">Detail Beras</Th>
              <Th width={150} rowSpan="2">Grand Total Harga</Th>
              <Th width={350} rowSpan="2">Keterangan</Th>
            </tr>
            <tr>
              <Th width={450}>Nama Beras</Th>
              <Th width={150}>Qty Penerimaan</Th>
              <Th width={150}>Harga Satuan</Th>
              <Th width={150}>Total Harga</Th>
            </tr>
          </THead>
          <TBody>
            {dataPenerimaanBeras.map((val, index) => {
              const rowSpan = val.detail.length ?? 1

              return val.detail.map((item, index_item) => {
                return (
                  <Tr key={index}>
                    {index_item === 0 &&
                      <>
                        <TabelTdFixed rowSpan={rowSpan}>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TabelTdFixed>
                        <TabelTdFixed rowSpan={rowSpan}>
                          <div className="d-flex">
                            <ReadButton 
                              onClick={() => {
                                history.push("/inventory/transaksi/penerimaan-beras/detail/" + val.id_penerimaan_beras, { ...location?.state, pbrs: dataFilter, satuanName: item?.nama_satuan })
                              }}
                            />
                            <UpdateButton 
                              onClick={() => {
                                history.push("/inventory/transaksi/penerimaan-beras/update/" + val.id_penerimaan_beras, { ...location?.state, pbrs: dataFilter })
                              }}
                            />
                          </div>
                        </TabelTdFixed>
                        <TabelTdFixed rowSpan={rowSpan}>
                          <div className="text-left">{val.tgl_penerimaan_beras ? DateConvert(new Date(val.tgl_penerimaan_beras)).defaultDMY : "-"}</div>
                          <div className="text-left">{val.no_penerimaan_beras ?? "-"}</div>
                        </TabelTdFixed>
                        <TabelTd rowSpan={rowSpan}><div className="text-right">{`${val.total_muatan_truk ? DecimalConvert(val.total_muatan_truk).getWithComa : 0} Kg`}</div></TabelTd>
                      </>
                    }
                    <TabelTd>{item.nama_item ?? "-"}</TabelTd>
                    <TabelTd><div className="text-right">{`${item.qty_penerimaan ? DecimalConvert(item.qty_penerimaan).getWithComa : 0} ${item.nama_satuan ?? " "}`}</div></TabelTd>
                    <TabelTd textRight>{item?.harga_satuan ? RupiahConvert(String(parseFloat(item?.harga_satuan))).getWithComa : "Rp.0"}</TabelTd>
                    <TabelTd textRight>{item?.total_harga ? RupiahConvert(String(parseFloat(item?.total_harga))).getWithComa : "Rp.0"}</TabelTd>
                    {index_item === 0 &&
                      <>
                        <TabelTd rowSpan={rowSpan} textRight>{val?.grand_total_harga ? RupiahConvert(String(parseFloat(val?.grand_total_harga))).getWithComa : "Rp.0"}</TabelTd>
                        <TabelTd rowSpan={rowSpan}><div>{val.keterangan ?? "-"}</div></TabelTd>
                      </>
                    }
                  </Tr>
                )
              })
            })}
          </TBody>
        </TablelPB>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
          dataPage={dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
            ? dataFilter?.pagination?.dataCount
            : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchConfig({ ...searchConfig, key: e.target.value })
                  setDataFilter({
                    ...dataFilter,
                    pagination: { ...dataFilter.pagination, page: "1" }
                  })
                  setAlertConfig({
                    show: e.target.value ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + e.target.value,
                  });
                }, 1000)
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton 
              active={dataFilter?.filter?.active} 
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
            <ExportButton loading={loadingExport} className="ml-2" text="EXPORT ">
              <Dropdown.Item onClick={() => handleExport('excel')}>Excel (.xlsx)</Dropdown.Item>
              <Dropdown.Item onClick={() => handleExport('pdf')}>PDF (.pdf)</Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/inventory/transaksi/penerimaan-beras/create", { ...location?.state, pbrs: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig?.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ show: false })}
      />

      {/* Table Section */}
      { isLoading === true 
          ? <DataStatus loading={true} text="Memuat data..." /> 
          : dataPenerimaanBeras?.length > 0
          ? <TableSection />
          : <DataStatus text="Tidak ada data" />
      }

      {/* Modal Section */}
      {modalConfig?.type === "filter" && modalConfig?.show === true && (
        <ModalFilter
          show={modalConfig?.show}
          setShow={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default PenerimaanBerasList
