import Services from "../../../services";

class KelompokApi {
  getPage(page, dataLength, key) {
    return Services.get(`/kelompok/page/?page=${page}&per_page=${dataLength}&q=${key}&id_buaso=3`);
  }
  getDataBuaso() {
    return Services.get("/kelompok/buaso");
  }
  create(data) {
    return Services.post("/kelompok", data);
  }
  update(data) {
    return Services.put("/kelompok", data);
  }
  delete(id_kelompok) {
    return Services.post("/kelompok/delete", id_kelompok);
  }
  search(key) {
    return Services.get(`kelompok/page/?q=${key}`);
  }
  show(id_kelompok) {
    return Services.put("/kelompok/show", id_kelompok);
  }
  hide(id_kelompok) {
    return Services.put("/kelompok/hide", id_kelompok);
  }
}

export default new KelompokApi();
