// React
import React, { forwardRef, useEffect } from "react"

// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

// Page Component
import {
  InfoCatatanSection, KopSuratSection, ListData,
  TableSection
} from "./Section"

const PrintDeliveryOrder = forwardRef((props, ref) => {
  let id = props.id
  let data = props.data
  const today = new Date()

  useEffect(() => { }, [id])

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />

      <Row className="mt-5">
        <Col>
          <ListData title="Tgl. Sales Order" text={data.tgl_sales_order ? DateConvert(new Date(data.tgl_sales_order)).detail : '-'} />
          <ListData title="No. Sales Order" text={data.no_sales_order ?? '-'} />
          <ListData title="Batas Waktu Pengiriman" text={data.batas_waktu ? DateConvert(new Date(data.batas_waktu)).detail : '-'} />
        </Col>
        <Col>
          <ListData title="Sales" text={data.nama_sales ?? '-'} />
          <ListData title="Customer" text={data.nama_customer ?? '-'} />
          <ListData title="Gudang" text={data.nama_gudang ?? '-'} />
        </Col>
        <Col>
          <ListData title="Alamat Pengiriman" text={data.alamat_pengiriman ?? '-'} />
          <ListData title="Catatan Sales Order" text={data.catatan ?? '-'} />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <ListData title="Tanggal Delivery Order" text={DateConvert(new Date(data.tgl_delivery_order)).detail} />
        </Col>
        <Col>
          <ListData title="No. Delivery Order" text={data.no_delivery_order} />
        </Col>
        <Col />
      </Row>

      <TableSection data={data} type='detail' />
      <InfoCatatanSection data={data} />

      <div className="row mt-3">
        <div className="col-6 d-flex justify-content-center">
          <label></label>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <label>Negara, {DateConvert(today).defaultDay} {DateConvert(today).detailMonth} {DateConvert(today).defaultYear}</label>
        </div>
      </div>
      <div className="row">
        <div className="col-6 d-flex justify-content-center">
          <label>PETUGAS GUDANG</label>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <label>PENGIRIMAN</label>
        </div>
      </div>
      <div className="row" style={{ marginTop: 100 }}>
        <div className="col-2 d-flex justify-content-end">(</div>
        <div className="col-2 d-flex justify-content-center"></div>
        <div className="col-2">)</div>
        <div className="col-2 d-flex justify-content-end">(</div>
        <div className="col-2 d-flex justify-content-center"></div>
        <div className="col-2">)</div>
      </div>
    </div >
  )
})

export default PrintDeliveryOrder