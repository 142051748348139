import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import { DatePicker, SelectSearch, FilterModal } from 'components' 
import { DateConvert } from 'utilities'

const ModalFilterPR = ({ dropdown, show, setShow, data, setData, dropdownItem }) => {
  const formInitialValues = {
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
    tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
    nama_item: data?.filter?.nama_item,
    keperluan: data?.filter?.keperluan,
    status_seleksi_vendor: data?.filter?.status_seleksi_vendor
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangeTglPurchaseRequest = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_request_mulai: startDate,
        tgl_purchase_request_selesai: endDate,
    })
  }
  const onChangeTglPemakaian = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_pemakaian_mulai: startDate,
        tgl_pemakaian_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_purchase_request_mulai: undefined,
        tgl_purchase_request_selesai: undefined,
        tgl_pemakaian_mulai: undefined,
        tgl_pemakaian_selesai: undefined,
        keperluan: undefined,
        nama_item: undefined,
        status_seleksi_vendor: undefined
    })
  }
  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

          <DatePicker
            selectsRange
            label="Tgl. Purchase Request"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal purchase request"
            startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
            endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
            onChange={(dates) => onChangeTglPurchaseRequest(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.keperluan}
            label="Keperluan"
            placeholder="Pilih keperluan"
            defaultValue={dropdown.keperluan.find(item => item.value === values.keperluan)}
            option={dropdown.keperluan}
            onChange={val => setFieldValue('keperluan', val.value)}
          />
          <DatePicker
            selectsRange
            label="Tgl. Pemakaian"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal pemakaian"
            startDate={values.tgl_pemakaian_mulai ? new Date(values.tgl_pemakaian_mulai) : ''}
            endDate={values.tgl_pemakaian_selesai ? new Date(values.tgl_pemakaian_selesai) : ''}
            onChange={(dates) => onChangeTglPemakaian(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.nama_item}
            label="Item Barang"
            placeholder="Pilih item barang"
            defaultValue={dropdownItem.find(item => item.value === values.nama_item)}
            option={dropdownItem}
            onChange={val => setFieldValue('nama_item', val.value)}
          />
          <SelectSearch
            key={values.status_seleksi_vendor}
            label="Status Seleksi Vendor"
            placeholder="Pilih status seleksi vendor"
            defaultValue={dropdown.status.find(item => item.value === values.status_seleksi_vendor)}
            option={dropdown.status}
            onChange={val => setFieldValue('status_seleksi_vendor', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  )
}

export default ModalFilterPR