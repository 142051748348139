import Services from "../../../services";

class SetupStokAwal {
  getPage(params) {
    return Services.get("/stok_awal/page", { params });
  }
  create(value) {
    return Services.post("/stok_awal", value);
  }
  getDropdownBahan(params) {
    return Services.get("/bahan/dropdown_all", { params });
  }
  getDropdownGudang(params) {
    return Services.get("/gudang/dropdown", { params });
  }
}

export default new SetupStokAwal();
