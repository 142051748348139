import {
  useState
} from 'react'
import { 
  Table,
  THead,
  TBody,
  Tr,
  Td,
  Th, 
  DataStatus,
  ActionButton
} from 'components'
import {
  DateConvert
} from 'utilities'
import {
  ModalTransfer
} from '../Comp'
import { IoEyeOutline } from 'react-icons/io5'

export const TableHistory = ({data, dataHistory}) => {
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })

  return (
    <>
      <div className="pb-2">
        <b>List Data History Transfer Bahan Produksi</b>
      </div>
      <Table>
        <THead className="bg-light">
          <Tr>
            <Th noPadding style={{width: 40}}>No</Th>
            <Th noPadding style={{width: 150}}>Informasi Transfer Bahan Produksi</Th>
            <Th noPadding style={{minWidth: 150}}>Item Bahan</Th>
            <Th noPadding style={{width: 150}}>Gudang Asal</Th>
            <Th noPadding style={{width: 150}}>Gudang Tujuan</Th>
            <Th noPadding style={{width: 100}}>Qty. Ditransfer</Th>
            <Th noPadding style={{width: 100}}>Diserahkan Oleh</Th>
            <Th noPadding style={{width: 40}}>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0
            ? data.map((val, index) => (
                <Tr key={index}>
                  <Td className="text-center">
                    {index + 1}
                  </Td>
                  <Td>
                    <span className="d-block">{val?.tgl_transfer_produksi_bahan ? DateConvert(new Date(val.tgl_transfer_produksi_bahan)).defaultDMY : "-"}</span>
                    <span>{val?.no_transfer_produksi_bahan ?? ''}</span>
                  </Td>
                  <Td>{val?.nama_item_buaso ?? "-"}</Td>
                  <Td>{val?.nama_gudang_asal ?? "-"}</Td>
                  <Td>{val?.nama_gudang_tujuan ?? "-"}</Td>
                  <Td>{`${val?.qty_transfer ? parseFloat(val.qty_transfer) : '-'} ${val?.nama_satuan ?? ''}`}</Td>
                  <Td>{val?.diserahkan ?? '-'}</Td>
                  <Td>
                    <ActionButton 
                      size="sm"
                      text={<IoEyeOutline />}
                      onClick={() => setModal({...modal, show: true, data: val})}
                    />
                  </Td>
                </Tr>
              ))
            : <Tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
          }
        </TBody>
      </Table>
      <ModalTransfer
        action="DETAIL"
        data={modal?.data ?? {}}
        show={modal?.show}
        onHide={() => setModal({...modal, show: false})}
      />
    </>
  )
}
