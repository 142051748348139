import Services from "../../../services";

class RekapitulasiNilaiPersediaan {
  getReport(params) {
    return Services.get("/rekapitulasi_nilai/page", { params });
  }

  getSummary(params) {
    return Services.get("/rekapitulasi_nilai/summary", { params });
  }

  getDropdown(params) {
    return Services.get("/laporan_stok_update/dropdown", { params });
  }

  getDropdownGudang() {
    return Services.get("/gudang/dropdown")
  }

  getDropdownBahan() {
    return Services.get("/bahan/dropdown")
  }

  getDropdownkelompok() {
    return Services.get("/kelompok/dropdown")
  }

  getDropdownJenis() {
    return Services.get("/jenis/dropdown")
  }

  export(params) {
    return Services.get("/rekapitulasi_nilai/export", { params });
  }
}

export default new RekapitulasiNilaiPersediaan();
