// React
import { useState, useEffect } from 'react'

// Component
import { Row, Col } from 'react-bootstrap'
import { DatePicker, Input, SelectSearch, TextArea } from 'components'
import { DateConvert } from 'utilities'

// API
import { SuratJalanApi } from 'api'

// Page Component
import { TableSection as CreateTableSection } from '../TambahSuratJalan/Section'
import { TableSection as UpdateTableSection } from '../UbahSuratJalan/Section'

const FormSection = ({
  formik,
  fetchingStatus,
  formRendered,
  setFormRendered,
  dataPetugas,
  dataArmada,
  dataTable,
  setDataTable,
  type
}) => {
  const today = DateConvert(new Date()).default
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const { values, errors, touched, setValues, setFieldValue, handleChange } = formik

  const getNomorHandler = date => {
    setIsNomorLoading(true)

    SuratJalanApi.getNomor({ tanggal: date })
      .then(no => {
        const nomor = no.data.data

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: ""
        })
        window.alert("Nomor gagal dimuat")
      })
      .finally(() => {
        setIsNomorLoading(false)
      })
  }

  useEffect(() => {
    !fetchingStatus.loading && formRendered.toString() === '0' && getNomorHandler(today)

    return () => {
      setFormRendered(formRendered + 1)
      setIsNomorLoading(false)
    }
  }, [])

  return (
    <div>
      <Row>
        <Col lg={3}>
          <DatePicker
            label="Tgl. Surat Jalan"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={date => {
              const newDate = DateConvert(date).default
              getNomorHandler(newDate)
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={Boolean(errors.tanggal) && touched.tanggal && errors.tanggal}
          />
        </Col>
        <Col lg={3}>
          <Input
            readOnly
            label="No. Surat Jalan"
            placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={Boolean(errors.nomor) && touched.nomor && errors.petugas_pengiriman}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Input
            label="Petugas Pengiriman"
            name="nama_petugas"
            placeholder="Masukkan Nama Petugas Pengiriman"
            value={values.nama_petugas}
            onChange={handleChange}
            error={Boolean(errors.nama_petugas) && touched.nama_petugas}
            errorText={Boolean(errors.nama_petugas) && touched.nama_petugas && errors.nama_petugas}
          />

          {/* <SelectSearch
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            option={dataPetugas}
            defaultValue={dataPetugas.find(val => val.value === values.petugas_pengiriman)}
            onChange={val => setFieldValue('petugas_pengiriman', val.value)}
            error={Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman}
            errorText={Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman && errors.petugas_pengiriman}
          /> */}
        </Col>
        <Col lg={3}>
          <Input
            label="Armada Pengiriman"
            name="nama_armada"
            placeholder="Masukkan Armada Pengiriman"
            value={values.nama_armada}
            onChange={handleChange}
            error={Boolean(errors.nama_armada) && touched.nama_armada}
            errorText={Boolean(errors.nama_armada) && touched.nama_armada && errors.nama_armada}
          />
          {/* <SelectSearch
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            option={dataArmada}
            defaultValue={dataArmada.find(val => val.value === values.armada_pengiriman)}
            onChange={val => setFieldValue('armada_pengiriman', val.value)}
            error={Boolean(errors.armada_pengiriman) && touched.armada_pengiriman}
            errorText={Boolean(errors.armada_pengiriman) && touched.armada_pengiriman && errors.armada_pengiriman}
          /> */}
        </Col>
      </Row>

      {type === 'create' ? (
        <CreateTableSection dataTable={dataTable} setDataTable={setDataTable} />
      ) : (
        <UpdateTableSection dataTable={dataTable} />
      )}

      <TextArea
        label="Catatan Surat Jalan"
        name="catatan_surat_jalan"
        value={values.catatan_surat_jalan}
        onChange={handleChange}
        rows={4}
        error={Boolean(errors.catatan_surat_jalan) && touched.catatan_surat_jalan}
        errorText={Boolean(errors.catatan_surat_jalan) && touched.catatan_surat_jalan && errors.catatan_surat_jalan}
      />
    </div>
  )
}

export default FormSection