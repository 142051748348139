import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker, SelectSearch, FilterModal } from "components";
import { LaporanStockUpdateApi, RekapitulasiNilaiPersediaanApi } from "api";
import SelectMonth from "components/Select/SelectMonth";

const ModalFilterRekapitulasiNilaiPersediaan = ({ dropdown, setDropdown, modalConfig, setModalConfig, data, setData, isActive, setIsActive }) => {
  const [loading, setLoading] = useState({ jenis: false, item_buaso: false });
  const [dropdownBulan, setDropdownBulan] = useState([
    // { label: "Pilih Bulan", value: "" },
    { label: "Januari", value: "1" },
    { label: "Februari", value: "2" },
    { label: "Maret", value: "3" },
    { label: "April", value: "4" },
    { label: "Mei", value: "5" },
    { label: "Juni", value: "6" },
    { label: "Juli", value: "7" },
    { label: "Agustus", value: "8" },
    { label: "September", value: "9" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" },
  ])
// FORM VALUES
  const formInitialValues = {
    bulan: data?.bulan ?? new Date().getMonth() + 1,
    tahun: isActive ? new Date().getFullYear() : data?.tahun ??  new Date().getFullYear(),
    gudang: data?.gudang ?? null,
    kelompok: data?.kelompok ?? null,
    jenis: data?.jenis ?? null,
    item_barang: data.item_barang ?? null,
  };
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        ...values,
        tanggal: `${values.tahun}-${values.bulan}-01`,
        active: true,
      });
      setIsActive(false)
    } else {
      setData({ ...data, ...values, active: false });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, errors, touched, setValues, handleSubmit } = formik;

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      bulan: undefined,
      tanggal: undefined,
      gudang: undefined,
      kelompok: undefined,
      jenis: undefined,
      item_barang: undefined,
    }));
    setIsActive(true)
    setModalConfig(false);
  };

// FETCH DROPDOWN
  const getJenisData = (value) => {
    setLoading({ jenis: true, item_buaso: true });

    RekapitulasiNilaiPersediaanApi.getDropdown({ tipe: "jenis", id_kelompok: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          jenis: res.data.data?.map((val) => ({
            ...val, 
            value: val.id_jenis, 
            label: val.nama_jenis
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, jenis: [] })))
      .finally(() => setLoading({ jenis: false, item_buaso: false }));
  };
  const getItemData = (value) => {
    setLoading((prev) => ({ ...prev, item_buaso: true }));

    RekapitulasiNilaiPersediaanApi.getDropdown({ tipe: "item_buaso", id_jenis: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          item_buaso: res.data.data?.map((val) => ({
            ...val,
            value: val.id_item_buaso,
            label: val.nama_item,
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, item_buaso: [] })))
      .finally(() => setLoading((prev) => ({ ...prev, item_buaso: false })));
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}>
      <SelectSearch
        label="Bulan"
        placeholder="Pilih Bulan"
        defaultValue={values.bulan ? dropdownBulan.find((val) => val.value === values.bulan) : null}
        option={dropdownBulan}
        onChange={(val) => { setValues({ ...values, bulan: val.value })}}
      />
      {console.log(values)}
      <SelectSearch
        label="Tahun"
        placeholder="Pilih Tahun"
        defaultValue={values.tahun ? dropdown?.tahun.find((val) => val.value === values.tahun) : null}
        option={dropdown?.tahun}
        onChange={(val) => { setValues({ ...values, tahun: val.value })}}
      />
      <SelectSearch
        // key={values.gudang}
        label="Gudang"
        placeholder="Pilih Gudang"
        defaultValue={values.gudang ? dropdown.gudang.find((val) => val.value === values.gudang) : null}
        option={dropdown.gudang ?? []}
        onChange={(val) => setValues({ ...values, gudang: val.value })}
      />
      <SelectSearch
        label="Kelompok Item"
        placeholder="Pilih Kelompok Item"
        defaultValue={values.kelompok ? dropdown.kelompok.find((val) => val.value === values.kelompok) : null}
        option={dropdown?.kelompok ?? []}
        onChange={(val) => {
          setValues({ ...values, kelompok: val.value, jenis: "", item_barang: ""});
          getJenisData(val.value);
        }}
      />
      <SelectSearch
        label="Jenis Item"
        placeholder="Pilih Jenis"
        defaultValue={values.jenis ? dropdown.jenis.find((val) => val.value === values.jenis) : null }
        option={dropdown.jenis ?? []}
        onChange={(val) => {
          setValues({ ...values, jenis: val.value, item_barang: "" });
          getItemData(val.value);
        }}
        isDisabled={Boolean(values.kelompok) ? false : true}
        loading={loading.jenis}
      />
      <SelectSearch
        label="Nama Item"
        placeholder="Pilih bahan"
        defaultValue={values.item_barang ? dropdown.item_buaso.find((val) => val.value === values.item_barang ) : null}
        option={dropdown.item_buaso ?? []}
        onChange={(val) => setValues({ ...values, item_barang: val.value })}
        isDisabled={Boolean(values.kelompok) ? false : true}
        loading={loading.item_buaso}
        error={Boolean(errors.item_barang && touched.item_barang) && true}
        errorText={errors.item_barang && touched.item_barang && errors.item_barang}
      />
    </FilterModal>
  );
};

export default ModalFilterRekapitulasiNilaiPersediaan;
