import React, { useState, memo } from 'react'
import {
    SelectSearch,
    ActionButton,
    Alert,
    Td,
    Tr,
    TdFixed,
} from 'components'
import {
  IoTrashOutline,
  IoPencilOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RupiahConvert, DecimalConvert } from "utilities";
import { InputField, ReactNumberFormatField } from "../__Comps__";

const TabelPenerimaanBeras = ({ 
  dropdownItem, 
  dropdownGudang, 
  listValues, 
  setListValues,
  isType 
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const [indexEdit, setIndexEdit] = useState("")

  const [processedData, setProcessedData] = useState({})

  // CONFIG
  const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "",
      title: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  })

  const FormSection = memo(({ type }) => {
    // Form Section
    const formInitialValues = {
      id_item_buaso: type === "update" ? processedData.id_item_buaso : "",
      id_satuan: type === "update" ? processedData.id_satuan : "",
      id_gudang: type === "update" ? processedData.id_gudang : "",
      kode_item: type === "update" ? processedData.kode_item : "",
      nama_item: type === "update" ? processedData.nama_item : "",
      nama_gudang: type === "update" ? processedData.nama_gudang : "",
      qty_penerimaan: type === "update" ? processedData.qty_penerimaan : 0,
      nama_satuan: type === "update" ? processedData.nama_satuan : "",
      gudang_penerimaan: type === "update" ? processedData.gudang_penerimaan : 0,
      harga_satuan: type === "update" ? processedData.harga_satuan : "",
      total_harga: type === "update" ? processedData.total_harga : "",
    }
    const formValidationSchema = Yup.object().shape({
      id_item_buaso: Yup.string().required(),
      id_gudang: Yup.string().required(),
      qty_penerimaan: Yup.string().required(),
      harga_satuan: Yup.string().required(),
    })
    const formSubmitHandler = (values, { setSubmitting }) => {
      let newData = []

      if (type === "update") {
          const update = listValues?.map((item, index) =>
          Boolean(index === processedData.index) ? values : item
        )
        newData = update
      } else {
        newData = [...listValues, {...values, qty_penerimaan: parseFloat(values?.qty_penerimaan ?? 0)}];
      }

      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil ditambah!",
        });
        setListValues(newData)

        setIsEdit(false)
        setSubmitting(false)
        setProcessedData({})
      }, 300)
    }

    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, "");
      const convert = newValue.substring(0, 5);

      return convert;
    }
    
    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    })
  
    const { values, errors, touched, setValues, setFieldValue, handleSubmit, isSubmitting } = formik;
  
    return (
      <tr>
        <Td></Td>
        <Td colSpan={2}>
          <SelectSearch
            noMargin
            placeholder="Pilih item beras"
            option={dropdownItem}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            defaultValue={values.id_item_buaso ? dropdownItem.find((val) => val.value === values.id_item_buaso) : ""}
            onChange={(val) => {
              setValues({
                ...values,
                id_item_buaso: val.value,
                id_satuan_pakai: val.satuanValue,
                nama_item: val.itemLabel,
                nama_satuan: val.satuanLabel,
                kode_item: val.kodeItem
              });
            }}
            error={Boolean(errors.id_item_buaso && touched.id_item_buaso)}
          />
        </Td>
        <Td width={80}>
          <ReactNumberFormatField
            className="text-right"
            placeholder='Qty'
            value={values.qty_penerimaan}
            defaultValue={values.qty_penerimaan}
            onChange={(val) => {
              setFieldValue("qty_penerimaan", val)
            }}
            error={errors.qty_penerimaan && touched.qty_penerimaan && true}
            errorText={errors.qty_penerimaan && touched.qty_penerimaan && errors.berat_truk_masuk}
          />
        </Td>
        <Td>{values.id_item_buaso ? values.nama_satuan : ""}</Td>
        <Td>
          <SelectSearch
            noMargin
            placeholder="Pilih nama gudang"
            option={dropdownGudang}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            defaultValue={values.id_gudang ? dropdownGudang.find((val) => val.value === values.id_gudang) : ""}
            onChange={(val) => {
              setValues({
                ...values,
                id_gudang: val.value,
                nama_gudang: val.label
              });
            }}
            error={Boolean(errors.id_gudang && touched.id_gudang)}
          />
        </Td>
        <Td width={150}>
            <ReactNumberFormatField
                placeholder='Input harga'
                prefix="Rp."
                value={values.harga_satuan}
                defaultValue={values.harga_satuan}
                onChange={(value) => {
                    setFieldValue("harga_satuan", value);
                    setFieldValue("total_harga", value * values.qty_penerimaan)
                }}
                error={errors.harga_satuan && touched.harga_satuan && true}
                errorText={errors.harga_satuan && touched.harga_satuan && errors.harga_satuan}
            />
        </Td>
        <Td className="text-right">{values.total_harga ? RupiahConvert(String(parseFloat(values.total_harga))).getWithComa : "Rp.0"}</Td>
        <Td>
          {type === "update" ? (
            // Button outline centang dan silang saat update list table
            <ButtonGroup style={{ width: "100%" }}>
              <ActionButton
                size="sm"
                variant="outline-success"
                onClick={(handleSubmit)}
                disable={isSubmitting}
              >
                {isSubmitting ? <Spinner animation="border" size="sm" /> : <IoCheckmark />}
              </ActionButton>
              <ActionButton
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  setIsEdit(false)
                  setProcessedData({})
                }}
                disable={isSubmitting}
              >
                <IoCloseOutline />
              </ActionButton>
            </ButtonGroup>
          ) : (
            // Button primary plus di input field tabel
            <ActionButton
                size="sm" 
                className="col"
                onClick={handleSubmit}
                disable={isSubmitting}
            >
                {isSubmitting ? <Spinner animation="border" size="sm" /> : <IoAddOutline />}
            </ActionButton>
          )}
        </Td>
      </tr>
    )
  })
  const TabelData = ({ val, index }) => {
      const checkQty = val?.qty_penerimaan ? val?.qty_penerimaan : 0;
      const checkSatuan = val?.harga_satuan ? val?.harga_satuan : 0;
      const subTotal = checkQty * checkSatuan;

      return isEdit && index === processedData.index 
          ?   <FormSection type="update" />
          :   <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val?.kode_item ?? "-"}</TdFixed>
                  <Td>{val?.nama_item ?? "-"}</Td>
                  <Td width={150} className="text-right">{val?.qty_penerimaan ? DecimalConvert(val.qty_penerimaan).getWithComa : 0}</Td>
                  <Td width={100}>{val?.nama_satuan ?? "-"}</Td>
                  <Td>{val?.nama_gudang ?? "-"}</Td>
                  <Td className="text-right">{val?.harga_satuan ? RupiahConvert(String(parseFloat(val.harga_satuan))).getWithComa : "Rp.0"}</Td>
                  <Td width={140} className="text-right">{subTotal ? RupiahConvert(String(parseFloat(subTotal))).getWithComa : "Rp.0"}</Td>
                  {isType !== "DETAIL" && (
                      <TdFixed>
                          <ButtonGroup style={{ width: "100%" }}>
                              <ActionButton
                                  size="sm"
                                  variant="success"
                                  text={<IoPencilOutline />}
                                  onClick={() => {
                                      setIsEdit(true)
                                      setIndexEdit(index)
                                      setProcessedData({ ...val, index: index })
                                  }}
                              />
                              <ActionButton
                                  size="sm"
                                  variant="danger"
                                  text={<IoTrashOutline />}
                                  onClick={() => {
                                    setProcessedData({ ...val, index: index })
                                    setModalConfig({
                                      show: true,
                                      type: "delete",
                                      title: <span className="text-danger">Hapus Data</span>,
                                    });
                                  }}
                              />
                          </ButtonGroup>
                      </TdFixed>
                  )}
              </Tr>
  }
  const TableSection = () => {
    const grandTotal = () => {
      const totalKeseluruhanHarga = listValues?.reduce((prevValue, currentValue) => {
        const checkTotal = currentValue.harga_satuan ? currentValue.harga_satuan : 0
        const checkQty = currentValue.qty_penerimaan ? currentValue.qty_penerimaan : 0
        const subTotal = checkTotal * checkQty
        const total = subTotal

        return prevValue + total
      }, 0)

      return {
        prices: totalKeseluruhanHarga,
      }
    }
    const tableStyling = {
      padding: "0.5px",
      fontSize: "14px",
      verticalAlign: "middle",
    };
      return(
          <>
            <div className="font-weight-bold mt-4">List Data Penerimaan Beras</div>
            <div className="table-responsive">
              <table className="table table-bordered bg-white table-sm">
                <thead className="text-center">
                  <tr>
                    <th className="align-middle p-2" style={{ ...tableStyling, width: 10 }}>No</th>
                    <th className="align-middle p-2" style={{ ...tableStyling, width: 10 }}>Kode Item</th>
                      <th className="align-middle p-2" style={tableStyling}>Nama Beras</th>
                      <th className="align-middle p-2" style={tableStyling}>Qty Penerimaan</th>
                      <th className="align-middle p-2" style={tableStyling}>Satuan</th>
                      <th className="align-middle p-2" style={tableStyling}>Gudang Penerimaan</th>
                      <th className="align-middle p-2" style={tableStyling}>Harga Satuan</th>
                      <th className="align-middle p-2" style={tableStyling}>Total harga</th>
                      <th className="align-middle p-2" style={{ ...tableStyling, width: 10 }}>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {isType !== "DETAIL" && <FormSection type="create"/>}
                  {listValues?.length > 0 
                    ? listValues.map((val, index) => {
                      return isEdit && index === indexEdit
                        ? <FormSection key={index} type="update" />
                        : <TabelData key={index} val={val} index={index}/>})
                    : null
                  }
                  <tr>
                    <td colSpan={7} className="text-right p-2" style={tableStyling}><b>Total Keseluruhan Harga</b></td>
                    <td className="text-right">{RupiahConvert(String(parseFloat(grandTotal().prices))).getWithComa}</td>
                    <td className='p-2'></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
      )
  }
  const DeleteModal = ({ processedData, modalConfig, setModalConfig }) => {
      const [isDeleting, setIsDeleting] = useState(false)

      const handleDelete = () => {
        setIsDeleting(true)
        const index = processedData.index

        const finalData = listValues.filter((val, i) => {
          if (i === index) {
            return null
          }
          return val
        })

        setTimeout(() => {
          setListValues(finalData)
          setModalConfig({ ...modalConfig, show: false })
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil dihapus!",
          })
        }, 300)
      }

      return (
        <Modal
          scrollable={true}
          show={modalConfig.show}
          size={modalConfig.type === "delete" ? "md" : "xl"}
          onHide={() => setModalConfig({ ...modalConfig, show: false })}>

          <Modal.Header closeButton className="py-2">
            <Modal.Title>
              <small>{modalConfig.title}</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
              <h5>
                <span>Hapus data dengan nama item bahan: </span>
                <br />
                <b>{processedData.nama_item}</b>
              </h5>
              <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
              <div className="d-flex justify-content-center mt-3">
                <ActionButton
                    variant="outline-secondary"
                    className="m-1"
                    text="Batal"
                    onClick={() => setModalConfig({ ...modalConfig, show: false })}
                />
                <ActionButton
                    variant="danger"
                    className="m-1"
                    text="Hapus Data"
                    loading={isDeleting}
                    onClick={handleDelete}
                />
              </div>
          </Modal.Body>
        </Modal>
      )
  }
    
  return (
    <div style={{ marginBottom: "0px" }}>
      <Alert
        show={alertConfig.status}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, status: false })}
      />
      <TableSection />
      <DeleteModal
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
      />
    </div>
  )
}

export default TabelPenerimaanBeras