import React, {useState, useEffect} from 'react'
import { 
    ActionButton ,
    SelectSearch,
    Input,
    DatePicker,
} from 'components'
import { DateConvert, RupiahConvert } from 'utilities'
import { Modal, Row, Col } from 'react-bootstrap'

const FormSection = ({
formik,
dropdownUnitOrganisasi,
dropdownPenanggungJawab,
dropdownLoading,
modalConfig,
karyawanLoading,
setIsUnitChange,
UnitOrganisasiChange,
getPenanggungJawab,
dataDepo,
isUnitChange,
karyawanFilterd,
getData,
formInitialValues
}) => {
    const {values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, setValues } = formik
// STATE DATA
    const [dataPJFilter, setDataPJFilter] = useState([])
    console.log(dataPJFilter)
    const [dataUnitChange, setDataUnitChange] = useState(true)

    const filterPJ = (id_unit) => {
      const karyawanfilter = dropdownPenanggungJawab.filter((val) => val.id_unit_organisasi === id_unit);
      setDataPJFilter(karyawanfilter);
    }

    return(
        <div>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                <Input
                  label="Kode Depo"
                  type="text"
                  name="kode_depo"
                  placeholder="Kode Depo"
                  value={values.kode_depo}
                  readOnly={true}
                  onChange={handleChange}
                  error={!!(errors.kode_depo && touched.kode_depo) && true}
                  errorText={!!(errors.kode_depo && touched.kode_depo) && errors.kode_depo}
                />
                <Input
                  label="Nama Depo"
                  type="text"
                  name="nama_depo"
                  placeholder="Nama Depo"
                  value={values.nama_depo}
                  onChange={handleChange}
                  error={!!(errors.nama_depo && touched.nama_depo) && true}
                  errorText={!!(errors.nama_depo && touched.nama_depo) && errors.nama_depo}
                />
                <Input
                  label="Alamat Depo"
                  type="text"
                  name="alamat_depo"
                  placeholder="Alamat Depo"
                  value={values.alamat_depo}
                  onChange={handleChange}
                  error={errors.alamat_depo && touched.alamat_depo && true}
                  errorText={errors.alamat_depo && touched.alamat_depo && errors.alamat_depo}
                />
                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan_depo"
                  placeholder="Keterangan Depo"
                  value={values.keterangan_depo}
                  onChange={handleChange}
                  error={errors.keterangan_depo && touched.keterangan_depo && true}
                  errorText={errors.keterangan_depo && touched.keterangan_depo && errors.keterangan_depo}
                />
                <SelectSearch
                  label="Unit Organisasi"
                  name="id_unit_organisasi"
                  placeholder="Pilih Unit Organisasi"
                  defaultValue={values.id_unit_organisasi
                    ? dropdownUnitOrganisasi.find(val => val.value === values.id_unit_organisasi)
                    : ''
                  }
                  option={dropdownUnitOrganisasi}
                  onChange={(val) => {
                    setFieldValue("id_unit_organisasi", val.value)
                    filterPJ(val.value)
                    setDataUnitChange(false)
                    // setIsUnitChange(false)
                    // getPenanggungJawab()
                    // UnitOrganisasiChange(val.value)
                  }}
                  loading={dropdownLoading}
                  error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                  errorText={errors.id_unit_organisasi && touched.id_unit_organisasi && errors.id_unit_organisasi}
                />
                {dropdownLoading === true 
                  ? ( <small>Memuat Data....</small>) 
                  : (
                  <SelectSearch
                    key={values.id_unit_organisasi}
                    label="Penanggung Jawab"
                    name="id_pekerja"
                    placeholder={dataUnitChange !== false ? "Pilih Unit Organisasi Terlebih Dahulu" : "Pilih Penanggung Jawab"}
                    isDisabled={dataUnitChange}
                    onChange={(val) => setFieldValue("id_pekerja", val.value)}
                    option={dataPJFilter.map((val) => ({ value: val.id_karyawan, label: val.nama_karyawan }))}
                    // defaultValue={values.id_pekerja
                    //   ? dropdownPenanggungJawab.find(val => val.value === values.id_pekerja)
                    //   : ''
                    // }
                    defaultValue={{
                      value: values.id_pekerja ?? "",
                      label: Boolean(values.id_pekerja) ? dropdownPenanggungJawab.find((e) => e.id_karyawan === values.id_pekerja)?.nama_karyawan ?? "Pilih Penanggung Jawab" : "Pilih Penanggung Jawab",
                    }}
                    loading={dropdownLoading}
                    error={errors.id_pekerja && touched.id_pekerja && true}
                    errorText={errors.id_pekerja && touched.id_pekerja && errors.id_pekerja}
                  />
                )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                    <ActionButton
                        type="submit"
                        variant={modalConfig.type === "update" ? "success" : "primary"}
                        text={modalConfig.type === "update" ? "Ubah" : "Simpan"}
                        className="mt-2 px-4"
                        loading={isSubmitting}
                    />
                    </div>
                </Modal.Footer>
            </form>
        </div>
    )
}

export default FormSection