import {
  useState,
  useEffect
} from 'react'
import { 
  Modal,
  Row,
  Col
} from 'react-bootstrap'
import {
  Formik,
  useFormikContext
} from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import { 
  ActionButton,
  InfoItemHorizontal,
  Input,
  DatePicker,
  SelectSearch,
  TextArea,
  InfoItemVertical
} from 'components'
import {
  DateConvert
} from 'utilities'
import {
  TransferBahanProduksiApi
} from 'api'

const ModalBodyDetail = ({data}) => {
  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical 
            label="Tgl. Transfer Bahan Produksi"
            text={data?.tgl_transfer_produksi_bahan ? DateConvert(new Date(data.tgl_transfer_produksi_bahan)).defaultDMY : "-"}
          />
        </Col>
        <Col>
          <InfoItemVertical 
            label="No. Transfer Bahan Produksi"
            text={data?.no_transfer_produksi_bahan ?? '-'}
          />
        </Col>
      </Row>
      <InfoItemVertical 
        label="Gudang Asal"
        text={data?.nama_gudang_asal ?? '-'}
      />
      <Row>
        <Col>
          <InfoItemVertical 
            label="Gudang Tujuan"
            text={data?.nama_gudang_tujuan ?? '-'}
          />
        </Col>
        <Col>
          <InfoItemVertical 
            label="Qty. Transfer"
            text={parseFloat(data.qty_transfer ?? '-')}
          />
        </Col>
      </Row>
      <InfoItemVertical 
        label="Diserahkan Oleh"
        text={data?.diserahkan ?? '-'}
      />
      <InfoItemVertical 
        label="Keterangan Transfer"
        text={data?.keterangan_transfer ?? '-'}
      />

    </>
  )
}

const ModalBodyForm = ({data}) => {
  const [dataGudang, setDataGudang] = useState([])
  const [dataKaryawan, setDataKaryawan] = useState([])
  const { values, errors, touched, setValues, setFieldValue, handleChange } = useFormikContext()
  const [loading, setLoading] = useState({
    kode: false,
    qty: false,
    dropdown: true 
  })

  const getDropdownData = () => Axios.all([
    TransferBahanProduksiApi.getDropdown({tipe: 'gudang'}),
    TransferBahanProduksiApi.getDropdown({tipe: 'karyawan'})
  ])
    .then(Axios.spread((gudang, karyawan) => {
      const getDataGudang = gudang?.data?.data
        ? gudang.data.data.map(val => ({label: val.nama_gudang, value: val.id_gudang}))
        : []
      const getDataKaryawan = karyawan?.data?.data
        ? karyawan.data.data.map(val => ({label: val.nama_karyawan, value: val.id_karyawan}))
        : []

      setDataGudang(getDataGudang)
      setDataKaryawan(getDataKaryawan)
    }))
    .catch(() => {
      setDataGudang([])
      setDataKaryawan([])
    })
    .finally(() => {
      setLoading({
        ...loading,
        dropdown: false
      })
    })

  const getQtyGudang = (idGudang) => {
    setLoading({
      ...loading,
      qty: true
    })

    return TransferBahanProduksiApi.getQtyGudang({id_barang: data?.id_item_buaso, id_gudang: idGudang})
      .then((data) => {
        const stok = data?.data?.data?.jumlah ?? ''
        setFieldValue('stok_gudang', stok)
      })
      .catch(() => {
        setValues({
          ...values,  
          stok_gudang: ''
        })
      })
      .finally(() => {
        setLoading({ 
          ...loading, 
          qty: false 
        })
      })
  }

  const getKode = (date) => {
    setLoading({
      ...loading,
      kode: true
    })

    return TransferBahanProduksiApi.getKode({tanggal: date})
      .then((data) => {
        const kode = data?.data?.data
        setFieldValue('no_transfer_produksi_bahan', kode)
      })
      .catch(() => {
        setValues({
          ...values,
          tgl_transfer_produksi_bahan: '',
          no_transfer_produksi_bahan: ''
        })
      })
      .finally(() => {
        setLoading({
          ...loading,
          kode: false
        })
      })
  }

  useEffect(() => {
    getDropdownData()
  }, [])
  

  return (
    <>
      <Row>
        <Col>
          <DatePicker 
            label="Tgl. Transfer Bahan Produksi"
            placeholderText="Masukan tgl. transfer bahan produksi"
            selected={values.tgl_transfer_produksi_bahan ? new Date(values.tgl_transfer_produksi_bahan) : undefined}
            onChange={(date) => {
              const newDate = DateConvert(new Date(date)).default
              setFieldValue('tgl_transfer_produksi_bahan', newDate)
              getKode(newDate)
            }}
            error={!!errors.tgl_transfer_produksi_bahan && touched.tgl_transfer_produksi_bahan}
            errorText={!!errors.tgl_transfer_produksi_bahan && touched.tgl_transfer_produksi_bahan && errors.tgl_transfer_produksi_bahan} 
          />
        </Col>
        <Col>
          <Input 
            readOnly
            label="No. Transfer Bahan Produksi"
            placeholder="Pili tgl. untuk menentukan nomor"
            value={loading.kode ? 'Memuat . . .' : values.no_transfer_produksi_bahan}
            error={!!errors.no_transfer_produksi_bahan && touched.no_transfer_produksi_bahan}
            errorText={!!errors.no_transfer_produksi_bahan && touched.no_transfer_produksi_bahan && errors.no_transfer_produksi_bahan}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectSearch
            label="Gudang Asal"
            placeholder="Pilih gudang asal"
            option={dataGudang}
            loading={loading.dropdown}
            defaultValue={dataGudang?.find(val => values.id_gudang_asal === val.value)}
            onChange={val => {
              setValues({
                ...values,
                id_gudang_asal: val.value,
                stok_gudang: ''
              })
              getQtyGudang(val.value)
            }}
            error={!!errors.id_gudang_asal && touched.id_gudang_asal}
            errorText={!!errors.id_gudang_asal && touched.id_gudang_asal && errors.id_gudang_asal}
          />
        </Col>
        <Col md={3}>
          <Input 
            readOnly
            label="Stok Gudang"
            placeholder="Stok"
            value={loading.qty ? 'Memuat . . .' : values.stok_gudang}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectSearch
            label="Gudang Tujuan"
            placeholder="Pilih gudang tujuan"
            option={dataGudang}
            loading={loading.dropdown}
            defaultValue={dataGudang?.find(val => values.id_gudang_tujuan === val.value)}
            onChange={val => {
              setFieldValue('id_gudang_tujuan', val.value)
            }}
            error={!!errors.id_gudang_tujuan && touched.id_gudang_tujuan}
            errorText={!!errors.id_gudang_tujuan && touched.id_gudang_tujuan && errors.id_gudang_tujuan}
          />
        </Col>
        <Col md={3}>
          <Input 
            type="number"
            name="qty_transfer"
            label="Qty. Transfer"
            placeholder="Qty."
            onChange={handleChange}
            error={!!errors.qty_transfer && touched.qty_transfer}
            errorText={!!errors.qty_transfer && touched.qty_transfer && errors.qty_transfer}
          />
        </Col>
      </Row>
      <SelectSearch
        label="Diserahkan Oleh"
        placeholder="Pilih diserahkan oleh"
        option={dataKaryawan}
        loading={loading.dropdown}
        defaultValue={dataKaryawan?.find(val => values.id_karyawan === val.value)}
        onChange={val => {
          setFieldValue('id_karyawan', val.value)
        }}
        error={!!errors.id_karyawan && touched.id_karyawan}
        errorText={!!errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
      />
      <TextArea 
        name="keterangan_transfer"
        label="Keterangan Transfer"
        placeholder="Masukan keterangan transfer"
        value={values.keterangan_transfer}
        onChange={handleChange}
      />
    </>
  )
}

export const ModalTransfer = ({data = {}, dataHistory = [], show, onHide, action, refresh, alertConfig, setAlertConfig}) => {
  const qtyHistoryTransfer = parseFloat(data?.qty_transfer ?? dataHistory.filter(fil => fil.id_permintaan_produksi_bahan === data?.id_permintaan_produksi_bahan)
    .reduce((prev, current) => {
      const qty = parseFloat(current?.qty_transfer ?? 0)

      return prev + qty
    }, 0))
    
  const formInitialValues = {
    id_permintaan_produksi_bahan: data?.id_permintaan_produksi_bahan,
    tgl_transfer_produksi_bahan: '',
    id_item_buaso: data?.id_item_buaso,
    id_gudang_asal: '',
    id_gudang_tujuan: '',
    id_karyawan: '',
    qty_transfer: '',
    stok_gudang: '',
    keterangan_transfer: ''
  }

  const formValidationSchema = Yup.object().shape({
    tgl_transfer_produksi_bahan: Yup.string().required('Pilih tgl transfer'),
    no_transfer_produksi_bahan: Yup.string().required('Nomor tidak boleh kosong, pilih tgl transfer untuk menentukan nomor'),
    id_gudang_asal: Yup.string().required('Pilih gudang asal'),
    id_gudang_tujuan: Yup.string().required('Pilih gudang tujuan'),
    id_karyawan: Yup.string().required('Pilih karyawan'),
    qty_transfer: Yup.string()
      .required('Qty transfer tidak boleh kosong')
      .test({
        name: 'check-stok-gudang',
        message: 'Qty. gudang tidak mencukupi',
        test: (value, base) => !!(parseFloat(base?.parent?.stok_gudang ?? 0) >= value)
      })
      .test({
        name: 'check-qty-permintaan',
        message: 'Qty. melebihi permintaan',
        test: (value) => !!(parseFloat(data?.qty_permintaan_produksi ?? 0) - qtyHistoryTransfer >= value)
      })
  })

  const formSubmitHandler = (values) =>  TransferBahanProduksiApi.save(values)
    .then(() => {
      setAlertConfig({
        ...alertConfig,
        show: true,
        variant: 'primary',
        text: 'Data berhasil disimpan!'
      })
      refresh()
    })
    .catch(() => {
      setAlertConfig({
        ...alertConfig,
        show: true,
        variant: 'danger',
        text: 'Data gagal disimpan!'
      })
    })
    .finally(() => onHide())
  
  return (
    <Modal 
      show={show} 
      onHide={onHide}
    >
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({handleSubmit, isSubmitting}) => (
          <>
            <Modal.Header closeButton>
              <b>{action === 'create' ? 'Tambah' : 'Detail'} Transfer Bahan Produksi</b>
            </Modal.Header>
            <Modal.Body>
              <InfoItemHorizontal 
                label="Kode Item"
                text={data?.kode_item ?? data?.kode_item_buaso ??  '-'}
              />
              <InfoItemHorizontal 
                label="Item Bahan"
                text={data?.nama_item ?? data?.nama_item_buaso ?? '-'}
              />
              <InfoItemHorizontal 
                label="Satuan"
                text={data?.satuan_pakai ?? data?.nama_satuan ?? '-'}
              />
              <InfoItemHorizontal 
                label="Qty. Permintaan Produksi"
                text={data?.qty_permintaan_produksi ?? data?.qty_permintaan ?? '-'}
              />
              <InfoItemHorizontal 
                label="Total Qty. Telah Ditransfer"
                text={qtyHistoryTransfer}
              />
              <hr />
              {action === 'DETAIL'
                ? <ModalBodyDetail 
                    data={data}
                  />
                : <ModalBodyForm 
                    data={data}
                  />
              }
            </Modal.Body>
            <Modal.Footer>
              <ActionButton 
                variant="outline-secondary"
                text={action === 'DETAIL' ? 'Kembali' : 'Batal'}
                onClick={onHide}
              />
              {action === 'CREATE' &&
                <ActionButton 
                  text="Simpan"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                />
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}